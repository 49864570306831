import * as React from "react";
import Login from "pages/Login";
import { Navigate, PathRouteProps, Route } from "react-router-dom";
import MyIntegrations from "pages/integrations/Integrations";
import IntegrationCatalog from "pages/integrationCatalog/IntegrationCatalog";

import Settings from "pages/settings/Settings";
import LookAndFeel from "pages/settings/LookAndFeel";
import Organizations from "pages/settings/organizations/Organizations";
import OrganizationSettings from "pages/settings/organizations/OrganizationSettings";
import CreateNewOrganization from "pages/settings/organizations/CreateNewOrganization";
import SimpleOrganizationSettings from "pages/settings/organizations/SimpleOrganizationSettings";
import ShopConfiguration from "pages/settings/ShopConfiguration";
import SsoConfiguration from "pages/settings/SsoConfiguration";
import Users from "pages/settings/Users";
import Landing from "pages/Landing";
import TemplateDetails from "pages/integrationCatalog/TemplateDetails";
import TemplateChangeLog from "pages/integrationCatalog/TemplateChangeLog";
import TemplateWizard from "pages/integrationCatalog/TemplateWizard";
import ManualRun from "pages/integrations/ManualRun";
import IntegrationInstances from "pages/integrations/IntegrationInstances";
import IntegrationInstanceDetails from "pages/integrations/IntegrationInstanceDetails";
import { RequireTenantAdminRoute } from "components/route/ProtectedRoute";
import OrganizationCreationRequest from "pages/OrganizationCreationRequest";
import ChangeLocalUserPassword from "pages/passwords/ChangeLocalUserPassword";
import ForgotPassword from "pages/passwords/ForgotPassword";
import ResetPassword from "pages/passwords/ResetPassword";

export const LANDING_PATH = "/landing";
export const LOGIN_PATH = "/login";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/reset-password";
export const CHANGE_PASSWORD_PATH = "/change-password";
export const ORGANIZATION_REQUEST_PATH = "/organization-request";
export const INTEGRATIONS_BASE_PATH = "/integrations";
export const INTEGRATION_CATALOG_BASE_PATH = "/integration-catalog";

export const TEMPLATES_API_BASE_PATH = "templates";
export const TEMPLATES_TAGS_API_PATH = `${TEMPLATES_API_BASE_PATH}/tags`;
export const SHOPCONFIG_INTEGRATIONS_API_BASE_PATH =
    "shopconfiguration/availableIntegrations";
export const SHOPCONFIG_ENVIRONMENTS_API_BASE_PATH =
    "shopconfiguration/integrationEnvironments";
export const CATEGORIES_API_BASE_PATH = "categories";

const AppRoutes: PathRouteProps[] = [
    {
        path: INTEGRATIONS_BASE_PATH,
        element: <MyIntegrations />,
    },
    {
        path: `${INTEGRATIONS_BASE_PATH}/:processId/manually-run`,
        element: <ManualRun />,
    },
    {
        path: `${INTEGRATIONS_BASE_PATH}/:processId/instances`,
        element: <IntegrationInstances />,
    },
    {
        path: `${INTEGRATIONS_BASE_PATH}/:processId/instances/:instanceExecutionIdentifier`,
        element: <IntegrationInstanceDetails />,
    },
    {
        path: INTEGRATION_CATALOG_BASE_PATH,
        element: <IntegrationCatalog />,
        id: "integration-catalog",
    },
    {
        path: `${INTEGRATION_CATALOG_BASE_PATH}/:templateId`,
        element: <TemplateDetails />,
    },
    {
        path: `${INTEGRATION_CATALOG_BASE_PATH}/:templateId/changelog`,
        element: <TemplateChangeLog />,
    },
    {
        path: `${INTEGRATION_CATALOG_BASE_PATH}/:templateId/setup`,
        element: <TemplateWizard />,
    },
];

export const AppRoutesForLocalUser: PathRouteProps[] = [
    {
        path: CHANGE_PASSWORD_PATH,
        element: <ChangeLocalUserPassword />,
    },
];

export const AdminRoutes: PathRouteProps[] = [
    {
        path: "/settings",
        element: <Settings />,
        children: (
            <>
                <Route
                    index
                    element={<Navigate to="organizations" replace />}
                />
                <Route
                    element={
                        <RequireTenantAdminRoute redirectTo="organization" />
                    }
                >
                    <Route
                        path="organizations/new"
                        element={<CreateNewOrganization />}
                    />
                    <Route
                        path="organizations/:organizationId"
                        element={<OrganizationSettings />}
                    />
                    <Route path="organizations" element={<Organizations />} />
                    <Route
                        path="shop-configuration"
                        element={<ShopConfiguration />}
                    />
                    <Route path="look-and-feel" element={<LookAndFeel />} />
                    <Route
                        path="sso-configuration"
                        element={<SsoConfiguration />}
                    />
                    <Route path="users" element={<Users />} />
                </Route>
                <Route
                    path="organization"
                    element={<SimpleOrganizationSettings />}
                />
                <Route
                    path="*"
                    element={<Navigate to="organizations" replace />}
                />
            </>
        ),
    },
];

// Routes without Layout wrapper
export const SimpleRoutes: PathRouteProps[] = [
    {
        path: LANDING_PATH,
        element: <Landing />,
    },
    {
        path: LOGIN_PATH,
        element: <Login />,
    },
    {
        path: ORGANIZATION_REQUEST_PATH,
        element: <OrganizationCreationRequest />,
    },
    {
        path: FORGOT_PASSWORD_PATH,
        element: <ForgotPassword />,
    },
    {
        path: RESET_PASSWORD_PATH,
        element: <ResetPassword />,
    },
];

export default AppRoutes;
