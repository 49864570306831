import * as React from "react";

import * as S from "@styled";

import Text from "components/shared/Text";
import Button from "components/shared/Button";
import Table from "components/shared/Table";
import Input from "components/shared/Input";
import Checkbox from "components/shared/Checkbox";

import { ReactComponent as TrashIcon } from "@icons/trash.svg";

interface TagsSelectorProps {
    selectedTagNames: string[];
    tags: string[];
    onSelectedTagsChange: (tagNames: string[]) => void;
}

const TagsSelector: React.FC<TagsSelectorProps> = (props) => {
    const [searchValue, setSearchValue] = React.useState('');
    const [selectedTagNames, setSelectedTagNames] = React.useState<string[]>(props.selectedTagNames);

    const handleSearchChange = (value: string) => setSearchValue(value);

    const shownTags = React.useMemo(() => {
        return props.tags.filter((tag) => tag.toLowerCase().includes(searchValue.toLowerCase().trim()));
    }, [props.tags, searchValue]);

    const toggleTagSelected = (tag: string) => {
        const tagIdx = selectedTagNames.indexOf(tag);
        let next = [...selectedTagNames];
        if (tagIdx > -1) {
            next.splice(tagIdx, 1);
        } else {
            next = [...next, tag];
        }

        setSelectedTagNames(next);
        props.onSelectedTagsChange(next);
    };

    const isTagSelected = (tag: string) => selectedTagNames.includes(tag);

    const shownSelectedTags = props.tags.filter((tag) => isTagSelected(tag));

    return (
        <S.ConfigureModalTablesContainer>
            <Text tag="h3">
                Selected
            </Text>

            {shownSelectedTags.length > 0 ? (
                <Table>
                    <table>
                        <S.SettingsTableHeadSROnly>
                            <tr>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Actions
                                </th>
                            </tr>
                        </S.SettingsTableHeadSROnly>

                        <tbody>
                            {shownSelectedTags.map((tag, i) => (
                                <S.ConfigureModalSelectedTagsTableRow key={i}>
                                    <td>
                                        <Text tag="span">
                                            {tag}
                                        </Text>
                                    </td>
                                    <td>
                                        <S.ConfigureModalSelectedTagsTableActionButtonsContainer>
                                            <Button
                                                title="Unselect"
                                                variant="bordered"
                                                onClick={() => toggleTagSelected(tag)}
                                            >
                                                <TrashIcon />
                                            </Button>
                                        </S.ConfigureModalSelectedTagsTableActionButtonsContainer>
                                    </td>
                                </S.ConfigureModalSelectedTagsTableRow>
                            ))}
                        </tbody>
                    </table>
                </Table>
            ) : (
                <Text tag="span">
                    No tags selected
                </Text>
            )}

            <S.ConfigureModalSearchFieldset>
                <Text tag="legend" appearance="h3">
                    Full list
                </Text>

                <Input
                    label="Search"
                    hidelabel
                    type="search"
                    value={searchValue}
                    onChange={handleSearchChange}
                />
            </S.ConfigureModalSearchFieldset>

            {shownTags.length > 0 ? (
                <Table>
                    <table>
                        <S.SettingsTableHeadSROnly>
                            <tr>
                                <th>
                                    Selected
                                </th>
                                <th>
                                    Name
                                </th>
                            </tr>
                        </S.SettingsTableHeadSROnly>

                        <tbody>
                            {shownTags.map((tag, i) => (
                                <S.ConfigureModalAvailableTagsTableRow key={i}>
                                    <td>
                                        <Checkbox
                                            label={`Select ${tag}`}
                                            hidelabel
                                            checked={isTagSelected(tag)}
                                            onChange={() => toggleTagSelected(tag)}
                                        />
                                    </td>
                                    <td>
                                        <Text tag="span">
                                            {tag}
                                        </Text>
                                    </td>
                                </S.ConfigureModalAvailableTagsTableRow>
                            ))}
                        </tbody>
                    </table>
                </Table>
            ) : (
                <Text tag="span">
                    No tags available
                </Text>
            )}
        </S.ConfigureModalTablesContainer>
    );
};

export default TagsSelector;