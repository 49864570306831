import { styled } from "styled-components";

export const MenuBarCssPropertiesWrapper = styled.div`
    --frends-menu-bar--padding: 0 7.625rem 0 7.375rem;

    @media (min-width: 1920px) {
        --frends-menu-bar--padding: 
            0 
            calc((100vw - 1280px) / 2) 
            0 
            calc((100vw - 1280px) / 2);
    }
`;

export const BusinessLogoWrapper = styled.div`
    display: grid;
    align-items: center;
    width: 13.688rem;
`;

export const UserMenuContentWrapper = styled.div`
    display: grid;
    justify-items: start;
    gap: 0.375rem;
`;

export const UserMenuLogoutLinkCssPropertiesWrapper = styled.div`
  --frends-link--font-size: 0.875rem;
  --frends-link--color: ${props => props.theme.grey1};
`;