import { UseMutationResult, useMutation } from "react-query";
import Utilities from "shared/utilities";
import { FetchError } from "model/FetchError";
import useHandleMutationError from "./useHandleMutationError";

export type MutationVariables = {
    url?: string;
    body?: unknown;
};

const usePutMutation = <T = string, TVariables = unknown>(
    url: string,
    body: TVariables,
    onSuccess?: () => void,
    errorMsg?: string,
): UseMutationResult<T, FetchError, void, unknown> => {
    const mutation = useMutation<T, FetchError, void, unknown>({
        mutationFn: () => Utilities.fetchBase(url, "PUT", body),
        onSuccess,
    });

    useHandleMutationError(mutation, errorMsg);

    return mutation;
};

export default usePutMutation;
