import styled from "styled-components";

export const PageContainer = styled.section`
    padding: 0 7.625rem 2rem 7.375rem;

    @media (min-width: 1920px) {
        padding: 0 0 2rem 0;
    }
`

export const WidthContainer = styled.div`
    // Restrict the width of the content for FHD screens and bigger to 80rem ~1280px
    @media (min-width: 1920px) {
        max-width: 80rem;
        margin: 0 auto;
    }
`;