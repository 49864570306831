import * as React from "react";

const useIntersectionObserver = (props: IntersectionObserverProps) => {
    const observerRef = React.useRef<IntersectionObserver | null>(null);
    const intersectionEntryRef = React.useRef<{
        top: number;
        bottom: number;
    } | null>(null);

    React.useEffect(() => {
        if (observerRef.current) {
            observerRef.current.disconnect();
        }

        const option = {
            root: null,
            rootMargin: "0px",
            threshold: 0,
        };

        observerRef.current = new IntersectionObserver((entries) => {
            const entry = entries[0];

            if (!entry.isIntersecting || props.blockIntersectAction) {
                return;
            }

            if (
                intersectionEntryRef.current === null ||
                (intersectionEntryRef.current.top !==
                    entry.intersectionRect.top &&
                    intersectionEntryRef.current.bottom !==
                        entry.intersectionRect.bottom)
            ) {
                intersectionEntryRef.current = {
                    top: entry.intersectionRect.top,
                    bottom: entry.intersectionRect.bottom,
                };
                props.onIntersect();
            }
        }, option);

        if (props.intersectionRef.current) {
            observerRef.current.observe(props.intersectionRef.current);
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [props.intersectionObserverDependencies, props]);
};

/**
 * @typedef {Object} IntersectionObserverProps
 * @property {unknown[]} intersectionObserverDependencies - Dependencies for the intersection observer effect.
 * @property {React.MutableRefObject<HTMLElement>} intersectionRef - Ref to the element to be observed.
 * @property {boolean} blockIntersectAction - If true, the onIntersect callback will not be called, used for example when the user is already loading more data.
 * @property {() => void} onIntersect - Callback function to be called when the element intersects.
 */
interface IntersectionObserverProps {
    intersectionObserverDependencies: unknown[];
    intersectionRef: React.MutableRefObject<HTMLDivElement | null>;
    blockIntersectAction: boolean;
    onIntersect: () => void;
}

export default useIntersectionObserver;
