import * as React from "react";

import useDeferredMutation from "hooks/useDeferredMutation";

import * as S from "@styled";

import PasswordChangeInfo from "components/passwords/PasswordChangeInfo";
import { SaveButton } from "components/shared/Button";
import Input, { InputRef } from "components/shared/Input";
import PasswordActionSuccess from "components/passwords/PasswordActionSuccess";

import { INTEGRATIONS_BASE_PATH } from "AppRoutes";
import { PASSWORD_REQUIREMENTS_PATTERN } from "shared/PasswordRequirements";

const ChangeLocalUserPassword: React.FC = () => {
    const [currentPassword, setCurrentPassword] = React.useState("");
    const currentPasswordInputRef = React.useRef<InputRef>(null);
    const currentPasswordValid =
        currentPasswordInputRef.current?.checkValidity() ?? false;

    const [newPassword, setNewPassword] = React.useState("");
    const newPasswordInputRef = React.useRef<InputRef>(null);
    const newPasswordValid =
        newPasswordInputRef.current?.checkValidity() ?? false;

    const [confirmedPassword, setConfirmedPassword] = React.useState("");
    const confirmedPasswordInputRef = React.useRef<InputRef>(null);
    const confirmedPasswordValid =
        (confirmedPasswordInputRef.current?.checkValidity() ?? false) &&
        newPassword === confirmedPassword;

    const [submitTried, setSubmitTried] = React.useState(false);

    const localUserPasswordChangePOST = useDeferredMutation("POST");

    const inputsValid =
        currentPasswordValid && newPasswordValid && confirmedPasswordValid;

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setSubmitTried(true);

        if (inputsValid) {
            localUserPasswordChangePOST.mutate({
                url: "account/manage/info",
                body: {
                    oldPassword: currentPassword,
                    newPassword: confirmedPassword,
                },
            });
        }
    };

    return (
        <>
            {!localUserPasswordChangePOST.isSuccess && (
                <S.PasswordActionForm onSubmit={handleSubmit}>
                    <PasswordChangeInfo />

                    <Input
                        ref={currentPasswordInputRef}
                        label="Current password"
                        type="password"
                        autocomplete="current-password"
                        required
                        value={currentPassword}
                        onChange={(next) => setCurrentPassword(String(next))}
                        invalid={submitTried && !currentPasswordValid}
                        errortext="Current password required"
                    />

                    <Input
                        ref={newPasswordInputRef}
                        label="New password"
                        type="password"
                        autocomplete="new-password"
                        required
                        pattern={PASSWORD_REQUIREMENTS_PATTERN}
                        value={newPassword}
                        onChange={(next) => setNewPassword(String(next))}
                        invalid={submitTried && !newPasswordValid}
                        errortext="New password must match the requirements above"
                    />

                    <Input
                        ref={confirmedPasswordInputRef}
                        label="Confirm new password"
                        type="password"
                        autocomplete="new-password"
                        required
                        value={confirmedPassword}
                        onChange={(next) => setConfirmedPassword(String(next))}
                        invalid={submitTried && !confirmedPasswordValid}
                        errortext="New and confirmed passwords must match"
                    />

                    <SaveButton
                        type="submit"
                        disabled={submitTried && !inputsValid}
                        loading={localUserPasswordChangePOST.isLoading}
                        error={localUserPasswordChangePOST.isError}
                    >
                        Save
                    </SaveButton>
                </S.PasswordActionForm>
            )}

            {localUserPasswordChangePOST.isSuccess && (
                <PasswordActionSuccess
                    title="Password changed successfully!"
                    link={{
                        href: INTEGRATIONS_BASE_PATH,
                        text: "Back to Integrations",
                    }}
                />
            )}
        </>
    );
};

export default ChangeLocalUserPassword;
