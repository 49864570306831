import * as React from "react";
import { useNavigate } from "react-router-dom";

import useDeferredMutation from "hooks/useDeferredMutation";

import * as S from "@styled";

import TitleWithArrowLink from "components/shared/TitleWithArrowLink";
import OrganizationSettingsForm from "components/settings/organization/organizationSettings/OrganizationSettingsForm";

import { Organization } from "model/Organization";

const CreateNewOrganization: React.FC = () => {
    const navigate = useNavigate();

    const postOrganization = useDeferredMutation<{ organizationId: number }>(
        "POST",
        undefined,
        "Failed to create organization.",
    );

    const handleSave = (organization: Organization) => {
        postOrganization.reset();
        postOrganization.mutate({ url: "organizations", body: organization });
    };

    React.useEffect(() => {
        if (postOrganization.isSuccess && postOrganization.data) {
            navigate(
                `/settings/organizations/${postOrganization.data.organizationId}`,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postOrganization.isSuccess, postOrganization.data]);

    return (
        <div>
            <S.GridContainer $gap="1.313rem">
                <TitleWithArrowLink
                    title="New organization"
                    href="/settings/organizations"
                />

                <OrganizationSettingsForm
                    saveButtonText="Create"
                    onSave={handleSave}
                    saving={postOrganization.isLoading}
                    saveSuccess={postOrganization.isSuccess}
                    saveError={postOrganization.isError}
                />
            </S.GridContainer>
        </div>
    );
};

export default CreateNewOrganization;
