import { styled } from "styled-components";

export const SmallDropdownCssPropertiesWrapper = styled.div`
    --frends-dropdown--width: 9.5rem;
    --frends-dropdown--toggle--padding: 0.125rem 0.25rem 0.1875rem 0.5625rem;
    --frends-dropdown--toggle--border: 0;
    --frends-dropdown--toggle--border-radius: 0.9375rem;
    --frends-dropdown--toggle--svg--fill: ${(props) => props.theme.grey1};
    --frends-dropdown--toggle--focus--box-shadow: 0 0 0 0.0625rem
        ${(props) => props.theme.grey3};
    --frends-dropdown--toggle--hover--box-shadow: 0 0 0 0.0625rem
        ${(props) => props.theme.grey3};
`;

export const TableSelectColumnsDropdownCssPropertiesWrapper = styled.div`
    --frends-dropdown--width: 9.5rem;
    --frends-dropdown--toggle--padding: 0.5rem;
    --frends-dropdown--toggle--is-multi-value--padding: 0.5rem;
    --frends-dropdown--toggle--border: 0;
    --frends-dropdown--toggle--border-radius: 0.9375rem;
    --frends-dropdown--toggle--svg--fill: ${(props) => props.theme.black};
    --frends-dropdown--toggle--background-color: transparent;
    --frends-dropdown--toggle--focus--box-shadow: 0 0 0 0.0625rem
        ${(props) => props.theme.grey3};
    --frends-dropdown--toggle--hover--box-shadow: 0 0 0 0.0625rem
        ${(props) => props.theme.grey3};
    --frends-dropdown--toggle--position: absolute;
    --frends-dropdown--toggle--right: 0;
    --frends-dropdown--toggle--width: 1.5rem;
`;
