import * as React from "react";

import * as S from "@styled";

import AvailableIntegrations from "components/settings/shopConfiguration/AvailableIntegrations";
import Categories from "components/settings/shopConfiguration/Categories";
import IntegrationEnvironments from "components/settings/shopConfiguration/IntegrationEnvironments";

const ShopConfiguration: React.FC = () => (
    <S.ShopConfigurationPageContainer>
        <AvailableIntegrations />
        <Categories />
        <IntegrationEnvironments />
    </S.ShopConfigurationPageContainer>
);

export default ShopConfiguration;