import * as React from "react";
import useQuery from "./useQuery";
import { LookAndFeelModel } from "model/LookAndFeelModel";
import { AppActions, Types } from "reducers/AppReducer";

const useLookAndFeel = (dispatch: React.Dispatch<AppActions>) => {
    const [stylesLoaded, setStylesLoaded] = React.useState(false);

    const request = useQuery<LookAndFeelModel>(
        "lookandfeel",
        undefined,
        undefined,
        "Failed to fetch look and feel settings.",
    );

    React.useEffect(() => {
        if (request.error) {
            setStylesLoaded(true);
            return;
        }
        if (request.isFetched && request?.data != null) {
            setRootStyleProperty("--button-color", request.data.buttonColor);
            setRootStyleProperty(
                "--background-color",
                request.data.backgroundColor,
            );
            setRootStyleProperty("--accent-color", request.data.accentColor);

            setFavicon(request.data.favIcon);
            setTitlePrefix(request.data.pageTitlePrefix);

            injectCustomCss(request.data.customCss);
            injectHtmlIntoHead(request.data.customHtmlHead);

            setStylesLoaded(true);
            dispatch({
                type: Types.SET_LOOK_AND_FEEL,
                payload: { lookAndFeel: request.data },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request.data, request.isFetched, request.error]);

    return stylesLoaded;
};

const setRootStyleProperty = (property: string, value?: string) => {
    if (value) {
        document.documentElement.style.setProperty(property, value);
    }
};

const injectHtmlIntoHead = (htmlString?: string) => {
    if (!htmlString) {
        return;
    }

    const head = document.head || document.getElementsByTagName("head")[0];
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    // We have to inject scripts separately because they won't run if we just append them to the head directly
    for (const node of tempDiv.childNodes) {
        if (node.nodeName.toLowerCase() === "script") {
            const script = document.createElement("script");
            script.text = node.textContent || "";
            head.appendChild(script);
        } else {
            head.appendChild(node);
        }
    }
};

const injectCustomCss = (cssString?: string | null) => {
    if (!cssString) {
        return;
    }

    const body = document.body || document.getElementsByTagName("body")[0];
    const lookAndFeelElementId = "look-and-feel-custom-css";
    let style = document.getElementById(lookAndFeelElementId);

    if (!style) {
        style = document.createElement("style");
        style.id = lookAndFeelElementId;
        // Inject custom css to the end of the body so it can override other styles
        body.appendChild(style);
    }

    style.innerHTML = cssString;
};

const setFavicon = (url?: string | null) => {
    if (!url) {
        // Use fallback favicon
        _setFavicon(`${process.env.PUBLIC_URL}/favicon.png`);
        return;
    }

    _setFavicon(url);
};

const _setFavicon = (url: string) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement("link");
        link.setAttribute("rel", "icon");
        document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.setAttribute("href", url);
};

const setTitlePrefix = (prefix?: string) => {
    if (!prefix) {
        return;
    }

    document.title = prefix + " - " + document.title;
};

export default useLookAndFeel;
