import * as React from "react";
import { useParams, useLocation } from "react-router-dom";

import { useQueryClient } from "react-query";
import useQuery from "hooks/useQuery";
import useDeferredMutation from "hooks/useDeferredMutation";

import * as S from "@styled";

import IntegrationsBreadcrumbs from "components/integrations/IntegrationsBreadcrumbs";
import TitleWithArrowLink from "components/shared/TitleWithArrowLink";
import Text from "components/shared/Text";
import ButtonDropdown from "components/shared/ButtonDropdown";
import Table from "components/shared/Table";
import Checkbox from "components/shared/Checkbox";
import Button from "components/shared/Button";
import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import { TableSelectColumnsDropdown } from "components/shared/Dropdown";
import Tooltip from "components/shared/Tooltip";

import ConfirmInstanceActionModal, {
    ConfirmInstanceActionModalProps,
} from "components/integrations/ConfirmInstanceActionModal";

import { ReactComponent as ExceptionIcon } from "@icons/error_outline.svg";
import { ReactComponent as StartedIcon } from "@icons/running.svg";
import { ReactComponent as SuccessIcon } from "@icons/success_circle.svg";

import useIntersectionObserver from "hooks/useIntersectionObserver";

import { Breadcrumb } from "frends-ui-components/dist/types/breadcrumbs/Breadcrumbs";
import { Process } from "model/process/Process";
import {
    ProcessInstance,
    ProcessInstanceResponse,
} from "model/process/ProcessInstance";
import { ProcessInstanceComment } from "model/process/ProcessInstanceComment";

import { INTEGRATIONS_BASE_PATH } from "AppRoutes";

import Utilities from "shared/utilities";
import Time from "shared/Time";

const IntegrationInstances: React.FC = () => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const { processId } = useParams();
    const loadMoreInstancesRef = React.useRef<HTMLDivElement | null>(null);
    const [
        confirmInstanceActionModalProps,
        setConfirmInstanceActionModalProps,
    ] = React.useState<ConfirmInstanceActionModalProps>();

    const processFetch = useQuery<Process>(
        `processes/${processId}`,
        undefined,
        undefined,
        "Failed to fetch process.",
    );
    const process = processFetch.data;

    const agentGroupId = process?.prodAgentGroupId;
    const instancesApiBasePath = "processInstance";
    const downloadApiRoute = `${instancesApiBasePath}/${agentGroupId}/${process?.processGuid}/csv`;
    const acknowledgeActionApiRoute = `${instancesApiBasePath}/${agentGroupId}/${process?.processGuid}/acknowledgeErrors`;
    const commentActionApiRoute = `${instancesApiBasePath}/${agentGroupId}/${process?.processGuid}/comment`;

    const instanceListApiRoute = `${instancesApiBasePath}/${agentGroupId}`;
    const [nextContinuationToken, setNextContinuationToken] =
        React.useState<string>();
    const instancesFetch = useQuery<ProcessInstanceResponse>(
        instanceListApiRoute,
        {
            ...(process?.processGuid && {
                processGuids: [process.processGuid],
            }),
            ...(nextContinuationToken && {
                continuationToken: nextContinuationToken,
            }),
        },
        { enabled: !!process },
        "Failed to fetch process instances.",
    );
    const incomingInstances = instancesFetch.data?.data;

    const [instances, setLoadedInstances] = React.useState<ProcessInstance[]>(
        incomingInstances ?? [],
    );
    const hasMoreInstances = !!instancesFetch.data?.nextContinuationToken;
    useIntersectionObserver({
        blockIntersectAction: instancesFetch.isLoading,
        intersectionRef: loadMoreInstancesRef,
        intersectionObserverDependencies: [
            instancesFetch.isLoading,
            hasMoreInstances,
            queryClient,
        ],
        onIntersect: () => {
            if (hasMoreInstances) {
                setNextContinuationToken(
                    instancesFetch.data!.nextContinuationToken,
                );
                queryClient.invalidateQueries(instanceListApiRoute);
            }
        },
    });

    React.useEffect(() => {
        // Reset loaded instances when the location changes, because component doesnt unmount
        setLoadedInstances([]);
        setNextContinuationToken(undefined);
        queryClient.invalidateQueries(instanceListApiRoute);
    }, [location, queryClient, instanceListApiRoute]);

    React.useEffect(() => {
        if (incomingInstances && !nextContinuationToken) {
            setLoadedInstances(incomingInstances);
            return;
        }
        if (nextContinuationToken && incomingInstances) {
            setLoadedInstances((prevInstances) => [
                ...prevInstances,
                ...incomingInstances,
            ]);
        }
    }, [incomingInstances, nextContinuationToken]);

    const [csvFetchUrl, setCsvFetchUrl] = React.useState<string>();
    const csvFetch = useQuery<string>(
        csvFetchUrl!,
        {},
        { enabled: !!csvFetchUrl },
        "Failed to fetch instances csv.",
    );

    React.useEffect(() => {
        if (!csvFetch.isLoading && csvFetch.data) {
            Utilities.downloadFile(csvFetch.data, "instances.csv", "text/csv");
        }
    }, [csvFetch.isLoading, csvFetch.data]);

    const isFetching = processFetch.isLoading || instancesFetch.isLoading;

    const allInstanceIds = instances?.map((i) => i.id);
    const [selectedInstanceIds, setSelectedInstanceIds] = React.useState(
        new Set<number>(),
    );

    const action = useDeferredMutation(
        "PUT",
        async () => {
            try {
                const refetchedInstances = await instancesFetch.refetch();
                if (refetchedInstances.data) {
                    // After instance action, refetch instances and update the selected instances
                    const newInstancesToOverwrite =
                        refetchedInstances.data.data.filter((i) =>
                            selectedInstanceIds.has(i.id),
                        );
                    const instancesToOverwrite = instances.map(
                        (i) =>
                            newInstancesToOverwrite.find(
                                (ni) => ni.id === i.id,
                            ) ?? i,
                    );
                    setLoadedInstances(instancesToOverwrite);
                    setSelectedInstanceIds(new Set<number>());
                }
            } catch (error) {
                console.error(error);
            }
        },
        "Failed to perform instance action.",
    );

    const handleConfirmInstanceActionModalClose = () => {
        action.reset();
        setConfirmInstanceActionModalProps(undefined);
    };

    React.useEffect(() => {
        if (action.isLoading) {
            setConfirmInstanceActionModalProps(
                (prev: ConfirmInstanceActionModalProps) => ({
                    ...prev,
                    confirming: true,
                }),
            );
        }
    }, [action.isLoading]);

    React.useEffect(() => {
        if (action.error) {
            setConfirmInstanceActionModalProps(
                (prev: ConfirmInstanceActionModalProps) => ({
                    ...prev,
                    confirming: false,
                    confirmError: true,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action.error]);

    React.useEffect(() => {
        if (action.isSuccess && confirmInstanceActionModalProps) {
            handleConfirmInstanceActionModalClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action.isSuccess, confirmInstanceActionModalProps]);

    const handleAcknowledgeAllErrors = () => {
        setConfirmInstanceActionModalProps({
            title: "Acknowledge all errors",
            onConfirm: (comment?: string) => {
                action.mutate({
                    url: acknowledgeActionApiRoute,
                    body: {
                        instanceIds: allInstanceIds,
                        reason: comment,
                    },
                });
            },
        });
    };

    const handleSaveAsCsv = (instanceIds?: number[]) => {
        const nextFetchCsvUrl = `${downloadApiRoute}${
            instanceIds
                ? `?${instanceIds.map((guid) => `processInstanceIds=${guid}`).join("&")}`
                : ""
        }`;

        if (nextFetchCsvUrl === csvFetchUrl) {
            csvFetch.refetch();
        } else {
            setCsvFetchUrl(nextFetchCsvUrl);
        }
    };

    const handleSaveAllAsCsv = () => handleSaveAsCsv();

    const handleAcknowledgeSelectedErrors = () => {
        setConfirmInstanceActionModalProps({
            title: "Acknowledge error",
            onConfirm: (comment?: string) => {
                action.mutate({
                    url: acknowledgeActionApiRoute,
                    body: {
                        instanceIds: [...selectedInstanceIds],
                        reason: comment,
                    },
                });
            },
        });
    };

    const handleCommentSelected = () => {
        setConfirmInstanceActionModalProps({
            title: "Comment",
            commentRequired: true,
            onConfirm: (comment: string) => {
                action.mutate({
                    url: commentActionApiRoute,
                    body: {
                        instanceIds: [...selectedInstanceIds],
                        comment,
                    },
                });
            },
        });
    };

    const handleSaveSelectedAsCsv = () =>
        handleSaveAsCsv([...selectedInstanceIds]);

    const instanceActions = [
        {
            label: "Acknowledge all errors",
            action: handleAcknowledgeAllErrors,
        },
        {
            label: "Save all as .csv",
            action: handleSaveAllAsCsv,
        },
        {
            label: "Acknowledge error",
            action: handleAcknowledgeSelectedErrors,
            hasDivider: true,
            disabled: !selectedInstanceIds.size,
        },
        {
            label: "Comment",
            action: handleCommentSelected,
            disabled: !selectedInstanceIds.size,
        },
        {
            label: "Save selected as .csv",
            action: handleSaveSelectedAsCsv,
            disabled: !selectedInstanceIds.size,
        },
    ];

    const instanceActionOptions = instanceActions.map((a) => ({
        ...a,
        value: a.label,
    }));

    const handleInstanceActionsClick = (value: string) => {
        const instanceAction = instanceActions.find((a) => a.label === value);
        if (!instanceAction) {
            return;
        }
        instanceAction.action();
    };

    const toggleSelectAll = () => {
        setSelectedInstanceIds(
            new Set(
                selectedInstanceIds.size < instances.length
                    ? allInstanceIds
                    : undefined,
            ),
        );
    };

    const toggleSelectId = (id: number) => {
        if (selectedInstanceIds.has(id)) {
            setSelectedInstanceIds(
                new Set([...selectedInstanceIds].filter((sId) => sId !== id)),
            );
        } else {
            setSelectedInstanceIds(new Set([...selectedInstanceIds, id]));
        }
    };

    const extraColumns = ["End time", "Comment"];
    const extraColumnsStorageKey =
        "frends_bap_integration_instance_list_extra_columns";
    const [selectedExtraColumns, setSelectedExtraColumns] = React.useState<
        Set<string>
    >(() => {
        let columns = [];
        try {
            const extraColumns = localStorage.getItem(extraColumnsStorageKey);
            if (extraColumns) {
                columns = JSON.parse(extraColumns);
            }
        } catch (_) {
            /* empty */
        }
        return new Set(columns);
    });

    const handleSelectedExtraTableColumnsChange = (columns: string[]) => {
        setSelectedExtraColumns(new Set(columns));
        localStorage.setItem(extraColumnsStorageKey, JSON.stringify(columns));
    };

    const hasEndTimeColumn = selectedExtraColumns.has(extraColumns[0]);
    const hasCommentColumn = selectedExtraColumns.has(extraColumns[1]);

    if (!process) {
        return (
            <InstancePageLayout
                process={process}
                isFetching={processFetch.isLoading}
            >
                <TitleWithArrowLink
                    title={
                        processFetch.isLoading
                            ? "Process loading..."
                            : "Process not found."
                    }
                    href={INTEGRATIONS_BASE_PATH}
                />
            </InstancePageLayout>
        );
    }

    if (!instances || instances.length === 0) {
        return (
            <InstancePageLayout
                process={process}
                isFetching={instancesFetch.isLoading}
            >
                <TitleWithArrowLink
                    title={process?.name}
                    href={INTEGRATIONS_BASE_PATH}
                />

                <Text tag="p">{process.description || ""}</Text>

                <Text tag="h3">
                    {instancesFetch.isLoading
                        ? "Process instances loading..."
                        : "Process has no instances."}
                </Text>
            </InstancePageLayout>
        );
    }

    return (
        <InstancePageLayout process={process} isFetching={isFetching}>
            <TitleWithArrowLink
                title={process?.name}
                href={INTEGRATIONS_BASE_PATH}
            />

            <Text tag="p">{process.description || ""}</Text>

            <ButtonDropdown
                label="Instance actions"
                options={instanceActionOptions}
                onClick={handleInstanceActionsClick}
            />

            <Table>
                <S.IntegrationInstancesTable>
                    <thead>
                        <tr>
                            <th>
                                <div className="sr-only">Select instances</div>

                                <Checkbox
                                    label="Select all"
                                    hidelabel
                                    checked={
                                        selectedInstanceIds.size ===
                                        instances.length
                                    }
                                    onChange={toggleSelectAll}
                                />
                            </th>
                            <th>Status</th>
                            <th>Start time</th>

                            {hasEndTimeColumn && <th>End time</th>}

                            <th>Duration</th>

                            {hasCommentColumn && <th>Comment</th>}

                            <th>
                                <div>
                                    <span className="sr-only">
                                        View details
                                    </span>

                                    <TableSelectColumnsDropdown
                                        label="Select shown extra columns"
                                        options={extraColumns.map((c) => ({
                                            label: c,
                                            value: c,
                                        }))}
                                        selected={[...selectedExtraColumns]}
                                        onChange={
                                            handleSelectedExtraTableColumnsChange
                                        }
                                    />
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {instances.map((i, key) => (
                            <tr key={key}>
                                <td className="select">
                                    <Checkbox
                                        label="Select"
                                        hidelabel
                                        checked={selectedInstanceIds.has(i.id)}
                                        onChange={() => toggleSelectId(i.id)}
                                    />
                                </td>
                                <td className="state">
                                    <div
                                        className={`${i.state.toLowerCase()}${i.acknowledgedUtc ? " acknowledged" : ""}`}
                                    >
                                        {i.state === "Started" && (
                                            <StartedIcon />
                                        )}

                                        {i.state === "Exception" && (
                                            <ExceptionIcon />
                                        )}

                                        {i.state === "Finished" && (
                                            <SuccessIcon />
                                        )}

                                        <Text tag="span">{i.state}</Text>
                                    </div>
                                </td>
                                <td className="starttime">
                                    <Text tag="span">
                                        {Time.toDetailedLocalTime(
                                            i.startTimeUtc,
                                        )}
                                    </Text>
                                </td>

                                {hasEndTimeColumn && (
                                    <td className="endtime">
                                        <Text tag="span">
                                            {Time.toDetailedLocalTime(
                                                i.endTimeUtc,
                                            ) ?? ""}
                                        </Text>
                                    </td>
                                )}

                                <td className="duration">
                                    <Text tag="span">
                                        {Time.getDuration(
                                            i.startTimeUtc,
                                            i.endTimeUtc,
                                        )}
                                    </Text>
                                </td>

                                {hasCommentColumn && (
                                    <td className="comment">
                                        <InstanceCommentCell
                                            comment={i.comment}
                                        />
                                    </td>
                                )}

                                <td className="view-details">
                                    <div>
                                        <Button
                                            type="link"
                                            href={`${INTEGRATIONS_BASE_PATH}/${processId}/instances/${i.executionId}`}
                                            variant="bordered"
                                        >
                                            View details
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </S.IntegrationInstancesTable>
            </Table>

            <div ref={loadMoreInstancesRef} style={{ height: "1px" }} />

            {confirmInstanceActionModalProps && (
                <ConfirmInstanceActionModal
                    {...confirmInstanceActionModalProps}
                    onClose={handleConfirmInstanceActionModalClose}
                />
            )}
        </InstancePageLayout>
    );
};

const InstancePageLayout: React.FC<InstancePageProps> = ({
    process,
    isFetching,
    children,
}) => {
    const breadcrumbs: Breadcrumb[] = React.useMemo(() => {
        if (process) {
            return [
                {
                    label: "Instance list",
                    href: `${INTEGRATIONS_BASE_PATH}/${process.processId}/instances`,
                },
            ];
        }
        return [];
    }, [process]);

    return (
        <S.GridContainer $margin={"1.5rem 0 0 0"}>
            <IntegrationsBreadcrumbs
                breadcrumbs={breadcrumbs}
                organizationId={process?.organizationId}
            />

            <ContainerWithLoadingSpinner loading={isFetching}>
                <S.ContentContainer style={{ gap: "1.313rem" }}>
                    {children}
                </S.ContentContainer>
            </ContainerWithLoadingSpinner>
        </S.GridContainer>
    );
};

const InstanceCommentCell: React.FC<{ comment?: string }> = (props) => {
    if (props.comment === undefined || props.comment === "[]") {
        return null;
    }
    const instanceComments = JSON.parse(
        props.comment,
    ) as ProcessInstanceComment[];

    const tooltipProps = instanceComments.reduce(
        (acc, cur) => ({
            toggleText: acc.toggleText + `${cur.message}\n`,
            text:
                acc.text +
                `${cur.modifier} commented on ${Time.toDetailedLocalTime(cur.modified)}:\n${cur.message}\n`,
        }),
        { toggleText: "", text: "" },
    );

    return (
        <Tooltip toggleText={tooltipProps.toggleText}>
            <span style={{ whiteSpace: "pre-line" }}>{tooltipProps.text}</span>
        </Tooltip>
    );
};

interface InstancePageProps extends React.PropsWithChildren {
    isFetching: boolean;
    process?: Process;
}

export default IntegrationInstances;
