import * as React from "react";
import * as S from "@styled";
import useQuery from "hooks/useQuery";
import { OrganizationRequest } from "model/OrganizationRequest";
import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Text from "components/shared/Text";
import Button, { SaveButton } from "components/shared/Button";
import Modal from "components/shared/Modal";
import Input from "components/shared/Input";
import useDeferredMutation from "hooks/useDeferredMutation";
import usePatchMutation from "hooks/usePatchMutation";
import { queryClient } from "index";

const ORGANIZATION_REQUESTS_BASE_PATH = "organizations/requests";
const invalidatableQueries = [ORGANIZATION_REQUESTS_BASE_PATH, "organizations"];

const DenyRequestModal: React.FC<DenyRequestModalProps> = (props) => {
    const [denyReason, setDenyReason] = React.useState<string>("");

    const denyRequestResponse = usePatchMutation(
        `${ORGANIZATION_REQUESTS_BASE_PATH}/${props.id}`,
        { accepted: false, reasonRejected: denyReason },
        () => {
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return invalidatableQueries.some((s) =>
                        query.queryKey.includes(s),
                    );
                },
            });
        },
        "Failed to deny organization request.",
    );

    const handleConfirmDenial = () => {
        denyRequestResponse.mutate();
    };

    React.useEffect(() => {
        if (denyRequestResponse.isSuccess) {
            props.onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [denyRequestResponse.isSuccess]);

    return (
        <Modal title="Deny request" open={true} onClose={props.onClose}>
            <S.GridContainer
                style={{ width: "57.688rem" }}
                $gap={"1.5rem"}
                $margin={"1.5rem 0 0 0"}
            >
                <Input
                    label="Reason for denial"
                    type="textarea"
                    placeholder="Reason..."
                    value={denyReason}
                    onChange={(e: string) => setDenyReason(e)}
                />
                <S.GridContainer
                    $gridTemplateColumns={"auto auto"}
                    $justifyContent="end"
                    $gap={"2rem"}
                >
                    <Button variant="bordered" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <SaveButton
                        disabled={!denyReason}
                        onClick={handleConfirmDenial}
                        loading={denyRequestResponse.isLoading}
                        success={denyRequestResponse.isSuccess}
                        error={denyRequestResponse.isError}
                    >
                        Confirm denial
                    </SaveButton>
                </S.GridContainer>
            </S.GridContainer>
        </Modal>
    );
};

const OrganizationRequests: React.FC = () => {
    const organizationRequestsResponse = useQuery<OrganizationRequest[]>(
        ORGANIZATION_REQUESTS_BASE_PATH,
        undefined,
        undefined,
        "Failed to fetch organization requests.",
    );

    const acceptOrganizationRequestMutation = useDeferredMutation(
        "PATCH",
        () => {
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return invalidatableQueries.some((s) =>
                        query.queryKey.includes(s),
                    );
                },
            });
        },
        "Failed to accept organization request.",
    );

    const handleAcceptRequest = async (id: number) => {
        await acceptOrganizationRequestMutation.mutateAsync({
            url: `${ORGANIZATION_REQUESTS_BASE_PATH}/${id}`,
            body: { accepted: true },
        });
    };

    const organizationRequests = organizationRequestsResponse.data;
    const [denyRequestModal, setShowDenyRequestModal] = React.useState<
        number | null
    >(null);

    if (!organizationRequests || organizationRequests.length === 0) {
        return null;
    }

    return (
        <ContainerWithLoadingSpinner
            loading={
                organizationRequestsResponse.isLoading ||
                organizationRequestsResponse.isFetching ||
                acceptOrganizationRequestMutation.isLoading
            }
            style={{ marginBottom: "1rem" }}
        >
            <Text tag="h3">Requests</Text>
            <S.CardsContainer style={{ marginTop: "1rem" }}>
                {organizationRequests.map((request) => (
                    <S.Card
                        key={request.id}
                        style={{ gridTemplateColumns: "1fr auto" }}
                    >
                        <div>
                            <Text tag="h3">{request.organizationName}</Text>
                            <Text tag="p">{request.contactEmail}</Text>
                            <Text tag="p">{request.adminMessage}</Text>
                        </div>
                        <S.GridContainer
                            $gridTemplateColumns={"1fr 1fr"}
                            $alignContent="center"
                        >
                            <Button
                                variant="bordered"
                                onClick={() =>
                                    setShowDenyRequestModal(request.id)
                                }
                            >
                                Deny
                            </Button>
                            <Button
                                onClick={() => handleAcceptRequest(request.id)}
                            >
                                Accept
                            </Button>
                        </S.GridContainer>
                    </S.Card>
                ))}
            </S.CardsContainer>
            {denyRequestModal && (
                <DenyRequestModal
                    id={denyRequestModal}
                    onClose={() => setShowDenyRequestModal(null)}
                />
            )}
        </ContainerWithLoadingSpinner>
    );
};

interface DenyRequestModalProps {
    id: number;
    onClose: () => void;
}

export default OrganizationRequests;
