import * as React from "react";
import useDeferredMutation from "./useDeferredMutation";
import useUserData from "./useUserData";
import { LANDING_PATH } from "AppRoutes";

const useLogout = () => {
    const { isLocalLoginType } = useUserData();
    const logoutMutation = useDeferredMutation("GET");

    React.useEffect(() => {
        if (logoutMutation.isSuccess) {
            // do refresh to clear everything from the app's state
            window.location.assign(LANDING_PATH);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutMutation.isSuccess]);

    const logout = () => {
        if (isLocalLoginType) {
            logoutMutation.mutate({ url: "auth/logout" });
        } else {
            window.location.assign("/api/auth/logout/sso");
        }
    };

    return logout;
};

export default useLogout;
