import * as React from "react";

import * as S from "@styled";

import Modal, { ModalProps } from "components/shared/Modal";
import Input from "components/shared/Input";
import Button, { SaveButton } from "components/shared/Button";

export interface ConfirmInstanceActionModalProps extends ModalProps {
    onConfirm: (comment?: string) => void;
    confirming?: boolean;
    confirmError?: boolean;
    commentRequired?: boolean;
}

const ConfirmInstanceActionModal: React.FC<ConfirmInstanceActionModalProps> = (props) => {
    const [comment, setComment] = React.useState('');

    const handleCommentChange = (nextValue: string) => setComment(nextValue);

    const handleCancelClick = () => props.onClose?.();

    const handleConfirmClick = () => {
        const trimmedComment = comment.trim();
        props.onConfirm(trimmedComment !== '' ? trimmedComment : undefined);
    };

    return (
        <Modal
            title={props.title}
            onClose={props.onClose}
            open
        >
            <S.IntegrationInstancesConfirmActionForm>
                <Input
                    label="Comment"
                    type="textarea"
                    value={comment}
                    onChange={handleCommentChange}
                />

                <div>
                    <Button
                        type="button"
                        variant="bordered"
                        onClick={handleCancelClick}
                    >
                        Cancel
                    </Button>

                    <SaveButton
                        type="button"
                        onClick={handleConfirmClick}
                        disabled={!!props.commentRequired && comment.trim() === ''}
                        loading={!!props.confirming}
                        error={!!props.confirmError}
                    >
                        Confirm
                    </SaveButton>
                </div>
            </S.IntegrationInstancesConfirmActionForm>
        </Modal>
    );
};

export default ConfirmInstanceActionModal;