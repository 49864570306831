import * as React from "react";
import { Link } from "react-router-dom";

import { AppContext } from "App";

import * as S from "@styled";

import frends_logo from "../../icons/frends_logo.svg";

import { LANDING_PATH } from "AppRoutes";

const SimplePageContainer: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const { state } = React.useContext(AppContext);
    const portalLogo = state.lookAndFeel.portalLogo || frends_logo;

    return (
        <S.SimplePageLayout>
            <S.SimplePageLogoContainer>
                <Link to={LANDING_PATH}>
                    <img src={portalLogo} alt="Business logo" />
                </Link>
            </S.SimplePageLogoContainer>

            {children}
        </S.SimplePageLayout>
    );
};

export default SimplePageContainer;
