import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useUserData from "hooks/useUserData";
import useDeferredMutation from "hooks/useDeferredMutation";

import Breadcrumbs from "components/shared/Breadcrumbs";
import { Breadcrumb } from "frends-ui-components/dist/types/breadcrumbs/Breadcrumbs";

import { INTEGRATIONS_BASE_PATH } from "AppRoutes";
import { Organization, OrganizationBase } from "model/Organization";

interface IntegrationsBreadcrumbs {
    breadcrumbs: Breadcrumb[];
    organizationId?: number;
}

const IntegrationsBreadcrumbs: React.FC<IntegrationsBreadcrumbs> = (props) => {
    const { isTenantAdmin } = useUserData();
    const location = useLocation();
    const navigate = useNavigate();
    const deferredOrganizationFetch = useDeferredMutation<Organization>("GET");
    const organization: OrganizationBase | undefined =
        location.state?.organization || deferredOrganizationFetch.data;

    React.useEffect(() => {
        if (
            isTenantAdmin &&
            !organization &&
            props.organizationId !== undefined
        ) {
            deferredOrganizationFetch.mutate({
                url: `organizations/${props.organizationId}`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTenantAdmin, organization, props.organizationId]);

    const breadcrumbs = React.useMemo(() => {
        const integrationsCrumb = {
            label: "Integrations",
            href: INTEGRATIONS_BASE_PATH,
        };

        if (!isTenantAdmin) {
            integrationsCrumb.label = "My integrations";
        } else if (organization) {
            integrationsCrumb.label = organization.name;
        }

        return [integrationsCrumb, ...props.breadcrumbs];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTenantAdmin, organization, props.breadcrumbs]);

    return <Breadcrumbs breadcrumbs={breadcrumbs} onClick={navigate} />;
};

export default IntegrationsBreadcrumbs;
