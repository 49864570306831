import * as React from "react";

import Text from "components/shared/Text";

import { PASSWORD_REQUIREMENTS } from "shared/PasswordRequirements";

const PasswordChangeInfo: React.FC = () => (
    <>
        <Text tag="h1">Set a new password</Text>

        <Text tag="p">Password requirements:</Text>

        <ul>
            {PASSWORD_REQUIREMENTS.map((r, i) => (
                <li key={i}>{r}</li>
            ))}
        </ul>
    </>
);

export default PasswordChangeInfo;
