import styled from "styled-components";

export const IntegrationCatalogTextContainer = styled.div`
    display: grid;
    justify-content: center;
    justify-items: center;
    margin-top: 4rem;
    --frends-text--text-align: center;
    --frends-text--margin:  0 0 1rem 0;
`;

export const IntegrationCatalogFilterContainer = styled.div`
    display: grid;
    grid-template-columns: 33% auto 6rem;
    margin: 1rem 0;
    gap: 1rem;
    align-items: end;
    --frends-dropdown--height: 100%;
    --frends-dropdown-menu--list--category--width: 100%;
`;

export const IntegrationCatalogSearchContainer = styled.div`
`;

export const IntegrationCatalogViewSwitchContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    margin-bottom: 0.25rem;
`;

export const IntegrationCatalogSelectedView = styled.button<{ $selected: boolean }>`
    all: unset;
    outline: revert;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.$selected ? `${props.theme.grey3}` : "transparent"};
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
`;

export const IntegrationNameWithArrowLink = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    align-items: center;
`;