import * as React from "react";
import * as S from "@styled";

import SimplePageContainer from "components/simplePages/SimplePageContainer";
import Text from "components/shared/Text";
import Input, { InputRef } from "components/shared/Input";
import Button, { SaveButton } from "components/shared/Button";
import usePostMutation from "hooks/usePostMutation";

const OrganizationCreationRequest: React.FC = () => {
    const nameInputRef = React.useRef<InputRef>(null);
    const emailInputRef = React.useRef<InputRef>(null);
    const messageInputRef = React.useRef<InputRef>(null);

    const [newOrganizationRequest, setNewOrganizationRequest] =
        React.useState<OrganizationCreationRequestProps>({
            organizationName: "",
            contactEmail: "",
            adminMessage: "",
        });
    const [showErrors, setShowErrors] = React.useState(false);

    const createRequestMutation = usePostMutation<
        number,
        OrganizationCreationRequestProps
    >(
        "organizations/requests",
        newOrganizationRequest,
        undefined,
        "Failed to send organization request.",
    );

    const handleChangeRequest = (
        key: keyof OrganizationCreationRequestProps,
        newValue: string,
    ) => {
        setNewOrganizationRequest({
            ...newOrganizationRequest,
            [key]: newValue,
        });
    };

    const isValid =
        nameInputRef.current?.checkValidity() &&
        emailInputRef.current?.checkValidity() &&
        messageInputRef.current?.checkValidity();

    const handleSubmit = () => {
        if (!isValid) {
            setShowErrors(true);
            return;
        }
        createRequestMutation.reset();
        createRequestMutation.mutate();
    };

    return (
        <SimplePageContainer>
            <>
                {!createRequestMutation.isSuccess ? (
                    <S.OrganizationCreationRequestForm>
                        <Text tag="h1">Create new Organization</Text>
                        <Text tag="p">
                            Create new organization to start using the Business
                            Automation Portal.
                        </Text>

                        <Input
                            type="text"
                            ref={nameInputRef}
                            label="Name of organization"
                            placeholder="Enter organization name"
                            required
                            max="255"
                            value={newOrganizationRequest.organizationName}
                            invalid={
                                showErrors &&
                                !nameInputRef.current?.checkValidity()
                            }
                            errortext="Organization name required"
                            onChange={(e: string) =>
                                handleChangeRequest("organizationName", e)
                            }
                        />
                        <Input
                            ref={emailInputRef}
                            type="email"
                            required
                            max="255"
                            label="Contact email"
                            placeholder="Enter contact email address"
                            value={newOrganizationRequest.contactEmail}
                            invalid={
                                showErrors &&
                                !emailInputRef.current?.checkValidity()
                            }
                            errortext="Contact email required"
                            onChange={(e: string) =>
                                handleChangeRequest("contactEmail", e)
                            }
                        />
                        <Input
                            ref={messageInputRef}
                            required
                            type="textarea"
                            rows="4"
                            label="Message to the admistrator"
                            value={newOrganizationRequest.adminMessage}
                            placeholder="Message to the admistrator"
                            invalid={
                                showErrors &&
                                !messageInputRef.current?.checkValidity()
                            }
                            errortext="Message required"
                            onChange={(e: string) =>
                                handleChangeRequest("adminMessage", e)
                            }
                        />
                        <SaveButton
                            type="button"
                            disabled={showErrors && !isValid}
                            onClick={handleSubmit}
                            loading={createRequestMutation.isLoading}
                            error={createRequestMutation.isError}
                        >
                            Submit
                        </SaveButton>
                    </S.OrganizationCreationRequestForm>
                ) : (
                    <S.OrganizationCreationRequestContent>
                        <Text tag="h1">Request sent successfully</Text>
                        <Text tag="p">
                            You will get a notification to your email when the
                            request has been processed.
                        </Text>
                        <Button variant="bordered" type="link" href="/landing">
                            Back to landing page
                        </Button>
                    </S.OrganizationCreationRequestContent>
                )}
            </>
        </SimplePageContainer>
    );
};

interface OrganizationCreationRequestProps {
    organizationName: string;
    contactEmail: string;
    adminMessage: string;
}

export default OrganizationCreationRequest;
