import styled from "styled-components";

export const ContentContainer = styled.section`
    margin-top: 1rem;
    display: grid;
    gap: 0.5rem;
    background-color: ${props => props.theme.white};
    border-radius: 1.563rem;
    padding: 1.5rem;
    min-height: 5rem;
`