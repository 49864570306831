import { FetchError } from "model/FetchError";

class Utilities {
    static async fetchBase(url: string, method: string, body?: unknown) {
        const headers = new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
        });

        if (unsafeHttpMethods.includes(method.toUpperCase() as HttpMethod)) {
            headers.set(
                this.XSRFTokenHeaderIdentifier,
                await this.getXSRFToken(),
            );
        }

        const response = await fetch(`/api/${url}`, {
            credentials: "include",
            method: method,
            body: body ? (JSON.stringify(body) as BodyInit) : undefined,
            headers: headers,
        });

        const content = await response.text();
        if (!response.ok) {
            return Promise.reject({
                content,
                status: response.status,
            } as FetchError);
        }

        try {
            return Promise.resolve(JSON.parse(content));
        } catch (e) {
            return Promise.resolve(content);
        }
    }

    static mapQueryParameters(
        url: string,
        queryParameters?: Record<string, string | string[] | number | number[]>,
    ) {
        if (queryParameters) {
            // original from https://stackoverflow.com/a/34209399
            const query = Object.keys(queryParameters)
                .filter((k) => queryParameters[k] != null)
                .map((k) => {
                    const key = encodeURIComponent(k);
                    const val = queryParameters[k];
                    if (Array.isArray(val)) {
                        return (
                            key +
                            "=" +
                            val
                                .map((v) => encodeURIComponent(v))
                                .join(`&${key}=`)
                        );
                    } else {
                        // val can't be null here
                        return key + "=" + encodeURIComponent(val as string);
                    }
                })
                .join("&");
            return url + "?" + query;
        }

        return url;
    }

    static downloadFile = (content: string, name: string, type: string) => {
        const blob = new Blob([content], { type });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    static parseApiCallErrorMsg = (errorMsg?: string) =>
        errorMsg ? `${errorMsg}\n` : "";

    static deserialize = <T>(json: string): T | null => {
        try {
            return JSON.parse(json) as T;
        } catch (e) {
            return null;
        }
    };

    public static XSRFTokenHeaderIdentifier = "X-XSRF-Token";

    public static async getXSRFToken(): Promise<string> {
        const token = await fetch("/api/antiforgery/token", {
            method: "GET",
            credentials: "include",
        });

        if (!token.ok) {
            throw new Error("Could not set XSRF token");
        }

        const xsrfToken = this.getCookies()[this.XSRFTokenHeaderIdentifier];
        if (!xsrfToken) {
            throw new Error("XSRF token not found");
        }

        return xsrfToken;
    }

    public static getCookies() {
        const cookies: { [key: string]: string } = {};
        for (const cookie of document.cookie.split("; ")) {
            const [name, value] = cookie.split("=");
            cookies[name] = decodeURIComponent(value);
        }
        return cookies;
    }
}

export default Utilities;

type HttpMethod =
    | "GET"
    | "POST"
    | "PUT"
    | "DELETE"
    | "PATCH"
    | "HEAD"
    | "OPTIONS"
    | "CONNECT"
    | "TRACE";
const unsafeHttpMethods: HttpMethod[] = ["POST", "PUT", "DELETE", "PATCH"];
