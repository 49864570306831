export interface AvailableIntegration {
    templateTagName: string;
    integrationCount: number;
}

export interface IntegrationCategory {
    name: string;
    tags: string[];
    id?: number;
    integrationCount?: number;
    [key: string]: unknown;
}

export enum EnvironmentName {
    Test = 'Test',
    Production = 'Production',
}

export interface Environment {
    displayName: EnvironmentName;
    internalName: string;
    agentGroups: AgentGroup[];
    selectedAgentGroup?: string;
}

export interface AgentGroup {
    id: number;
    displayName: string;
    internalName: string;
    agents: string[];
    isCrossPlatform: boolean;
}