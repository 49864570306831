import * as React from "react";

import type FrendsTooltipProps from "frends-ui-components/dist/types/tooltip/Tooltip";
import "frends-ui-components/Toggle";

type TooltipProps = React.PropsWithChildren & Partial<Pick<FrendsTooltipProps, "placement" | "toggleText">>;

const Tooltip: React.FC<TooltipProps> = (props) => {
    const ref = React.useRef<FrendsTooltipProps>(null);

    React.useEffect(() => {
        if (ref.current && props.placement !== undefined) {
            ref.current.placement = props.placement;
        }
    }, [props.placement]);

    React.useEffect(() => {
        if (ref.current && props.toggleText !== undefined) {
            ref.current.toggleText = props.toggleText;
        }
    }, [props.toggleText]);

    return (
        <frends-tooltip
            ref={ref}
        >
            {props.children}
        </frends-tooltip>
    );
}

export default Tooltip;