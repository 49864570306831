import * as React from "react";

import type FrendsStepperProps from "frends-ui-components/dist/types/stepper/Stepper";
import { type ChangeEventDetail } from "frends-ui-components/dist/types/stepper/Stepper";
import "frends-ui-components/Stepper";

type StepperProps = {
    onChange?: (nextValue: string | number) => void;
} & Partial<Pick<FrendsStepperProps, "steps">>;

const Stepper: React.FC<StepperProps> = (props) => {
    const ref = React.useRef<FrendsStepperProps>(null);
    React.useEffect(() => {
        if (ref.current && props.steps !== undefined) {
            ref.current.steps = props.steps;
        }
    }, [props.steps]);

    React.useEffect(() => {
        const onInputChange = (event: CustomEvent<ChangeEventDetail>) => {
            props.onChange?.(event.detail.selected);
        };

        const { current } = ref;

        current?.addEventListener("change", onInputChange);

        return () => current?.removeEventListener("change", onInputChange);
    }, [props]);

    return <frends-stepper ref={ref} updateSelectedStep={() => false} />;
};

export default Stepper;
