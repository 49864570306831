import * as React from "react";
import * as S from "@styled";
import Input from "components/shared/Input";
import Text from "components/shared/Text";
import ImageUploader from "components/shared/ImageUploader";
import { LookAndFeelModel } from "model/LookAndFeelModel";
import { AppContext } from "App";
import { SaveButton } from "components/shared/Button";
import usePutMutation from "hooks/usePutMutation";
import { queryClient } from "index";
import ColorPicker from "components/shared/ColorPicker";
import Divider from "components/shared/Divider";

const LookAndFeel: React.FC = () => {
    const { state } = React.useContext(AppContext);
    const [lookAndFeel, setLookAndFeel] = React.useState<ModifiableLookAndFeel>(
        {
            accentColor: "",
            backgroundColor: "",
            buttonColor: "",
            catalogPageText: "",
            catalogPageTitle: "",
            landingPageText: "",
            landingPageTitle: "",
            pageTitlePrefix: "",
            customCss: "",
            customHtmlHead: "",
        },
    );

    React.useEffect(() => {
        if (state.lookAndFeel) {
            setLookAndFeel(state.lookAndFeel);
        }
    }, [state.lookAndFeel]);

    const defaultColors = React.useMemo(() => getDefaultColors(), []);

    const updateLookAndFeel = usePutMutation(
        "lookandfeel",
        lookAndFeel,
        () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: "lookandfeel" });
        },
        "Failed to save look and feel.",
    );

    const handleLookAndFeelChange = (
        key: keyof ModifiableLookAndFeel,
        value: string,
    ) => {
        setLookAndFeel({ ...lookAndFeel, [key]: value });
    };

    const handleSubmitLookAndFeel = () => {
        updateLookAndFeel.mutate();
    };

    return (
        <div>
            <S.LookAndFeelPageForm>
                <Text tag="h2">Configure logos and favicon</Text>
                <ImageUploader
                    existingImageSrc={state.lookAndFeel.portalLogo}
                    label="Portal logo"
                    imageType="portal_logo"
                />
                <ImageUploader
                    existingImageSrc={state.lookAndFeel.favIcon}
                    label="Favicon"
                    imageType="favicon"
                />
                <Divider />

                <Text tag="h2">Configure texts</Text>
                <Input
                    label="Catalog page title"
                    value={lookAndFeel.catalogPageTitle ?? ""}
                    onChange={(newValue: string) =>
                        handleLookAndFeelChange("catalogPageTitle", newValue)
                    }
                />
                <Input
                    label="Catalog page text"
                    value={lookAndFeel.catalogPageText ?? ""}
                    onChange={(newValue: string) =>
                        handleLookAndFeelChange("catalogPageText", newValue)
                    }
                />
                <Input
                    label="Page title prefix"
                    value={lookAndFeel.pageTitlePrefix ?? ""}
                    onChange={(newValue: string) =>
                        handleLookAndFeelChange("pageTitlePrefix", newValue)
                    }
                />
                <Input
                    label="Landing page title"
                    value={lookAndFeel.landingPageTitle ?? ""}
                    onChange={(newValue: string) =>
                        handleLookAndFeelChange("landingPageTitle", newValue)
                    }
                />
                <Input
                    label="Landing page text"
                    value={lookAndFeel.landingPageText ?? ""}
                    onChange={(newValue: string) =>
                        handleLookAndFeelChange("landingPageText", newValue)
                    }
                />
                <Divider />

                <Text tag="h2">Configure images</Text>
                <ImageUploader
                    existingImageSrc={state.lookAndFeel.landingPageImage}
                    label="Landing page image"
                    imageType="landing_page_image"
                />
                <ImageUploader
                    existingImageSrc={state.lookAndFeel.landingPageBackground}
                    label="Landing page background"
                    imageType="landing_page_background"
                />
                <Divider />

                <Text tag="h2">Colors</Text>
                <S.LookAndFeelColorsContainer>
                    <ColorPicker
                        label="Background color"
                        value={
                            lookAndFeel.backgroundColor ??
                            defaultColors.backgroundColor
                        }
                        onChange={(color: string) =>
                            handleLookAndFeelChange("backgroundColor", color)
                        }
                    />
                    <ColorPicker
                        label="Button color"
                        defaultValue=""
                        value={
                            lookAndFeel.buttonColor ?? defaultColors.buttonColor
                        }
                        onChange={(color: string) =>
                            handleLookAndFeelChange("buttonColor", color)
                        }
                    />
                    <ColorPicker
                        label="Accent color"
                        value={
                            lookAndFeel.accentColor ?? defaultColors.accentColor
                        }
                        onChange={(color: string) =>
                            handleLookAndFeelChange("accentColor", color)
                        }
                    />
                </S.LookAndFeelColorsContainer>

                <S.LookAndFeelInjectionsContainer>
                    <Input
                        type="textarea"
                        label="Inject custom CSS"
                        value={lookAndFeel.customCss ?? ""}
                        onChange={(newValue: string) =>
                            handleLookAndFeelChange("customCss", newValue)
                        }
                    />
                    <Input
                        type="textarea"
                        label="Inject HTML head tags"
                        value={lookAndFeel.customHtmlHead ?? ""}
                        onChange={(newValue: string) =>
                            handleLookAndFeelChange("customHtmlHead", newValue)
                        }
                    />
                </S.LookAndFeelInjectionsContainer>

                <S.SettingsSaveButtonContainer>
                    <SaveButton
                        onClick={handleSubmitLookAndFeel}
                        type="button"
                        loading={updateLookAndFeel.isLoading}
                        success={updateLookAndFeel.isSuccess}
                        error={updateLookAndFeel.isError}
                    >
                        Save
                    </SaveButton>
                </S.SettingsSaveButtonContainer>
            </S.LookAndFeelPageForm>
        </div>
    );
};

const getDefaultColors = () => {
    const root = document.documentElement;
    const computedStyles = getComputedStyle(root);

    const backgroundColor = computedStyles
        .getPropertyValue("--background-color")
        .trim();
    const buttonColor = computedStyles
        .getPropertyValue("--button-color")
        .trim();
    const accentColor = computedStyles
        .getPropertyValue("--accent-color")
        .trim();

    return { backgroundColor, buttonColor, accentColor };
};

type ModifiableLookAndFeel = Partial<
    Omit<
        LookAndFeelModel,
        "favIcon" | "portalLogo" | "landingPageImage" | "landingPageBackground"
    >
>;

export default LookAndFeel;
