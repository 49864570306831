import * as React from "react";
import { useNavigate } from "react-router-dom";

import useUserData from "hooks/useUserData";
import usePutMutation from "hooks/usePutMutation";
import useDeferredMutation from "hooks/useDeferredMutation";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Text from "components/shared/Text";
import IntegrationFilters from "components/integrations/IntegrationFilters";
import Divider from "components/shared/Divider";
import Button, {
    CloseButton,
    DropdownButton,
    SaveButton,
} from "components/shared/Button";
import Toggle from "components/shared/Toggle";

import { OrganizationBase } from "model/Organization";
import { Process, ProcessCounts } from "model/process/Process";

import {
    INTEGRATION_CATALOG_BASE_PATH,
    INTEGRATIONS_BASE_PATH,
} from "AppRoutes";

export const PROCESSES_APIROUTE = "processes";

const MyIntegrations: React.FC = () => {
    const { selectedOrganization } = useUserData();

    return (
        <div>
            <S.MyIntegrationsTitleContainer>
                <Text tag="h1">My integrations</Text>
            </S.MyIntegrationsTitleContainer>

            <ProcessesExecutionCounts
                organizationId={selectedOrganization!.id}
            />

            <ProcessList organization={selectedOrganization!} />
        </div>
    );
};

const ProcessesExecutionCounts: React.FC<{ organizationId: number }> = (
    props,
) => {
    const deferredCountsFetch = useDeferredMutation<ProcessCounts>("GET");

    React.useEffect(() => {
        deferredCountsFetch.reset();
        deferredCountsFetch.mutate({
            url: `${PROCESSES_APIROUTE}/all-counts`,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.organizationId]);

    const getSuccessRatio = () => {
        if (successes === undefined || executions === undefined) {
            return undefined;
        }

        // Cant calculate ratio if both are 0
        if (successes === 0 && executions === 0) {
            return undefined;
        }

        return `${Number((successes / executions) * 100).toFixed(1)}%`;
    };

    const counts = deferredCountsFetch.data;
    const processes = counts?.processesCount;
    const executions = counts?.totalExecutionCount;
    const errors = counts?.failedExecutionCount;
    const successes = counts?.successfulExecutionCount;
    const successRatio = getSuccessRatio();

    return (
        <S.MyIntegrationsKPIContainer>
            <Text>Last 7 days</Text>

            <ContainerWithLoadingSpinner
                loading={deferredCountsFetch.isLoading}
                style={{ minHeight: "7.438rem" }}
            >
                <CountLarge value={processes} label="Processes" />

                <CountLarge value={executions} label="Executions" />

                <CountLarge value={errors} label="Errors" />

                <CountLarge value={successRatio} label="Success" />
            </ContainerWithLoadingSpinner>
        </S.MyIntegrationsKPIContainer>
    );
};

const ProcessList: React.FC<{ organization: OrganizationBase }> = (props) => {
    const isListViewStorageKey = "frends_bap_my_integrations_is_list_view";
    const [processes, setProcesses] = React.useState<Process[]>([]);
    const [processTags, setProcessTags] = React.useState<string[]>([]);
    const [tagFilter, setTagFilter] = React.useState<string[]>([]);
    const [searchFilter, setSearchFilter] = React.useState<string>("");
    const initialIsListView =
        String(localStorage.getItem(isListViewStorageKey)) === "true";
    const [isListView, setIsListView] = React.useState(initialIsListView);

    const deferredProcessesFetch = useDeferredMutation<Process[]>("GET");

    React.useEffect(() => {
        deferredProcessesFetch.reset();
        deferredProcessesFetch.mutate({
            url: `${PROCESSES_APIROUTE}/organizations/${props.organization.id}`,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.organization.id]);

    React.useEffect(() => {
        if (deferredProcessesFetch.data) {
            const uniqueTags = Array.from(
                new Set(deferredProcessesFetch.data.flatMap((i) => i.tags)),
            );
            setProcessTags(uniqueTags);
        }
    }, [deferredProcessesFetch.data]);

    React.useEffect(() => {
        if (deferredProcessesFetch.data) {
            const filteredProcesses = deferredProcessesFetch.data.filter(
                (process) => {
                    const nameMatch = searchFilter
                        ? process.name
                              .toLowerCase()
                              .includes(searchFilter.toLowerCase())
                        : true;
                    const processTagsMatch =
                        tagFilter.length > 0
                            ? process.tags?.some((tag) =>
                                  tagFilter.includes(tag),
                              )
                            : true;
                    return nameMatch && processTagsMatch;
                },
            );
            setProcesses(filteredProcesses);
        }
    }, [deferredProcessesFetch.data, tagFilter, searchFilter]);

    const handleChangeTagFilter = (nextValues: string[]) =>
        setTagFilter(nextValues);

    const handleChangeSearchFilter = (nextValue: string) =>
        setSearchFilter(nextValue);

    const handleChangeLayout = (next: boolean) => {
        setIsListView(next);
        localStorage.setItem(isListViewStorageKey, String(next));
    };

    return (
        <>
            <IntegrationFilters
                tags={processTags}
                isListView={isListView}
                searchFilter={searchFilter}
                tagFilter={tagFilter}
                onChangeLayout={handleChangeLayout}
                onChangeSearchFilter={handleChangeSearchFilter}
                onChangeTagFilter={handleChangeTagFilter}
            />

            <ContainerWithLoadingSpinner
                loading={deferredProcessesFetch.isLoading}
                style={{ minHeight: "5rem" }}
            >
                {deferredProcessesFetch.data && processes.length > 0 && (
                    <S.MyIntegrationsList $cols={isListView ? 1 : 3}>
                        {processes.map((i, idx) => (
                            <React.Fragment key={idx}>
                                {isListView ? (
                                    <IntegrationsLargeListItem
                                        process={i}
                                        organization={props.organization}
                                    />
                                ) : (
                                    <IntegrationsListItem
                                        process={i}
                                        organization={props.organization}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </S.MyIntegrationsList>
                )}

                {!deferredProcessesFetch.isLoading &&
                    processes.length === 0 && (
                        <Text tag="h3">No integrations found</Text>
                    )}
            </ContainerWithLoadingSpinner>
        </>
    );
};

interface CountProps {
    value?: string | number;
    label: string;
}

const CountLarge: React.FC<CountProps> = ({ value = "-", label }) => (
    <S.MyIntegrationsCountLarge>
        <Text tag="span" size="bold">
            {value}
        </Text>

        <br />

        <Text tag="span" appearance="h2">
            {label}
        </Text>
    </S.MyIntegrationsCountLarge>
);

const Count: React.FC<CountProps> = ({ value = "-", label }) => (
    <S.MyIntegrationsCountLarge>
        <Text tag="span" appearance="h2" size="bold">
            {value}
        </Text>

        <br />

        <Text tag="span" appearance="label">
            {label}
        </Text>
    </S.MyIntegrationsCountLarge>
);

interface IntegrationsListItemProps {
    process: Process;
    organization: OrganizationBase;
}

const IntegrationsListItem: React.FC<IntegrationsListItemProps> = ({
    process,
    organization,
}) => {
    const [actionsOpen, setActionsOpen] = React.useState(false);

    const toggleActionsOpen = () => setActionsOpen((prev) => !prev);

    return (
        <S.MyIntegrationsListItem>
            <Text tag="h3">{process.name}</Text>

            <Divider />

            {actionsOpen ? (
                <S.MyIntegrationsListItemActiveToggleContainer>
                    <IntegrationActivateToggle process={process} />
                </S.MyIntegrationsListItemActiveToggleContainer>
            ) : (
                <S.MyIntegrationsListItemKPIContainer>
                    <Count
                        value={process.totalExecutionCount}
                        label="Executions"
                    />
                    <Count
                        value={process.failedExecutionCount}
                        label="Errors"
                    />
                </S.MyIntegrationsListItemKPIContainer>
            )}

            <S.MyIntegrationsListItemActionsContainer
                $justifyContent={actionsOpen ? "space-evenly" : "space-between"}
            >
                <ViewInstancesLink
                    process={process}
                    organization={organization}
                />

                {actionsOpen ? (
                    <>
                        <ManuallyRunLink
                            process={process}
                            organization={organization}
                        />

                        <IntegrationUpdateButton process={process} />

                        <ReconfigureLink
                            process={process}
                            organization={organization}
                        />

                        <CloseButton
                            title="Hide actions"
                            onClick={toggleActionsOpen}
                        />
                    </>
                ) : (
                    <DropdownButton
                        title="Show actions"
                        onClick={toggleActionsOpen}
                    >
                        Actions
                    </DropdownButton>
                )}
            </S.MyIntegrationsListItemActionsContainer>
        </S.MyIntegrationsListItem>
    );
};

const IntegrationsLargeListItem: React.FC<IntegrationsListItemProps> = ({
    process,
    organization,
}) => {
    return (
        <S.MyIntegrationsLargeListItem>
            <S.MyIntegrationsLargeListItemTitleContainer>
                <IntegrationActivateToggle process={process} hidelabel />

                <Text tag="h2">{process.name}</Text>
            </S.MyIntegrationsLargeListItemTitleContainer>

            <Text tag="p">{process.description}</Text>

            <S.MyIntegrationsLargeListItemActionContainer>
                <S.MyIntegrationsLargeListItemActionContainer>
                    <ViewInstancesLink
                        process={process}
                        organization={organization}
                    />

                    <ManuallyRunLink
                        process={process}
                        organization={organization}
                    />
                </S.MyIntegrationsLargeListItemActionContainer>

                <S.MyIntegrationsLargeListItemActionContainer>
                    <IntegrationUpdateButton process={process} />

                    <ReconfigureLink
                        process={process}
                        organization={organization}
                    />
                </S.MyIntegrationsLargeListItemActionContainer>
            </S.MyIntegrationsLargeListItemActionContainer>
        </S.MyIntegrationsLargeListItem>
    );
};

interface IntegrationActivateToggleProps {
    process: Process;
    hidelabel?: boolean;
}

const IntegrationActivateToggle: React.FC<IntegrationActivateToggleProps> = (
    props,
) => {
    const [active, setActive] = React.useState(props.process.triggersActive);

    const activate = useDeferredMutation(
        "PUT",
        undefined,
        "Failed to activate process.",
    );

    React.useEffect(() => {
        if (activate.error) {
            setActive((prev) => !prev);
        }
    }, [activate.error]);

    const toggleActive = () => {
        activate.reset();
        const nextActive = !active;
        activate.mutate({
            url: `${PROCESSES_APIROUTE}/${props.process.deploymentId}/${nextActive ? "disable" : "enable"}`,
        });
        setActive(nextActive);
    };

    return (
        <ContainerWithLoadingSpinner loading={activate.isLoading}>
            <Toggle
                label="Active"
                hidelabel={props.hidelabel}
                ontext="YES"
                offtext="NO"
                checked={active}
                onChange={toggleActive}
            />
        </ContainerWithLoadingSpinner>
    );
};

type IntegrationLinkProps = Omit<IntegrationLinkBaseProps, "href">;

const ViewInstancesLink: React.FC<IntegrationLinkProps> = (props) => (
    <IntegrationLinkBase
        href={`${INTEGRATIONS_BASE_PATH}/${props.process.processId}/instances`}
        process={props.process}
        organization={props.organization}
    >
        View instances
    </IntegrationLinkBase>
);

const ManuallyRunLink: React.FC<IntegrationLinkProps> = (props) => (
    <IntegrationLinkBase
        href={`${INTEGRATIONS_BASE_PATH}/${props.process.processId}/manually-run`}
        process={props.process}
        organization={props.organization}
    >
        Manual run
    </IntegrationLinkBase>
);

const ReconfigureLink: React.FC<IntegrationLinkProps> = (props) => (
    <IntegrationLinkBase
        href={`${INTEGRATION_CATALOG_BASE_PATH}/${props.process.templateId}/setup`}
        process={props.process}
        organization={props.organization}
    >
        Reconfigure
    </IntegrationLinkBase>
);

interface IntegrationLinkBaseProps {
    href: string;
    process: Process;
    organization: OrganizationBase;
}

const IntegrationLinkBase: React.FC<
    React.PropsWithChildren<IntegrationLinkBaseProps>
> = (props) => {
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        navigate(event.currentTarget.href, {
            state: {
                processId: props.process.processId,
                organization: props.organization,
                previousPage: INTEGRATIONS_BASE_PATH,
            },
        });
    };

    return (
        <Button
            type="link"
            variant="bordered"
            onClick={handleClick}
            href={props.href}
        >
            {props.children}
        </Button>
    );
};

const IntegrationUpdateButton: React.FC<{ process: Process }> = ({
    process,
}) => {
    const update = usePutMutation(
        `${PROCESSES_APIROUTE}/${process.processId}`,
        { templateId: process.templateId },
        undefined,
        "Failed to update process.",
    );

    const handleUpdateClick = () => {
        if (!update.isLoading) {
            update.reset();
            update.mutate();
        }
    };

    return (
        <SaveButton
            variant="bordered"
            onClick={handleUpdateClick}
            loading={update.isLoading}
            success={update.isSuccess}
            error={update.isError}
        >
            Update
        </SaveButton>
    );
};

export default MyIntegrations;
