import * as React from "react";
import { useLocation } from "react-router-dom";

import useDeferredMutation from "hooks/useDeferredMutation";

import * as S from "@styled";

import SimplePageContainer from "components/simplePages/SimplePageContainer";
import Text from "components/shared/Text";
import Input, { InputRef } from "components/shared/Input";
import { SaveButton } from "components/shared/Button";
import PasswordActionSuccess from "components/passwords/PasswordActionSuccess";

import { LANDING_PATH } from "AppRoutes";

const ForgotPassword: React.FC = () => {
    const location = useLocation();
    const [email, setEmail] = React.useState(location.state?.email ?? "");
    const emailInputRef = React.useRef<InputRef>(null);

    const [submitTried, setSubmitTried] = React.useState(false);

    const forgotPasswordPOST = useDeferredMutation("POST");

    const handleFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        setSubmitTried(true);

        if (emailInputRef.current?.checkValidity()) {
            forgotPasswordPOST.mutate({
                url: "account/forgotPassword",
                body: {
                    email,
                },
            });
        }
    };

    return (
        <SimplePageContainer>
            {!forgotPasswordPOST.isSuccess && (
                <S.LoginForm onSubmit={handleFormSubmit}>
                    <Text tag="h1">Reset your password</Text>

                    <Input
                        ref={emailInputRef}
                        label="Email"
                        type="email"
                        autocomplete="username current-username"
                        required
                        value={email}
                        onChange={(value: string) => setEmail(value)}
                        invalid={
                            submitTried &&
                            !emailInputRef.current?.checkValidity()
                        }
                        errortext="Email required"
                    />

                    <SaveButton
                        type="submit"
                        disabled={
                            submitTried &&
                            !emailInputRef.current?.checkValidity()
                        }
                        loading={forgotPasswordPOST.isLoading}
                        error={forgotPasswordPOST.isError}
                    >
                        Reset
                    </SaveButton>
                </S.LoginForm>
            )}

            {forgotPasswordPOST.isSuccess && (
                <PasswordActionSuccess
                    title="Password reset request sent successfully!"
                    body="Please check your email and follow the provided link"
                    link={{ href: LANDING_PATH, text: "Back to landing page" }}
                />
            )}
        </SimplePageContainer>
    );
};

export default ForgotPassword;
