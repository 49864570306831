import * as React from "react";

import type FrendsDropdownButtonProps from "frends-ui-components/dist/types/dropdown-button/DropdownButton";
import { type OptionClickEventDetail } from "frends-ui-components/dist/types/dropdown-button/DropdownButton";
import "frends-ui-components/DropdownButton";

type ButtonDropdownProps = React.PropsWithChildren<{
    onClick?: (nextValue: string | number) => void;
}> & Partial<Pick<FrendsDropdownButtonProps,
    'label' |
    'disabled' |
    'options'
>>;

const ButtonDropdown: React.FC<ButtonDropdownProps> = (props) => {
    const ref = React.useRef<FrendsDropdownButtonProps>(null);

    React.useEffect(() => {
        if (ref.current && props.options !== undefined) {
            ref.current.options = props.options;
        }
    }, [props.options]);

    React.useEffect(() => {
        if (ref.current && props.disabled !== undefined) {
            ref.current.disabled = props.disabled;
        }
    }, [props.disabled]);

    React.useEffect(() => {
        const onDropdownOptionClick = (event: CustomEvent) => {
            const details = event.detail as OptionClickEventDetail;
            props.onClick?.(details.clicked);
        };
        const { current } = ref;

        current?.addEventListener('option-click', onDropdownOptionClick);

        return () => current?.removeEventListener('option-click', onDropdownOptionClick);
    }, [props]);


    return (
        <frends-dropdown-button
            ref={ref}
            label={props.label}
        />
    );
};

export default ButtonDropdown;