import { parseISO } from "date-fns";
import { format as formatFNS, toZonedTime } from "date-fns-tz";


export default class Time {
    public static dateTimeFormat = "yyyy-MM-dd HH:mm:ss";
    public static dateFormat = "yyyy-MM-dd";

    /**
     * toDetailedLocalTime converts a UTC timestamp to local detailed date time
     * @param dateTimeUtc
     * @returns local detailed date time
    */
    public static toDetailedLocalTime(dateTimeUtc?: string | Date): string {
        if (!dateTimeUtc) {
            return "never";
        }
        if (dateTimeUtc instanceof Date) {
            return this.format(dateTimeUtc, Time.dateTimeFormat);
        }
        return this._fromUtcToLocalFormat(dateTimeUtc, Time.dateTimeFormat);
    }

    /**
    * format formats local {@link dateTime} into {@link format} 
    * @param dateTime 
    * @param format 
    * @returns formatted local date time
    */
    public static format(dateTime: Date, format: string): string {
        return formatFNS(dateTime, format);
    }

    public static isWithinTenMinutes = (timestamp?: string) => {
        const differenceInMinutes = Math.abs(Number(timestamp || Infinity) - new Date().getTime()) / (1000 * 60);
        return differenceInMinutes <= 10;
    };

    public static getDuration = (timestamp1?: string, timestamp2?: string) => {
        if (!timestamp1 || !timestamp2) {
            return '';
        }

        const diff = Math.abs(new Date(timestamp2).getTime() - new Date(timestamp1).getTime());
        const s = Math.round(diff / 1000);
        return `${s}s`;
    };

    private static _fromUtcToLocal(dateTimeUtc: string): Date {
        const parsedDateTime = parseISO(dateTimeUtc);
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const localDateTime = toZonedTime(parsedDateTime, localTimeZone);
        return localDateTime;
    }


    private static _fromUtcToLocalFormat(dateTimeUtc: string, timeFormat: string): string {
        const normalizedDateTimeUtc = this._ensureISO8601UtcFormat(dateTimeUtc);
        const localDateTime = this._fromUtcToLocal(normalizedDateTimeUtc);
        return this.format(localDateTime, timeFormat);
    }

    private static _ensureISO8601UtcFormat(dateTimeUtc: string): string {
        // In some cases the returned date from the backend doesn't include Z which is required for date-fns
        let normalizedDateTimeUtc = dateTimeUtc.toUpperCase();
        if (normalizedDateTimeUtc.includes("T") && !normalizedDateTimeUtc.endsWith("Z")) {
            normalizedDateTimeUtc += "Z";
        }
        return normalizedDateTimeUtc;
    }
}