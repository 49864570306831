import styled from "styled-components";

export const ContainerWithLoadingSpinner = styled.div`
  position: relative;

  > div:last-child:has(> svg) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
`;