import * as React from "react";

import useQuery from "hooks/useQuery";
import usePostMutation from "hooks/usePostMutation";
import { InviteUserRequest, UserLoginType, UserRole } from "model/User";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Dropdown from "components/shared/Dropdown";
import { SaveButton } from "components/shared/Button";
import Modal, { ModalProps } from "components/shared/Modal";
import RadioGroup from "components/shared/RadioGroup";
import Input from "components/shared/Input";

import { OrganizationBase } from "model/Organization";
import { Option } from "frends-ui-components/dist/types/dropdown/Dropdown";

interface InviteUserModalProps extends ModalProps {
    organizationId: number;
    initialOrganizationSelectionAllowed?: boolean;
}

const InviteUserModal: React.FC<InviteUserModalProps> = (props) => {
    const [organizationId, setOrganizationId] = React.useState(
        props.organizationId,
    );

    const [inviteData, setInviteData] = React.useState<InviteUserRequest>({
        loginType: UserLoginType.SSO,
        role: UserRole.User,
        email: "",
        message: "",
    });

    const [submitDataTriedOnce, setSubmitDataTriedOnce] = React.useState(false);

    const organizationsFetch = useQuery<OrganizationBase[]>(
        "organizations",
        undefined,
        { enabled: !!props.initialOrganizationSelectionAllowed },
        "Failed to fetch organizations.",
    );
    const initialOrganizationOptions: Option[] =
        organizationsFetch.data?.map((org) => ({
            label: org.name,
            value: org.id,
        })) ?? [];

    const handleInitialOrganizationChange = (organizationId: number) =>
        setOrganizationId(organizationId);

    const handleInviteDataChange = <T extends keyof InviteUserRequest>(
        key: T,
        value: InviteUserRequest[T],
    ) => setInviteData((prev) => ({ ...prev, [key]: value }));

    const getRadioGroupOptions = (en: Record<string, string>) =>
        Object.values(en).map((val) => ({ label: val, value: val }));

    const handleAccountTypeChange = (type: string) =>
        handleInviteDataChange("loginType", type as UserLoginType);

    const handleRoleChange = (role: string) =>
        handleInviteDataChange("role", role as UserRole);

    const handleEmailChange = (email: string) =>
        handleInviteDataChange("email", email);

    const emailValid = inviteData.email !== "";

    const handleMessageChange = (message: string) =>
        handleInviteDataChange("message", message);

    const inviteDataValid = emailValid;

    const postUserInvite = usePostMutation(
        `account/inviteuser/${organizationId}`,
        inviteData,
        undefined,
        "Failed to invite user.",
    );

    const handleInviteButtonClick = () => {
        if (inviteDataValid) {
            postUserInvite.mutate();
        } else {
            setSubmitDataTriedOnce(true);
        }
    };

    return (
        <Modal
            title="Invite new user"
            open={props.open}
            onClose={props.onClose}
            closeOnBackdropClick
        >
            <ContainerWithLoadingSpinner loading={organizationsFetch.isLoading}>
                <S.InviteNewUserModalForm>
                    {props.initialOrganizationSelectionAllowed && (
                        <Dropdown
                            label="Initial organization"
                            options={initialOrganizationOptions}
                            selected={organizationId}
                            onChange={handleInitialOrganizationChange}
                        />
                    )}

                    <RadioGroup
                        title="Account type"
                        name="account-type"
                        options={getRadioGroupOptions(UserLoginType)}
                        select={inviteData.loginType}
                        onChange={handleAccountTypeChange}
                    />

                    <RadioGroup
                        title="Role"
                        name="role"
                        options={getRadioGroupOptions(UserRole).filter(
                            (o) => o.value !== UserRole.TenantAdmin,
                        )}
                        select={inviteData.role}
                        onChange={handleRoleChange}
                    />

                    <Input
                        label="Email"
                        type="text"
                        invalid={submitDataTriedOnce && !emailValid}
                        errortext="Email required"
                        value={inviteData.email}
                        onChange={handleEmailChange}
                    />

                    <Input
                        label="Message"
                        type="textarea"
                        rows="3"
                        value={inviteData.message}
                        onChange={handleMessageChange}
                    />

                    <div>
                        <SaveButton
                            type="button"
                            variant="bordered"
                            disabled={submitDataTriedOnce && !inviteDataValid}
                            loading={postUserInvite.isLoading}
                            error={!!postUserInvite.error}
                            onClick={handleInviteButtonClick}
                        >
                            Invite
                        </SaveButton>
                    </div>
                </S.InviteNewUserModalForm>
            </ContainerWithLoadingSpinner>
        </Modal>
    );
};

export default InviteUserModal;
