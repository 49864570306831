import { useMutation, UseMutationResult } from "react-query";
import Utilities from "shared/utilities";
import useHandleMutationError from "./useHandleMutationError";
import { FetchError } from "model/FetchError";

const useDeferredMutation = <T = string>(
    method: HttpMethod,
    onSuccess?: () => void,
    errorMsg?: string,
): UseMutationResult<
    T,
    FetchError,
    DeferredMutationVariables | void,
    unknown
> => {
    const mutation = useMutation<
        T,
        FetchError,
        DeferredMutationVariables | void,
        unknown
    >({
        mutationFn: (variables: DeferredMutationVariables) => {
            const url = variables.url;
            const body = variables.body;
            return Utilities.fetchBase(url, method, body);
        },
        onSuccess,
    });

    const errorHandlerOptions =
        method === "GET" ? { excludedErrorStatuses: [404] } : undefined;
    useHandleMutationError(mutation, errorMsg, errorHandlerOptions);

    return mutation;
};

type DeferredMutationVariables = {
    url: string;
    body?: unknown;
};

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export default useDeferredMutation;
