import * as React from "react";

import useDeferredMutation from "hooks/useDeferredMutation";
import { queryClient } from "index";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Text from "components/shared/Text";
import Button from "components/shared/Button";
import Table from "components/shared/Table";
import SettingsTableShownItemsControl from "components/settings/SettingsTableShownItemsCountControl";
import ConfigureAvailableIntegrationsModal from "components/settings/ConfigureAvailableIntegrationsModal";
import ConfirmDeleteModal from "components/settings/ConfirmDeleteModal";

import { ReactComponent as TrashIcon } from "@icons/trash.svg";

interface OrganizationAvailableIntegrationsProps {
    organizationId: number;
    tags?: string[];
    loadingTags: boolean;
}

const OrganizationAvailableIntegrations: React.FC<
    OrganizationAvailableIntegrationsProps
> = (props) => {
    const initialShownCount = 15;
    const [shownCount, setShownCount] = React.useState(initialShownCount);
    const [configureModalOpen, setConfigureModalOpen] = React.useState(false);
    const [openConfirmDeleteModalForTag, setOpeConfirmDeleteModalForTagId] =
        React.useState<string>();

    const apiRouteBase = `organizations/${props.organizationId}`;
    const invalidateQueries = () =>
        queryClient.invalidateQueries({ queryKey: [apiRouteBase] });

    const save = useDeferredMutation(
        "PUT",
        invalidateQueries,
        "Failed to save available integrations.",
    );

    React.useEffect(() => {
        if (save.isSuccess && configureModalOpen) {
            setConfigureModalOpen(false);
        }
    }, [save.isSuccess, configureModalOpen]);

    const mutationApiRoute = `${apiRouteBase}/viewableTemplateTags`;
    const handleSave = (tagNames: string[]) =>
        save.mutate({
            url: mutationApiRoute,
            body: {
                viewableTemplateTags: tagNames,
            },
        });

    const del = useDeferredMutation(
        "DELETE",
        invalidateQueries,
        "Failed to delete available integration.",
    );

    React.useEffect(() => {
        if (del.isSuccess) {
            setOpeConfirmDeleteModalForTagId(undefined);
        }
    }, [del.isSuccess]);

    const handleConfigureClick = () => {
        save.reset();
        setConfigureModalOpen(true);
    };

    const handleDeleteTagClick = (tag: string) => {
        del.reset();
        setOpeConfirmDeleteModalForTagId(tag);
    };

    const handleDelete = () =>
        del.mutate({
            url: mutationApiRoute,
            body: {
                viewableTemplateTags: [openConfirmDeleteModalForTag],
            },
        });

    const handleControlShownCountClick = (shownCount: number) =>
        setShownCount(shownCount);

    const tagsCount = props.tags?.length ?? 0;
    const shownTagsCount = shownCount < tagsCount ? shownCount : tagsCount;

    return (
        <ContainerWithLoadingSpinner loading={props.loadingTags}>
            <S.ShopConfigurationTitleContainer>
                <Text tag="h3">Integrations available to organization</Text>

                <Button
                    variant="bordered"
                    onClick={handleConfigureClick}
                    disabled={props.loadingTags}
                >
                    Configure
                </Button>
            </S.ShopConfigurationTitleContainer>

            {!props.loadingTags && tagsCount > 0 && (
                <>
                    <Table>
                        <table>
                            <S.SettingsTableHeadSROnly>
                                <tr>
                                    <th>Name</th>
                                    <th>Actions</th>
                                </tr>
                            </S.SettingsTableHeadSROnly>

                            <tbody>
                                {props.tags &&
                                    props.tags
                                        .slice(0, shownTagsCount)
                                        .map((tag, i) => (
                                            <S.OrganizationAvailableIntegrationsTableRow
                                                key={i}
                                            >
                                                <td>
                                                    <Text tag="span">
                                                        {tag}
                                                    </Text>
                                                </td>
                                                <td>
                                                    <S.OrganizationAvailableIntegrationsTableActionButtonsContainer>
                                                        <Button
                                                            title="Delete"
                                                            variant="bordered"
                                                            onClick={() =>
                                                                handleDeleteTagClick(
                                                                    tag,
                                                                )
                                                            }
                                                            disabled={false}
                                                        >
                                                            <TrashIcon />
                                                        </Button>
                                                    </S.OrganizationAvailableIntegrationsTableActionButtonsContainer>
                                                </td>
                                            </S.OrganizationAvailableIntegrationsTableRow>
                                        ))}
                            </tbody>
                        </table>
                    </Table>

                    <SettingsTableShownItemsControl
                        initialCount={initialShownCount}
                        shownCount={shownCount}
                        totalCount={tagsCount}
                        onShowButtonClick={handleControlShownCountClick}
                    />
                </>
            )}

            {!props.loadingTags && tagsCount === 0 && (
                <Text tag="p">No available integrations</Text>
            )}

            {configureModalOpen && (
                <ConfigureAvailableIntegrationsModal
                    title="Configure integrations available to organization"
                    onClose={() => setConfigureModalOpen(false)}
                    selectedTags={props.tags ?? []}
                    onSave={handleSave}
                    saving={save.isLoading}
                    saveError={!!save.error}
                />
            )}

            {openConfirmDeleteModalForTag !== undefined && (
                <ConfirmDeleteModal
                    title="You are about to delete this tag from the organization"
                    onClose={() => setOpeConfirmDeleteModalForTagId(undefined)}
                    onDelete={handleDelete}
                    deleting={del.isLoading}
                    deleteError={!!del.error}
                />
            )}
        </ContainerWithLoadingSpinner>
    );
};

export default OrganizationAvailableIntegrations;
