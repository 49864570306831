import styled from "styled-components";

import { SimplePageContainerCss } from "./SimplePage";

export const LandingPageTextImageContainer = styled.div`
    ${SimplePageContainerCss}

    padding-right: 0;
    display: grid;
    grid-template-columns: minmax(min-content, 505px) 1fr;
    gap: 2.375rem;
`;

export const LandingPageTextContainer = styled.div`
    --frends-text--padding: 0 0 2rem 0;
    --frends-button--margin: 0 0 1rem 0;
`;

export const LandingPageImageContainer = styled.div`
    align-self: end;
    justify-self: end;

    img {
        height: auto;
        width: auto;
        @media only screen and (min-width: 1024px) {
            max-width: 40rem;
            max-height: 40rem;
        }
        @media only screen and (min-width: 2560px) {
            max-width: 60rem;
            max-height: 60rem;
        }
    }
`;
