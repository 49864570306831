import { AppReducerOwnState } from "model/AppReducer";
import { LookAndFeelModel } from "model/LookAndFeelModel";
import { AppUserData } from "model/UserData";
import Cookie from "shared/Cookie";

export interface AppReducerState {
    state: AppReducerOwnState;
    dispatch: (payload: AppActions) => void;
}

type ActionMap<M extends { [index: string]: unknown }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
              type: Key;
          }
        : {
              type: Key;
              payload: M[Key];
          };
};

export enum Types {
    SET_LOOK_AND_FEEL = "SET_LOOK_AND_FEEL",
    SET_USER_DATA = "SET_USER_DATA",
}

export type ActionPayloads = {
    [Types.SET_LOOK_AND_FEEL]: {
        lookAndFeel: LookAndFeelModel;
    };
    [Types.SET_USER_DATA]: {
        userData?: AppUserData;
    };
};
export type AppActions =
    ActionMap<ActionPayloads>[keyof ActionMap<ActionPayloads>];

export const initialState: AppReducerOwnState = {
    lookAndFeel: {},
    userData: Cookie.getUserData(),
};

export function reducer(
    state: AppReducerOwnState,
    action: AppActions,
): AppReducerOwnState {
    switch (action.type) {
        case Types.SET_LOOK_AND_FEEL:
        case Types.SET_USER_DATA: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return initialState;
    }
}
