import styled from "styled-components";

export const ImageUploaderContainer = styled.div`
    margin: 0 0 1rem 0;
`;

export const ImageUploaderContentContainer = styled.div`
    display: grid;
    grid-template-rows: 1rem 5rem;
`;

export const ImageUploaderNewImageContainer = styled.div`
    display: grid;
    grid-template-columns: 10rem 16rem;
    align-items: center;

`;

export const ImageUploaderExistingImageContainer = styled.div`
    display: grid;
    grid-template-columns: 15rem 10rem 10rem;
    align-items: center;
    img {
        max-width: 6.25rem;
        max-height: 6.25rem;
    }
`;