import styled from "styled-components";

export const TemplateDetailsPageContainer = styled.div`
    margin-top: 1.5rem;

    --frends-stepper--margin: 1rem 0;
`;

export const TemplateDetailsContentContainer = styled.div`
    display: grid;
    gap: 1.313rem;
`;

export const TemplateDetailsDescriptionContainer = styled.div`
    display: grid;
`;

export const TemplateDetailsTagsContainer = styled.div`
    display: grid;
`;

export const TemplateDetailsActionsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: end;
    gap: 1rem;
`;