import * as React from "react";

import useDeferredMutation from "hooks/useDeferredMutation";
import { queryClient } from "index";
import useUserData from "hooks/useUserData";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Text from "components/shared/Text";
import Table from "components/shared/Table";
import { SmallDropdown } from "components/shared/Dropdown";
import Link from "components/shared/Link";
import Button from "components/shared/Button";
import ConfirmDeleteModal from "../ConfirmDeleteModal";

import { ReactComponent as TrashIcon } from "@icons/trash.svg";

import {
    OrganizationUserRole,
    User,
    UserRole,
    UserUpdateModel,
} from "model/User";

interface UserTableProps {
    organizationId?: number;
    users?: User[];
    loadingUsers: boolean;
    filter?: UsersTableFilter;
    canToggleUserStatus?: boolean;
}

const UserTable: React.FC<UserTableProps> = (props) => {
    const onlySingleOrganizationShown = props.organizationId !== undefined;

    const shownUsers = React.useMemo(() => {
        if (!props.users) {
            return [];
        }

        if (!props.filter) {
            return props.users;
        }

        return props.users.filter(
            (u) => u[props.filter!.key] === props.filter!.value,
        );
    }, [props.users, props.filter]);

    const invalidateQueries = () => {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        queryClient.invalidateQueries({
            queryKey: [`users/organization/${props.organizationId}`],
        });
    };

    const update = useDeferredMutation(
        "PUT",
        invalidateQueries,
        "Failed to update user.",
    );

    const del = useDeferredMutation(
        "DELETE",
        invalidateQueries,
        onlySingleOrganizationShown
            ? "Failed to remove user from the organization."
            : "Failed to delete user.",
    );
    const [
        confirmUserDeleteModalOpenForUserId,
        setConfirmUserDeleteModalOpenForUserId,
    ] = React.useState<number>();

    const handleUserUpdate = (id: number, model: UserUpdateModel) => {
        update.mutate({
            url: `users/${id}`,
            body: model,
        });
    };

    const handleUserRoleChange = (id: number, newRole: UserRole) =>
        handleUserUpdate(id, {
            userRole: {
                organizationId: props.organizationId!,
                roleName: newRole,
            },
        });

    const handleUserDisabledChange = (id: number, disabled: boolean) =>
        handleUserUpdate(id, {
            disabled,
        });

    const handleDeleteUserClick = (id: number) =>
        setConfirmUserDeleteModalOpenForUserId(id);

    const handleDeleteUser = (id: number) => {
        if (onlySingleOrganizationShown) {
            del.mutate({ url: `users/${id}/role/${props.organizationId!}` });
        } else {
            del.mutate({ url: `users/${id}` });
        }

        setConfirmUserDeleteModalOpenForUserId(undefined);
    };

    const shownUsersLength = shownUsers?.length ?? 0;

    return (
        <ContainerWithLoadingSpinner loading={update.isLoading}>
            {!props.loadingUsers && shownUsersLength > 0 && (
                <Table>
                    <table>
                        <S.SettingsTableHeadSROnly>
                            <tr>
                                <th>Name</th>
                                <th>Role</th>
                                <th>Login type</th>
                                <th>Disabled</th>
                                <th>Actions</th>
                            </tr>
                        </S.SettingsTableHeadSROnly>

                        <tbody>
                            {shownUsers.map((user, id) => (
                                <S.UsersTableRow key={id}>
                                    <td>
                                        <Text
                                            tag="span"
                                            color={
                                                user.disabled ? "grey" : "black"
                                            }
                                        >
                                            {user.name}
                                        </Text>
                                    </td>
                                    {onlySingleOrganizationShown ? (
                                        <SingleOrganizationRoleDataCell
                                            organizationId={
                                                props.organizationId!
                                            }
                                            user={user}
                                            onRoleChange={handleUserRoleChange}
                                        />
                                    ) : (
                                        <MultiOrganizationRoleDataCell
                                            roles={user.roles}
                                        />
                                    )}

                                    <td>
                                        <Text
                                            tag="span"
                                            color={
                                                user.disabled ? "grey" : "black"
                                            }
                                        >
                                            {user.loginType}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text tag="span" color="grey">
                                            {user.disabled ? "Disabled" : ""}
                                        </Text>
                                    </td>
                                    <td>
                                        <S.UsersTableActionButtonsContainer>
                                            {props.canToggleUserStatus && (
                                                <Link
                                                    type="button"
                                                    onClick={() =>
                                                        handleUserDisabledChange(
                                                            user.id,
                                                            !user.disabled,
                                                        )
                                                    }
                                                >
                                                    {user.disabled
                                                        ? "Active"
                                                        : "Disable"}
                                                </Link>
                                            )}

                                            <Button
                                                title="Delete"
                                                variant="bordered"
                                                onClick={() =>
                                                    handleDeleteUserClick(
                                                        user.id,
                                                    )
                                                }
                                            >
                                                <TrashIcon />
                                            </Button>
                                        </S.UsersTableActionButtonsContainer>
                                    </td>
                                </S.UsersTableRow>
                            ))}
                        </tbody>
                    </table>
                </Table>
            )}

            {!props.loadingUsers && shownUsersLength === 0 && (
                <Text tag="p">No users</Text>
            )}

            {confirmUserDeleteModalOpenForUserId !== undefined && (
                <ConfirmDeleteModal
                    open
                    title={
                        onlySingleOrganizationShown
                            ? "You are about to delete this user from the organization"
                            : "You are about to delete this user"
                    }
                    onClose={() =>
                        setConfirmUserDeleteModalOpenForUserId(undefined)
                    }
                    onDelete={() =>
                        handleDeleteUser(confirmUserDeleteModalOpenForUserId)
                    }
                    deleting={del.isLoading}
                    deleteError={!!del.error}
                />
            )}
        </ContainerWithLoadingSpinner>
    );
};

interface SingleOrganizationRoleDataCellProps {
    organizationId: number;
    user: User;
    onRoleChange: (userId: number, newRole: UserRole) => void;
}

const SingleOrganizationRoleDataCell: React.FC<
    SingleOrganizationRoleDataCellProps
> = (props) => {
    const { isTenantAdmin } = useUserData();

    const userRoleDropdownOptions = Object.values(UserRole)
        .filter((value) => !(!isTenantAdmin && value === UserRole.TenantAdmin))
        .map((value) => ({ label: value, value }));

    const getUserRoleInOrganization = (roles: OrganizationUserRole[]) =>
        roles.find((r) => r.organizationId === props.organizationId)
            ?.roleName ?? "";

    return (
        <td>
            <Text tag="span" color={props.user.disabled ? "grey" : "black"}>
                {getUserRoleInOrganization(props.user.roles)}
            </Text>

            {!props.user.disabled && (
                <SmallDropdown
                    options={userRoleDropdownOptions}
                    selected={getUserRoleInOrganization(props.user.roles)}
                    onChange={(newRole) =>
                        props.onRoleChange(props.user.id, newRole as UserRole)
                    }
                />
            )}
        </td>
    );
};

interface MultiOrganizationRoleDataCellProps {
    roles: OrganizationUserRole[];
}

const MultiOrganizationRoleDataCell: React.FC<
    MultiOrganizationRoleDataCellProps
> = (props) => {
    const commaSeparatedOrganizationRoles = props.roles
        .map((r) => `${r.roleName} (${r.organizationName})`)
        .join(", ");
    return <td>{commaSeparatedOrganizationRoles}</td>;
};

export default UserTable;

type UserObjectKey = keyof User;

export interface UsersTableFilter {
    key: UserObjectKey;
    value: User[UserObjectKey];
}
