import * as React from "react";

import * as S from "@styled";

import LoadingSpinner from "./LoadingSpinner";

interface ContainerWithLoadingSpinner {
    loading: boolean;
    style?: React.CSSProperties;
}

const ContainerWithLoadingSpinner: React.FC<
    React.PropsWithChildren<ContainerWithLoadingSpinner>
> = (props) => {
    const [showSpinner, setShowSpinner] = React.useState(false);

    React.useEffect(() => {
        let showSpinnerTimeout: NodeJS.Timeout | undefined;
        if (props.loading) {
            showSpinnerTimeout = setTimeout(() => {
                setShowSpinner(true);
            }, 200);
        } else {
            setShowSpinner(false);
        }

        return () => clearTimeout(showSpinnerTimeout);
    }, [props.loading]);

    return (
        <S.ContainerWithLoadingSpinner style={props.style}>
            {props.children}

            {showSpinner && <LoadingSpinner />}
        </S.ContainerWithLoadingSpinner>
    );
};

export default ContainerWithLoadingSpinner;
