import { AppUserData, CookieUserData } from "model/UserData";
import { UserLoginType } from "model/User";

class Cookie {
    static getCookieValue = (name: string) => {
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie
                .split("=")
                .map((c) => c.trim());
            if (cookieName === name) {
                return decodeURIComponent(cookieValue);
            }
        }
    };

    static getUserData = () => {
        const userDataAsString = this.getCookieValue("UserData");
        if (!userDataAsString) {
            return;
        }

        const cookieUserData = JSON.parse(userDataAsString) as CookieUserData;

        const selectedOrganization = {
            id: cookieUserData.CurrentOrganizationId,
            name: cookieUserData.CurrentOrganizationName,
        };

        const availableOrganizations = [
            selectedOrganization,
            ...cookieUserData.OtherOrganizations.map((org) => ({
                id: org.Id,
                name: org.Name,
            })),
        ];
        availableOrganizations.sort((a, b) => a.name.localeCompare(b.name));

        return {
            userName: cookieUserData.UserName,
            userRoleInSelectedOrganization: cookieUserData.UserRoleName,
            selectedOrganization,
            availableOrganizations,
            userLoginType: cookieUserData.UserLoginType.toLowerCase().includes(
                "local",
            )
                ? UserLoginType.Local
                : UserLoginType.SSO,
        } as AppUserData;
    };
}

export default Cookie;
