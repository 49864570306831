import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const CardsContainer = styled.div<CardsContainerProps>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.$gridMode ? `repeat(3, 1fr)` : `repeat(1, 1fr)`};
    gap: 1rem;
`;

const CardStyleBase = css`
    display: grid;
    align-content: space-between;
    padding: 1rem;
    border: 1px solid ${(props) => props.theme.grey2};
    background-color: ${(props) => props.theme.white};
    border-radius: 1rem;
    overflow: hidden;

    --frends-text--text-overflow: ellipsis;
    --frends-text--padding: 0 0 0.5rem 0;
    &:hover {
        border: 1.5px solid ${(props) => props.theme.grey1};
    }
`;

export const Card = styled.div`
    ${CardStyleBase};
`;

export const CardLink = styled(Link)`
    ${CardStyleBase};
    text-decoration: none;
`;

export const CardTagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 3rem;
    gap: 0.5rem;
    --frends-tag--margin: 0.5rem 0;
`;

interface CardsContainerProps {
    $gridMode?: boolean;
}
