import * as React from "react";

import "frends-ui-components/Table";

type TableProps = React.PropsWithChildren;

const Table: React.FC<TableProps> = (props) => {
    return (
        <frends-table>
            {props.children}
        </frends-table>
    );
};

export default Table;