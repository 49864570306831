import styled from "styled-components";

import { SimplePageFormCss } from "./SimplePage";

export const OrganizationCreationRequestContainer = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: start;
`;

export const OrganizationCreationRequestForm = styled.form`
    ${SimplePageFormCss}
`;

export const OrganizationCreationRequestContent = styled.div`
    ${SimplePageFormCss}
`;
