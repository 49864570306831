import { useMutation, UseMutationResult } from "react-query";
import Utilities from "shared/utilities";
import useHandleMutationError from "./useHandleMutationError";
import { FetchError } from "model/FetchError";

const usePatchMutation = <T = string>(
    url: string,
    body: Record<string, unknown>,
    onSuccess?: () => void,
    errorMsg?: string,
): UseMutationResult<
    T,
    FetchError,
    Record<string, unknown> | void,
    unknown
> => {
    const mutation = useMutation<
        T,
        FetchError,
        Record<string, unknown> | void,
        unknown
    >({
        mutationFn: () => Utilities.fetchBase(url, "PATCH", body),
        onSuccess,
    });

    useHandleMutationError(mutation, errorMsg);

    return mutation;
};

export default usePatchMutation;
