import * as React from "react";
import * as S from "@styled";
import Input from "components/shared/Input";
import Text from "components/shared/Text";
import Toggle from "components/shared/Toggle";
import { SaveButton } from "components/shared/Button";
import useQuery from "hooks/useQuery";
import {
    SsoSettings,
    SsoSettingsConfiguration,
} from "model/SsoSettingsConfiguration";
import usePutMutation from "hooks/usePutMutation";
import { queryClient } from "index";

const ssoConfigurationApiBaseRoute = "sso";
const SsoConfiguration: React.FC = () => {
    const { isSuccess, data } = useQuery<SsoSettingsConfiguration>(
        ssoConfigurationApiBaseRoute,
        undefined,
        undefined,
        "Failed to fetch SSO configuration.",
    );

    const [ssoConfiguration, setSsoConfiguration] =
        React.useState<SsoSettingsConfiguration>({
            ssoEnabled: false,
            ssoSettings: {
                adTenant: "",
                appId: "",
                authority: "",
                redirectUri: "",
                postLogoutRedirectUri: "",
                nameClaimType: "",
            },
        });

    const [showInputErrors, setShowInputErrors] = React.useState(false);

    const updateSsoConfiguration = usePutMutation(
        ssoConfigurationApiBaseRoute,
        ssoConfiguration,
        () => {
            queryClient.invalidateQueries({
                queryKey: ssoConfigurationApiBaseRoute,
            });
        },
        "Failed to save SSO configuration.",
    );

    React.useEffect(() => {
        if (isSuccess && data) {
            setSsoConfiguration(data);
        }
    }, [isSuccess, data]);

    const toggleSso = () =>
        setSsoConfiguration({
            ...ssoConfiguration,
            ssoEnabled: !ssoConfiguration.ssoEnabled,
        });

    const updateSsoSettingConfiguration = (
        key: keyof SsoSettings,
        value: string,
    ) => {
        const existingSsoSettings: SsoSettings =
            ssoConfiguration.ssoSettings ?? {
                adTenant: "",
                appId: "",
                authority: "",
                redirectUri: "",
                postLogoutRedirectUri: "",
                nameClaimType: "",
            };
        setSsoConfiguration({
            ...ssoConfiguration,
            ssoSettings: { ...existingSsoSettings, [key]: value },
        });

        if (validateSsoConfiguration()) {
            setShowInputErrors(false);
        }
    };

    const adTenantHasValue = (): boolean => {
        return !!ssoConfiguration.ssoSettings?.adTenant;
    };

    const appIdHasValue = (): boolean => {
        return !!ssoConfiguration.ssoSettings?.appId;
    };

    const authorityHasValue = (): boolean => {
        return !!ssoConfiguration.ssoSettings?.authority;
    };

    const validateSsoConfiguration = (): boolean => {
        return adTenantHasValue() && appIdHasValue() && authorityHasValue();
    };

    const handleSubmit = () => {
        if (validateSsoConfiguration()) {
            updateSsoConfiguration.mutate();
        } else {
            setShowInputErrors(true);
        }
    };

    return (
        <div>
            <Text tag="h2">SSO Configuration</Text>
            <S.SsoConfigurationForm>
                <Toggle
                    label="Custom SSO"
                    checked={ssoConfiguration.ssoEnabled}
                    onChange={toggleSso}
                />
                <Input
                    label="Active directory tenant"
                    value={ssoConfiguration.ssoSettings?.adTenant}
                    onChange={(newAdTenant: string) =>
                        updateSsoSettingConfiguration("adTenant", newAdTenant)
                    }
                    type="text"
                    invalid={showInputErrors && !adTenantHasValue()}
                    errortext="Required field"
                />
                <Input
                    label="Application id"
                    value={ssoConfiguration.ssoSettings?.appId}
                    onChange={(newAppId: string) =>
                        updateSsoSettingConfiguration("appId", newAppId)
                    }
                    type="text"
                    disabled={ssoConfiguration.ssoEnabled} // Deactivate input if SSO has already been configured
                    invalid={showInputErrors && !appIdHasValue()}
                    errortext="Required field"
                />
                <Input
                    label="Authority"
                    value={ssoConfiguration.ssoSettings?.authority}
                    onChange={(newAuthority: string) =>
                        updateSsoSettingConfiguration("authority", newAuthority)
                    }
                    type="text"
                    disabled={ssoConfiguration.ssoEnabled} // Deactivate input if SSO has already been configured
                    invalid={showInputErrors && !authorityHasValue()}
                    errortext="Required field"
                />
                <Input
                    label="Redirect URI"
                    value={ssoConfiguration.ssoSettings?.redirectUri}
                    onChange={(newRedirectURI: string) =>
                        updateSsoSettingConfiguration(
                            "redirectUri",
                            newRedirectURI,
                        )
                    }
                    type="text"
                    disabled={ssoConfiguration.ssoEnabled} // Deactivate input if SSO has already been configured
                />
                <Input
                    label="Post logout redirect URI"
                    value={ssoConfiguration.ssoSettings?.postLogoutRedirectUri}
                    onChange={(newPostLogoutRedirectUri: string) =>
                        updateSsoSettingConfiguration(
                            "postLogoutRedirectUri",
                            newPostLogoutRedirectUri,
                        )
                    }
                    type="text"
                />
                <Input
                    label="Name claim type"
                    value={ssoConfiguration.ssoSettings?.nameClaimType}
                    onChange={(newNameClaimType: string) =>
                        updateSsoSettingConfiguration(
                            "nameClaimType",
                            newNameClaimType,
                        )
                    }
                    type="text"
                />
                <S.SettingsSaveButtonContainer>
                    <SaveButton
                        onClick={handleSubmit}
                        type="button"
                        loading={updateSsoConfiguration.isLoading}
                        success={updateSsoConfiguration.isSuccess}
                        error={updateSsoConfiguration.isError}
                    >
                        Save
                    </SaveButton>
                </S.SettingsSaveButtonContainer>
            </S.SsoConfigurationForm>
        </div>
    );
};

export default SsoConfiguration;
