import styled from "styled-components";

export const LeftIconButtonContainer = styled.div`
    --frends-button--padding: 0 1.5625rem 0 0.938rem;
`;

export const RightIconButtonContainer = styled.div`
    --frends-button--padding: 0 0.938rem 0 1.5625rem;
`;

export const IconOnlyButtonContainer = styled.div`
    --frends-button--padding: 0 0.938rem 0 0.938rem;
`;

export const SaveButtonContainer = styled.div`
    --loading-spinner: ${props => props.theme.grey1}; //#6C6C6C
    --frends-button--svg--fill: ${props => props.theme.grey1};;
    --frends-button--hover--svg--fill: ${props => props.theme.grey1};
`;