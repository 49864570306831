import * as React from "react";

import useQuery from "hooks/useQuery";
import useDeferredMutation from "hooks/useDeferredMutation";
import { queryClient } from "index";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Text from "components/shared/Text";
import Button from "components/shared/Button";
import Table from "components/shared/Table";
import SettingsTableShownItemsControl from "../SettingsTableShownItemsCountControl";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import ConfigureAvailableIntegrationsModal from "../ConfigureAvailableIntegrationsModal";

import { ReactComponent as TrashIcon } from "@icons/trash.svg";

import { AvailableIntegration } from "model/ShopConfiguration";

import Time from "shared/Time";

import { SHOPCONFIG_INTEGRATIONS_API_BASE_PATH } from "AppRoutes";

const AvailableIntegrations: React.FC = () => {
    const invalidateQueries = () =>
        queryClient.invalidateQueries({
            queryKey: [SHOPCONFIG_INTEGRATIONS_API_BASE_PATH],
        });
    const askDeleteConfirmTimestampKey =
        "frends_bap_available_integrations_confirm_delete_timestamp";
    const initialShownCount = 15;

    const integrationsFetch = useQuery<AvailableIntegration[]>(
        SHOPCONFIG_INTEGRATIONS_API_BASE_PATH,
        undefined,
        undefined,
        "Failed to fetch available integrations.",
    );
    const integrations = integrationsFetch.data;

    const [shownCount, setShownCount] = React.useState(initialShownCount);

    const shownIntegrations = React.useMemo<AvailableIntegration[]>(() => {
        if (!integrations) {
            return [];
        }

        const allShown = shownCount >= integrations.length;
        return allShown
            ? integrations
            : integrations.slice(0, initialShownCount);
    }, [shownCount, integrations]);

    const handleControlShownCountClick = (shownCount: number) =>
        setShownCount(shownCount);

    const [configureModalOpen, setConfigureModalOpen] = React.useState(false);

    const save = useDeferredMutation(
        "PUT",
        invalidateQueries,
        "Failed to save available integrations",
    );

    React.useEffect(() => {
        if (save.isSuccess && configureModalOpen) {
            setConfigureModalOpen(false);
        }
    }, [save.isSuccess, configureModalOpen]);

    const handleConfigureClick = () => {
        save.reset();
        setConfigureModalOpen(true);
    };

    const handleConfigureModalClose = () => setConfigureModalOpen(false);

    const handleSave = (templateTags: string[]) =>
        save.mutate({
            url: SHOPCONFIG_INTEGRATIONS_API_BASE_PATH,
            body: templateTags,
        });

    const [openConfirmDeleteModalForTag, setOpenConfirmDeleteModalForTag] =
        React.useState<string>();

    const handleConfirmDeleteModalClose = () =>
        setOpenConfirmDeleteModalForTag(undefined);

    const del = useDeferredMutation(
        "DELETE",
        invalidateQueries,
        "Failed to delete available integration.",
    );

    React.useEffect(() => {
        if (del.isSuccess && openConfirmDeleteModalForTag !== undefined) {
            handleConfirmDeleteModalClose();
        }
    }, [del, openConfirmDeleteModalForTag]);

    const handleDeleteTagClick = (tag: string) => {
        del.reset();
        const askDeleteConfirmTimestamp = localStorage.getItem(
            askDeleteConfirmTimestampKey,
        );
        if (
            askDeleteConfirmTimestamp === null ||
            !Time.isWithinTenMinutes(askDeleteConfirmTimestamp)
        ) {
            setOpenConfirmDeleteModalForTag(tag);
        } else {
            handleDeleteTag(tag);
        }
    };

    const handleDeleteTag = (templateTag: string) =>
        del.mutate({
            url: SHOPCONFIG_INTEGRATIONS_API_BASE_PATH,
            body: templateTag,
        });

    const isLoading =
        integrationsFetch.isFetching ||
        (openConfirmDeleteModalForTag === undefined && del.isLoading);

    return (
        <ContainerWithLoadingSpinner loading={isLoading}>
            <S.ShopConfigurationTitleContainer>
                <Text tag="h2">
                    Integrations available to all organizations
                </Text>

                <Button
                    variant="bordered"
                    onClick={handleConfigureClick}
                    disabled={isLoading}
                >
                    Configure
                </Button>
            </S.ShopConfigurationTitleContainer>

            {integrations && integrations.length > 0 && (
                <>
                    <Table>
                        <table>
                            <S.SettingsTableHeadSROnly>
                                <tr>
                                    <th>Name</th>
                                    <th>Actions</th>
                                    <th>Integration count</th>
                                </tr>
                            </S.SettingsTableHeadSROnly>

                            <tbody>
                                {shownIntegrations.map((integ, i) => (
                                    <S.ShopConfigurationTableRow key={i}>
                                        <td>
                                            <Text tag="span">
                                                {integ.templateTagName}
                                            </Text>
                                        </td>
                                        <td>
                                            <S.ShopConfigurationTableActionButtonsContainer>
                                                <Button
                                                    title="Delete"
                                                    variant="bordered"
                                                    onClick={() =>
                                                        handleDeleteTagClick(
                                                            integ.templateTagName,
                                                        )
                                                    }
                                                    disabled={isLoading}
                                                >
                                                    <TrashIcon />
                                                </Button>
                                            </S.ShopConfigurationTableActionButtonsContainer>
                                        </td>
                                        <td>
                                            <Text tag="span">
                                                {`${integ.integrationCount} integrations`}
                                            </Text>
                                        </td>
                                    </S.ShopConfigurationTableRow>
                                ))}
                            </tbody>
                        </table>
                    </Table>

                    <SettingsTableShownItemsControl
                        initialCount={initialShownCount}
                        shownCount={shownIntegrations.length}
                        totalCount={integrations.length}
                        onShowButtonClick={handleControlShownCountClick}
                    />
                </>
            )}

            {integrations && integrations.length === 0 && (
                <Text tag="p">No available integrations</Text>
            )}

            {configureModalOpen && (
                <ConfigureAvailableIntegrationsModal
                    title="Configure integrations available to all organizations"
                    onClose={handleConfigureModalClose}
                    selectedTags={
                        integrations?.map((i) => i.templateTagName) ?? []
                    }
                    onSave={handleSave}
                    saving={save.isLoading}
                    saveError={!!save.error}
                />
            )}

            {openConfirmDeleteModalForTag && (
                <ConfirmDeleteModal
                    title={`You are about to delete the tag "${openConfirmDeleteModalForTag}" from available integration`}
                    disableConfirmationKey={askDeleteConfirmTimestampKey}
                    onDelete={() =>
                        handleDeleteTag(openConfirmDeleteModalForTag)
                    }
                    deleting={del.isLoading}
                    deleteError={!!del.error}
                    onClose={handleConfirmDeleteModalClose}
                />
            )}
        </ContainerWithLoadingSpinner>
    );
};

export default AvailableIntegrations;
