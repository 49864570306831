const sharedColors = {
    grey1: "#6C6C6C",
    grey2: "#CBCBCB",
    grey3: "#E5E5E5",
    grey4: "#EDF5F4",
    grey5: "#F6FAF9",
    black: "#000000",
    white: "#FFFFFF",
    uiGreen: "#049372",
    warningDark: "#B20909",
    orangePrimary: "#FF7923",
}

export const defaultTheme = {
    ...sharedColors,
    frendsBlueDark: "#034F6D",
    frendsPinkBackground: "#FEF0F8"
}

// const defaultDarkTheme = {
//     ...defaultTheme
// }