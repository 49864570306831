import { UseMutationResult, useMutation } from "react-query";
import Utilities from "shared/utilities";
import useHandleMutationError from "./useHandleMutationError";
import { FetchError } from "model/FetchError";

const usePostMutation = <T = string, BodyType = Record<string, unknown>>(
    url: string,
    initialBody: BodyType,
    onSuccess?: () => void,
    errorMsg?: string,
): UseMutationResult<
    T,
    FetchError,
    Record<string, unknown> | void,
    unknown
> => {
    const mutation = useMutation<
        T,
        FetchError,
        Record<string, unknown> | void,
        unknown
    >({
        mutationFn: (body: Record<string, unknown>) =>
            Utilities.fetchBase(url, "POST", body || initialBody),
        onSuccess,
    });

    useHandleMutationError(mutation, errorMsg);

    return mutation;
};

export const usePostFileMutation = async (
    url: string,
    file?: File,
    onSuccess?: () => void,
    errorMsg?: string,
): Promise<UseMutationResult<Response, FetchError, void, unknown>> => {
    const mutation = useMutation<Response, FetchError, void, unknown>({
        mutationFn: async () => {
            const formData = new FormData();
            if (file) {
                formData.append("file", file);
            }
            const response = await fetch(`/api/${url}`, {
                credentials: "include",
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                const content = await response.text();
                throw {
                    content,
                    status: response.status,
                } as FetchError;
            }
            return response;
        },
        onSuccess,
    });

    useHandleMutationError(mutation, errorMsg);

    return mutation;
};

export default usePostMutation;
