import * as React from "react";
import * as S from "@styled";

import Dropdown from "components/shared/Dropdown";
import { Option } from "frends-ui-components/dist/types/dropdown/Dropdown";
import Input from "components/shared/Input";
import { ReactComponent as List } from "@icons/list.svg";
import { ReactComponent as Grid } from "@icons/grid.svg";
import useQuery from "hooks/useQuery";
import { CategoryViewModel } from "model/CategoryViewModel";
import { CATEGORIES_API_BASE_PATH } from "AppRoutes";

const IntegrationFilters: React.FC<IntegrationFiltersProps> = (props) => {
    const tagsOptions = props.tags?.map((tag) => ({ label: tag, value: tag }));
    const [categoryOptions, setCategoryOptions] = React.useState<Option[]>([]);

    const queryCategories = useQuery<CategoryViewModel[]>(
        CATEGORIES_API_BASE_PATH,
        undefined,
        undefined,
        "Failed to fetch categories.",
    );

    const options = React.useMemo(() => {
        return [
            { name: "Category", options: categoryOptions },
            { name: "Tags", options: tagsOptions },
        ];
    }, [tagsOptions, categoryOptions]);

    React.useEffect(() => {
        if (queryCategories.isFetched && queryCategories.data) {
            setCategoryOptions(
                queryCategories.data.map((category) => ({
                    label: category.name,
                    value: `cat-${category.id}`,
                })),
            );
        }
    }, [queryCategories.data, queryCategories.isFetched]);

    const handleChangeTagFilter = (nextValue: string[]) => {
        // Extract tags from selected categories
        const selectedCategoryIds = nextValue
            .filter((value) => value.startsWith("cat-"))
            .map((value) => parseInt(value.replace("cat-", "")));
        const selectedCategories =
            queryCategories.data?.filter((category) =>
                selectedCategoryIds.includes(category.id),
            ) ?? [];
        const categoryTags = selectedCategories
            .map((category) => category.tags.map((tag) => tag))
            .flat();
        nextValue = nextValue.filter((value) => !value.startsWith("cat-"));
        nextValue = nextValue.concat(categoryTags);

        props.onChangeTagFilter(nextValue);
    };

    return (
        <S.IntegrationCatalogFilterContainer>
            <Dropdown
                placeholder="Filter integrations by categories or tags"
                label="Show"
                options={options}
                selected={props.tagFilter}
                onChange={handleChangeTagFilter}
                multivalue={true}
            />
            <S.IntegrationCatalogSearchContainer>
                <Input
                    type="search"
                    placeholder="Search..."
                    value={props.searchFilter}
                    onChange={props.onChangeSearchFilter}
                />
            </S.IntegrationCatalogSearchContainer>
            <S.IntegrationCatalogViewSwitchContainer>
                <S.IntegrationCatalogSelectedView
                    $selected={props.isListView}
                    onClick={() => props.onChangeLayout(true)}
                >
                    <List />
                </S.IntegrationCatalogSelectedView>
                <S.IntegrationCatalogSelectedView
                    $selected={!props.isListView}
                    onClick={() => props.onChangeLayout(false)}
                >
                    <Grid />
                </S.IntegrationCatalogSelectedView>
            </S.IntegrationCatalogViewSwitchContainer>
        </S.IntegrationCatalogFilterContainer>
    );
};

interface IntegrationFiltersProps {
    searchFilter: string;
    tagFilter?: string[];
    tags: string[];
    onChangeTagFilter: (nextValue: string[]) => void;
    onChangeSearchFilter: (nextValue: string) => void;
    onChangeLayout: (isListView: boolean) => void;
    isListView: boolean;
}

export default IntegrationFilters;
