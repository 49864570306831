import styled from "styled-components";

export const LoadingSpinnerContainer = styled.div`
    svg {
        width: inherit;
        height: inherit;

        > path:nth-child(1) {
            fill: var(--loading-spinner, var(--accent-color));
            opacity: 0.3;
        }

        > path:nth-child(2) {
            fill: var(--loading-spinner, var(--accent-color));
            transform-origin: center;
            animation: spin 1.2s linear infinite;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;