import * as React from "react";
import "frends-ui-components/AccordionGroup";

type AccordionGroupProps = React.PropsWithChildren;


const AccordionGroup: React.FC<AccordionGroupProps> = (props) => {
    return (
        <frends-accordion-group>
            {props.children}
        </frends-accordion-group>
    );
}

export default AccordionGroup;