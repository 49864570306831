import * as React from "react";

import "frends-ui-components/Accordion";

type AccordionProps = React.PropsWithChildren;

const Accordion: React.FC<AccordionProps> = (props) => {
    return (
        <frends-accordion>
            {props.children}
        </frends-accordion>
    );
}

export default Accordion;