import * as React from "react";

import useQuery from "hooks/useQuery";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Button, { SaveButton } from "components/shared/Button";
import Modal, { ModalProps } from "components/shared/Modal";
import TagsSelector from "./TagsSelectors";

import { TEMPLATES_TAGS_API_PATH } from "AppRoutes";

interface ConfigureAvailableIntegrationsModalProps extends ModalProps {
    selectedTags: string[];
    onSave: (tags: string[]) => void;
    saving: boolean;
    saveError: boolean;
}

const ConfigureAvailableIntegrationsModal: React.FC<
    ConfigureAvailableIntegrationsModalProps
> = (props) => {
    const [selectedTags, setSelectedTags] = React.useState(props.selectedTags);
    const [saveDisabled, setSaveDisabled] = React.useState(true);

    const templateTagsFetch = useQuery<string[]>(
        TEMPLATES_TAGS_API_PATH,
        undefined,
        undefined,
        "Failed to fetch template tags",
    );
    const templateTags = templateTagsFetch.data;

    const handleSelectedTagNamesChange = (tagNames: string[]) => {
        setSelectedTags(tagNames);
        setSaveDisabled(false);
    };

    const handleCancelClick = () => props.onClose?.();

    const handleSaveClick = () => props.onSave(selectedTags);

    return (
        <Modal title={props.title} onClose={props.onClose} open>
            <ContainerWithLoadingSpinner loading={templateTagsFetch.isLoading}>
                <S.ConfigureModalContainer>
                    {templateTags && (
                        <TagsSelector
                            tags={templateTags}
                            selectedTagNames={selectedTags}
                            onSelectedTagsChange={handleSelectedTagNamesChange}
                        />
                    )}

                    <S.ConfigureModalActionButtonContainer>
                        <Button variant="bordered" onClick={handleCancelClick}>
                            Cancel
                        </Button>

                        <SaveButton
                            onClick={handleSaveClick}
                            disabled={
                                templateTagsFetch.isFetching || saveDisabled
                            }
                            loading={props.saving}
                            error={props.saveError}
                        >
                            Save
                        </SaveButton>
                    </S.ConfigureModalActionButtonContainer>
                </S.ConfigureModalContainer>
            </ContainerWithLoadingSpinner>
        </Modal>
    );
};

export default ConfigureAvailableIntegrationsModal;
