import * as React from "react";

import * as S from "@styled";

import type FrendsButtonProps from "frends-ui-components/dist/types/button/Button";
import "frends-ui-components/Button";
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";

import { ReactComponent as PlusIcon } from "@icons/plus.svg";
import { ReactComponent as ChevronDownIcon } from "@icons/chevron_down.svg";
import { ReactComponent as SuccessIcon } from "@icons/success_circle.svg";
import { ReactComponent as ErrorIcon } from "@icons/error_circle.svg";
import { ReactComponent as CloseIcon } from "@icons/close_big.svg";

type ButtonProps = React.PropsWithChildren<{
    title?: string;
    onClick?: (
        event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    ) => void;
}> &
    Partial<
        Pick<
            FrendsButtonProps,
            "variant" | "disabled" | "type" | "href" | "name" | "value"
        >
    >;

const Button: React.FC<ButtonProps> = (props) => {
    const ref = React.useRef<FrendsButtonProps>(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (ref.current && props.disabled !== undefined) {
            ref.current.disabled = props.disabled;
        }
    }, [props.disabled]);

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    ) => {
        if (props.onClick) {
            props.onClick(event);
        } else if (props.type === "link" && props.href) {
            event.preventDefault();
            navigate(props.href);
        }
    };

    return (
        <frends-button
            ref={ref}
            title={props.title}
            variant={props.variant}
            type={props.type}
            href={props.href}
            name={props.name}
            value={props.value}
            onClick={handleClick}
        >
            {props.children}
        </frends-button>
    );
};

export const AddButton: React.FC<ButtonProps> = (props) => (
    <S.LeftIconButtonContainer>
        <Button variant="bordered" {...props}>
            <PlusIcon />
            {props.children}
        </Button>
    </S.LeftIconButtonContainer>
);

export const DropdownButton: React.FC<ButtonProps> = (props) => (
    <S.RightIconButtonContainer>
        <Button variant="bordered" {...props}>
            {props.children}
            <ChevronDownIcon />
        </Button>
    </S.RightIconButtonContainer>
);

export const CloseButton: React.FC<ButtonProps> = (props) => (
    <S.IconOnlyButtonContainer>
        <Button variant="bordered" {...props}>
            <CloseIcon />
        </Button>
    </S.IconOnlyButtonContainer>
);

interface SaveButtonPros extends ButtonProps {
    loading?: boolean;
    success?: boolean;
    error?: boolean;
}

export const SaveButton: React.FC<SaveButtonPros> = ({
    children,
    disabled,
    loading,
    success,
    error,
    ...rest
}) => {
    const [_success, set_Success] = React.useState(success);

    React.useEffect(() => {
        set_Success(success);

        const resetSuccessTimeout = setTimeout(() => {
            set_Success(false);
        }, 2000);

        return () => clearTimeout(resetSuccessTimeout);
    }, [success]);

    return (
        <S.SaveButtonContainer>
            <Button disabled={disabled || loading} {...rest}>
                {children}

                {loading && <LoadingSpinner size="1.25rem" />}

                {!loading && _success && <SuccessIcon />}

                {!loading && error && <ErrorIcon />}
            </Button>
        </S.SaveButtonContainer>
    );
};

export default Button;
