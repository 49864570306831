import * as React from "react"
import styled from "styled-components";

export const DescriptionList = styled.dl<IDescriptionListProps>`
    margin: 0;

    dt {
        font-size: 1rem;
        font-family: 'Gilroy-Medium';
        margin-bottom: 0.5rem;
    }

    dd {
        margin: 0;
        padding: 0.5rem 1rem;
    }

    dd div {
        min-width: 0;
        word-wrap: break-word;
    }

    pre {
        white-space: break-spaces;
    }

    .two-column-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    ${props => props.$itemsPerRow && `
        display: grid;
        grid-template-columns: repeat(${props.$itemsPerRow}, 1fr);
    `}
`;

interface IDescriptionListProps {
    $itemsPerRow?: number;
}

interface IDescriptionListItemProps {
    dt: string;
    dd?: string | number | null;
    preformat?: boolean;
}

export const DescriptionListItem: React.FC<IDescriptionListItemProps> = ({
    dt,
    dd,
    preformat
}) => (
    <>
        {dd !== undefined && dd !== null && (
            <div>
                <dt>
                    {dt}
                </dt>
                <dd>
                    {preformat === true ? (
                        <pre>
                            {dd}
                        </pre>
                    ) : (
                        <>
                            {dd}
                        </>
                    )}
                </dd>
            </div>
        )}
    </>
);

export default DescriptionList;