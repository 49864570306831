import styled from "styled-components";

export const TemplateChangeLogActionsContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
`;

export const TemplateChangeLogTable = styled.table`
    margin: 1rem 0 1.313rem 0;
    tbody tr {
        td:nth-child(1) {
            width: 9.563rem;
        }
        td:nth-child(2) {
            width: 13rem;
        }
        td:nth-child(3) {
            width: auto;
        }
    }
`;