import * as React from "react";
import OrganizationList from "components/settings/organization/OrganizationList";
import OrganizationRequests from "components/settings/organization/OrganizationRequests";

const Organizations: React.FC = () => {
    return (
        <div>
            <OrganizationRequests />
            <OrganizationList />
        </div>
    )
}


export default Organizations;