import * as React from "react";

import type FrendsTextProps from "frends-ui-components/dist/types/text/Text";
import "frends-ui-components/Text";

type TextProps = React.PropsWithChildren<{
    slot?: string;
}> & Partial<Pick<FrendsTextProps, 'tag' | 'appearance' | 'size' | 'color'>>;

const Text: React.FC<TextProps> = (props) => {
    const ref = React.useRef<FrendsTextProps>(null);

    React.useEffect(() => {
        if (ref.current && props.children != undefined) {
            ref.current.text = props.children.toString();
        }
    }, [props.children]);

    React.useEffect(() => {
        if (ref.current && props.color !== undefined) {
            ref.current.color = props.color;
        }
    }, [props.color]);

    return (
        <frends-text
            ref={ref}
            tag={props.tag}
            appearance={props.appearance}
            size={props.size}
            color={props.color}
            slot={props.slot}
        />
    );
};

export default Text;