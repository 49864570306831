import * as React from "react";

import * as S from "@styled";

import Text from "components/shared/Text";
import Button from "components/shared/Button";

interface PasswordActionSuccessProps {
    title: string;
    body?: string;
    link: {
        text: string;
        href: string;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    };
}

const PasswordActionSuccess: React.FC<PasswordActionSuccessProps> = (props) => (
    <S.PasswordActionSuccess>
        <Text tag="h1">{props.title}</Text>

        {props.body && <Text tag="p">{props.body}</Text>}

        <Button type="link" href={props.link.href} onClick={props.link.onClick}>
            {props.link.text}
        </Button>
    </S.PasswordActionSuccess>
);

export default PasswordActionSuccess;
