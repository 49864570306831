import * as React from "react";

import useUserData from "hooks/useUserData";
import useQuery from "hooks/useQuery";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Text from "components/shared/Text";
import Checkbox from "components/shared/Checkbox";
import UserTable, {
    UsersTableFilter,
} from "components/settings/users/UsersTable";
import { AddButton } from "components/shared/Button";
import InviteUserModal from "components/settings/users/InviteUserModal";

import { User, UserLoginType } from "model/User";

const Users: React.FC = () => {
    const { selectedOrganization } = useUserData();
    const [usersFilter, setUsersFilter] = React.useState<UsersTableFilter>();
    const [inviteNewUserModalOpen, setInviteNewUserModalOpen] =
        React.useState(false);

    const usersFetch = useQuery<User[]>(
        `users`,
        undefined,
        undefined,
        "Failed to fetch users.",
    );

    const setLoginTypeFilter = (type: UserLoginType, enabled: boolean) => {
        const nextFilter: UsersTableFilter | undefined = !enabled
            ? { key: "loginType", value: type }
            : undefined;
        setUsersFilter(nextFilter);
    };

    const showOnlySso = usersFilter?.value === UserLoginType.SSO;
    const toggleShowOnlySso = () =>
        setLoginTypeFilter(UserLoginType.SSO, showOnlySso);

    const showOnlyLocal = usersFilter?.value === UserLoginType.Local;
    const toggleShowOnlyLocal = () =>
        setLoginTypeFilter(UserLoginType.Local, showOnlyLocal);

    const handleCreateNewUserClick = () => setInviteNewUserModalOpen(true);

    const handleInviteNewUserModalClose = () =>
        setInviteNewUserModalOpen(false);

    return (
        <ContainerWithLoadingSpinner loading={usersFetch.isLoading}>
            <S.UsersTitleContainer>
                <Text tag="h2">Users</Text>

                <fieldset>
                    <Text tag="legend">Show only</Text>

                    <Checkbox
                        label="SSO"
                        checked={showOnlySso}
                        onChange={toggleShowOnlySso}
                        disabled={usersFetch.isLoading}
                    />
                    <Checkbox
                        label="Local"
                        checked={showOnlyLocal}
                        onChange={toggleShowOnlyLocal}
                        disabled={usersFetch.isLoading}
                    />
                </fieldset>
            </S.UsersTitleContainer>

            <UserTable
                users={usersFetch.data}
                loadingUsers={usersFetch.isLoading}
                filter={usersFilter}
                canToggleUserStatus
            />

            <S.NewUserButtonContainer>
                <AddButton
                    onClick={handleCreateNewUserClick}
                    disabled={usersFetch.isLoading}
                >
                    New user
                </AddButton>
            </S.NewUserButtonContainer>

            {inviteNewUserModalOpen && (
                <InviteUserModal
                    open
                    organizationId={selectedOrganization!.id}
                    onClose={handleInviteNewUserModalClose}
                    initialOrganizationSelectionAllowed
                />
            )}
        </ContainerWithLoadingSpinner>
    );
};

export default Users;
