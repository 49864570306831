import * as React from "react";

import type FrendsToggleProps from "frends-ui-components/dist/types/toggle/Toggle";
import "frends-ui-components/Toggle";

type ToggleProps = React.PropsWithChildren<{
    onChange?: () => void;
}> & Partial<Pick<FrendsToggleProps,
    'label' |
    'hidelabel' |
    'checked' |
    'value' |
    'name' |
    'ontext' |
    'offtext'
>>;

const Toggle: React.FC<ToggleProps> = (props) => {
    const ref = React.useRef<FrendsToggleProps>(null);

    React.useEffect(() => {
        if (props.checked !== undefined && ref.current) {
            ref.current.checked = props.checked;
        }
    }, [props.checked]);

    React.useEffect(() => {
        const onToggleChange = () => props.onChange?.();

        const { current } = ref;

        current?.addEventListener('change', onToggleChange);

        return () => current?.removeEventListener('change', onToggleChange);
    }, [props]);

    return (
        <frends-toggle
            ref={ref}
            label={props.label}
            hidelabel={props.hidelabel}
            value={props.value}
            name={props.name}
            ontext={props.ontext}
            offtext={props.offtext}
        />
    );
};

export default Toggle;