import * as React from "react";

import useUserData from "hooks/useUserData";
import useQuery from "hooks/useQuery";

import OrganizationUsers from "components/settings/organization/organizationSettings/OrganizationUsers";

import { User } from "model/User";

const SimpleOrganizationSettings: React.FC = () => {
    const { selectedOrganization } = useUserData();

    const organizationUsersFetch = useQuery<User[]>(
        `users/organization/${selectedOrganization!.id}`,
        undefined,
        undefined,
        "Failed to fetch users.",
    );

    return (
        <div>
            <OrganizationUsers
                titleTag="h2"
                organizationId={selectedOrganization!.id}
                users={organizationUsersFetch.data}
                loadingUsers={organizationUsersFetch.isLoading}
            />
        </div>
    );
};

export default SimpleOrganizationSettings;
