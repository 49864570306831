import * as React from "react";

import * as S from "@styled";

import type FrendsDropdownProps from "frends-ui-components/dist/types/dropdown/Dropdown";
import { type ChangeEventDetail } from "frends-ui-components/dist/types/dropdown/Dropdown";
import "frends-ui-components/Dropdown";

import { ReactComponent as SliderIcon } from "@icons/slider_icon.svg";

type DropdownProps = React.PropsWithChildren<{
    onChange?: (nextValue: string | string[] | number) => void;
}> & Partial<Pick<FrendsDropdownProps,
    'label' |
    'hidelabel' |
    'placeholder' |
    'options' |
    'selected' |
    'errortext' |
    'invalid' |
    'disabled' |
    'searchable' |
    'multivalue' |
    'hidevalues' |
    'allowdeselect'
>>;

const Dropdown: React.FC<DropdownProps> = (props) => {
    const ref = React.useRef<FrendsDropdownProps>(null);

    React.useEffect(() => {
        if (ref.current && props.options !== undefined) {
            ref.current.options = props.options;
        }
    }, [props.options]);

    React.useEffect(() => {
        if (ref.current && props.selected !== undefined) {
            ref.current.selected = props.selected;
        }
    }, [props.selected]);

    React.useEffect(() => {
        if (ref.current && props.disabled !== undefined) {
            ref.current.disabled = props.disabled;
        }
    }, [props.disabled]);

    React.useEffect(() => {
        if (ref.current && props.invalid !== undefined) {
            ref.current.invalid = props.invalid;
        }
    }, [props.invalid]);

    React.useEffect(() => {
        const onDropdownChange = (event: CustomEvent) => {
            const details = event.detail as ChangeEventDetail;
            props.onChange?.(details.selected);
        };
        const { current } = ref;

        current?.addEventListener('change', onDropdownChange);

        return () => current?.removeEventListener('change', onDropdownChange);

    }, [props]);

    return (
        <frends-dropdown
            ref={ref}
            label={props.label}
            hidelabel={props.hidelabel}
            placeholder={props.placeholder}
            errortext={props.errortext}
            searchable={props.searchable}
            multivalue={props.multivalue}
            hidevalues={props.hidevalues}
            allowdeselect={props.allowdeselect}
        >
            {props.children && (
                props.children
            )}
        </frends-dropdown>
    );
};

export const SmallDropdown: React.FC<DropdownProps> = (props) => (
    <S.SmallDropdownCssPropertiesWrapper>
        <Dropdown {...props} />
    </S.SmallDropdownCssPropertiesWrapper>
);

export const TableSelectColumnsDropdown: React.FC<DropdownProps> = (props) => (
    <S.TableSelectColumnsDropdownCssPropertiesWrapper>
        <Dropdown
            {...props}
            hidelabel
            multivalue
            hidevalues
            allowdeselect
        >
            <SliderIcon />
        </Dropdown>
    </S.TableSelectColumnsDropdownCssPropertiesWrapper>
);

export default Dropdown;