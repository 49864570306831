import * as React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import useDeferredMutation from "hooks/useDeferredMutation";

import * as S from "@styled";

import SimplePageContainer from "components/simplePages/SimplePageContainer";
import PasswordChangeInfo from "components/passwords/PasswordChangeInfo";
import { SaveButton } from "components/shared/Button";
import Input, { InputRef } from "components/shared/Input";
import PasswordActionSuccess from "components/passwords/PasswordActionSuccess";

import { PASSWORD_REQUIREMENTS_PATTERN } from "shared/PasswordRequirements";
import { LANDING_PATH, LOGIN_PATH } from "AppRoutes";

const ResetPassword: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [email, setEmail] = React.useState("");
    const [resetCode, setResetCode] = React.useState("");

    const [newPassword, setNewPassword] = React.useState("");
    const newPasswordInputRef = React.useRef<InputRef>(null);
    const newPasswordValid =
        newPasswordInputRef.current?.checkValidity() ?? false;

    const [confirmedPassword, setConfirmedPassword] = React.useState("");
    const confirmedPasswordInputRef = React.useRef<InputRef>(null);
    const confirmedPasswordValid =
        (confirmedPasswordInputRef.current?.checkValidity() ?? false) &&
        newPassword === confirmedPassword;

    const [submitTried, setSubmitTried] = React.useState(false);

    React.useEffect(() => {
        const emailInQuery = searchParams.get("email");
        const resetCodeInQuery = searchParams.get("resetCode");
        if (!emailInQuery || !resetCodeInQuery) {
            navigate(LANDING_PATH);
            return;
        }

        setEmail(emailInQuery);
        setResetCode(resetCodeInQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const localUserResetPasswordPOST = useDeferredMutation("POST");

    const inputsValid = newPasswordValid && confirmedPasswordValid;

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setSubmitTried(true);

        if (inputsValid) {
            localUserResetPasswordPOST.mutate({
                url: "account/resetPassword",
                body: {
                    email: email,
                    resetCode: resetCode,
                    newPassword: confirmedPassword,
                },
            });
        }
    };

    const handleGoToLoginClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
        evt.preventDefault();

        navigate(LOGIN_PATH, {
            state: {
                email: email,
                localUserLoginExpected: true,
            },
        });
    };

    return (
        <SimplePageContainer>
            {!localUserResetPasswordPOST.isSuccess && (
                <S.PasswordActionForm onSubmit={handleSubmit}>
                    <PasswordChangeInfo />

                    <input type="hidden" name="reset-code" value={resetCode} />

                    <Input
                        label="Email"
                        type="email"
                        autocomplete="current-password"
                        value={email}
                        disabled
                    />

                    <Input
                        ref={newPasswordInputRef}
                        label="New password"
                        type="password"
                        autocomplete="new-password"
                        required
                        pattern={PASSWORD_REQUIREMENTS_PATTERN}
                        value={newPassword}
                        onChange={(next) => setNewPassword(String(next))}
                        invalid={submitTried && !newPasswordValid}
                        errortext="New password must match the requirements above"
                    />

                    <Input
                        ref={confirmedPasswordInputRef}
                        label="Confirm new password"
                        type="password"
                        autocomplete="new-password"
                        required
                        value={confirmedPassword}
                        onChange={(next) => setConfirmedPassword(String(next))}
                        invalid={submitTried && !confirmedPasswordValid}
                        errortext="New and confirmed passwords must match"
                    />

                    <SaveButton
                        type="submit"
                        disabled={submitTried && !inputsValid}
                        loading={localUserResetPasswordPOST.isLoading}
                        error={localUserResetPasswordPOST.isError}
                    >
                        Save
                    </SaveButton>
                </S.PasswordActionForm>
            )}

            {localUserResetPasswordPOST.isSuccess && (
                <PasswordActionSuccess
                    title="New password set successfully!"
                    body="Login with your new password"
                    link={{
                        href: LOGIN_PATH,
                        text: "Go to login",
                        onClick: handleGoToLoginClick,
                    }}
                />
            )}
        </SimplePageContainer>
    );
};

export default ResetPassword;
