import * as React from "react";
import * as S from "@styled";
import NavMenu from "./NavMenu";
import { Outlet } from "react-router-dom";

const Layout: React.FC<LayoutProps> = () => {
    return (
        <S.Layout>
            <NavMenu />
            <S.WidthContainer>
                <S.PageContainer>
                    <Outlet />
                </S.PageContainer>
            </S.WidthContainer>
        </S.Layout>
    );
};

interface LayoutProps extends React.PropsWithChildren {}

export default Layout;
