import * as React from "react";
import { useLocation } from "react-router-dom";
import * as S from "@styled";
import Input from "components/shared/Input";
import Text from "components/shared/Text";
import {
    ChangeStepProps,
    TemplateWizardStep,
} from "../../../pages/integrationCatalog/TemplateWizard";
import { ProcessVariable } from "model/process/ProcessVariable";
import Button from "components/shared/Button";
import Tag from "components/shared/Tag";
import Divider from "components/shared/Divider";
import Tooltip from "components/shared/Tooltip";
import styled from "styled-components";
import { INTEGRATION_CATALOG_BASE_PATH } from "AppRoutes";

const ConfigureIntegrationStep: React.FC<ConfigureVariablesProps> = (props) => {
    const location = useLocation();

    const processVariables = props?.originalProcessVariables?.map(
        (processVariable) => (
            <ProcessVariableInput
                key={processVariable.name}
                processVariable={processVariable}
                onChangeProcessVariable={props.onChangeProcessVariable}
            />
        ),
    );

    const hasProcessVariables = processVariables
        ? processVariables?.length > 0
        : false;

    const backLinkHref =
        location.state?.previousPage ??
        `${INTEGRATION_CATALOG_BASE_PATH}/${props.templateId}`;

    return (
        <S.TemplateWizardPageContainer>
            <Input
                label="Integration name"
                value={props.integrationName}
                onChange={(e: string) => props.onChangeIntegrationName(e)}
            />
            {hasProcessVariables && (
                <>
                    <Divider />
                    <Text tag="h3">Configure variables</Text>
                    {processVariables}
                </>
            )}

            {props.processTags?.length > 0 && (
                <S.CardTagsContainer>
                    {props.processTags.map((tag, index) => (
                        <Tag key={index}>{tag}</Tag>
                    ))}
                </S.CardTagsContainer>
            )}
            <S.TemplateWizardStepButtonsContainer>
                <Button type="link" href={backLinkHref} variant="bordered">
                    Back
                </Button>
                <Button
                    disabled={!props.isValidBasicConfiguration}
                    variant="bordered"
                    onClick={() =>
                        props.onChangeStep(TemplateWizardStep.TestIntegration)
                    }
                >
                    Continue
                </Button>
            </S.TemplateWizardStepButtonsContainer>
        </S.TemplateWizardPageContainer>
    );
};

const ProcessVariableInput: React.FC<IProcessVariableInput> = (props) => {
    const { processVariable } = props;

    return (
        <React.Fragment key={processVariable.name}>
            <S.GridContainer
                $alignItems="center"
                $justifyContent="start"
                $justifyItems="start"
                $gridTemplateColumns={"auto auto"}
            >
                <VariableLabel>{processVariable.name}</VariableLabel>
                <Tooltip placement="top">Type: {processVariable.mode}</Tooltip>
            </S.GridContainer>
            <Input
                hidelabel={true}
                label={processVariable.name}
                value={processVariable.value}
                required={true}
                onChange={(e: string) =>
                    props.onChangeProcessVariable(processVariable.name, e)
                }
            />
            {processVariable.description && (
                <Text tag="p">{processVariable.description}</Text>
            )}
        </React.Fragment>
    );
};

const VariableLabel = styled.label`
    font-family: "Gilroy-Medium", sans-serif;
    font-size: var(--frends-text-input--label--font-size, 1.125rem);
    line-height: var(--frends-text-input--label--line-height, 1.188rem);
    color: var(--frends-text-input--label--color, "#000000");
    margin-bottom: var(--frends-text-input--gap, 0.25rem);
`;

interface IProcessVariableInput {
    processVariable: ProcessVariable;
    onChangeProcessVariable: (
        variableName: string,
        variableValue: string,
    ) => void;
}

interface ConfigureVariablesProps extends ChangeStepProps {
    templateId?: string;
    integrationName: string;
    originalProcessVariables?: ProcessVariable[];
    processTags: string[];
    isValidBasicConfiguration: boolean;
    onChangeIntegrationName: (nextValue: string) => void;
    onChangeProcessVariable: (
        variableName: string,
        variableValue: string,
    ) => void;
}

export default ConfigureIntegrationStep;
