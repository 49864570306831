import * as React from "react";

import useQuery from "hooks/useQuery";
import useDeferredMutation from "hooks/useDeferredMutation";
import useDeleteMutation from "hooks/useDeleteMutation";
import { queryClient } from "index";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Text from "components/shared/Text";
import Button, { AddButton, SaveButton } from "components/shared/Button";
import Modal, { ModalProps } from "components/shared/Modal";
import Table from "components/shared/Table";
import Link from "components/shared/Link";
import Input from "components/shared/Input";

import TagsSelector from "../TagsSelectors";
import ConfirmDeleteModal from "../ConfirmDeleteModal";

import { IntegrationCategory } from "model/ShopConfiguration";

import Time from "shared/Time";

import { ReactComponent as TrashIcon } from "@icons/trash.svg";
import { ReactComponent as SettingsIcon } from "@icons/settings.svg";

import { CATEGORIES_API_BASE_PATH, TEMPLATES_TAGS_API_PATH } from "AppRoutes";

const Categories: React.FC = () => {
    const invalidateQueries = () =>
        queryClient.invalidateQueries({ queryKey: [CATEGORIES_API_BASE_PATH] });
    const askDeleteConfirmTimestampKey =
        "frends_bap_categories_confirm_delete_timestamp";

    const fetch = useQuery<IntegrationCategory[]>(
        CATEGORIES_API_BASE_PATH,
        undefined,
        undefined,
        "Failed to fetch categories.",
    );
    const categories = fetch.data;

    const [categoryModalOpenFor, setCategoryModalOpenFor] =
        React.useState<IntegrationCategory>();

    const create = useDeferredMutation(
        "POST",
        invalidateQueries,
        "Failed to create category.",
    );

    const update = useDeferredMutation(
        "PUT",
        invalidateQueries,
        "Failed to update category.",
    );

    React.useEffect(() => {
        if ((create.isSuccess || update.isSuccess) && categoryModalOpenFor) {
            handleCategoryModalClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create, update, categoryModalOpenFor]);

    const handleNewCategoryClick = () =>
        setCategoryModalOpenFor({ id: undefined, name: "", tags: [] });

    const handleEditCategoryClick = (category: IntegrationCategory) =>
        setCategoryModalOpenFor(category);

    const handleCategoryModalClose = () => {
        setCategoryModalOpenFor(undefined);
        create.reset();
        update.reset();
    };

    const handleSave = (model: IntegrationCategory) => {
        if (model.id) {
            update.mutate({
                url: `${CATEGORIES_API_BASE_PATH}/${model.id}`,
                body: model,
            });
        } else {
            create.mutate({
                url: CATEGORIES_API_BASE_PATH,
                body: model,
            });
        }
    };

    const [openConfirmDeleteModalForId, setOpenConfirmDeleteModalForId] =
        React.useState<number>();

    const handleConfirmDeleteModalClose = () =>
        setOpenConfirmDeleteModalForId(undefined);

    const del = useDeleteMutation(
        "",
        invalidateQueries,
        "Failed to delete category.",
    );

    React.useEffect(() => {
        if (del.isSuccess && openConfirmDeleteModalForId !== undefined) {
            handleConfirmDeleteModalClose();
        }
    }, [del, openConfirmDeleteModalForId]);

    const handleDeleteCategoryClick = (catId?: number) => {
        if (!catId) {
            return;
        }

        setCategoryModalOpenFor(undefined);
        del.reset();

        const askDeleteConfirmTimestamp = localStorage.getItem(
            askDeleteConfirmTimestampKey,
        );
        if (
            askDeleteConfirmTimestamp === null ||
            !Time.isWithinTenMinutes(askDeleteConfirmTimestamp)
        ) {
            setOpenConfirmDeleteModalForId(catId);
        } else {
            handleDeleteCategory(catId);
        }
    };

    const handleDeleteCategory = (catId: number) =>
        del.mutate(`${CATEGORIES_API_BASE_PATH}/${catId}`);

    const isLoading =
        fetch.isFetching ||
        (openConfirmDeleteModalForId === undefined && del.isLoading);

    return (
        <ContainerWithLoadingSpinner loading={isLoading}>
            <S.ShopConfigurationTitleContainer>
                <Text tag="h2">Categories</Text>

                <AddButton
                    onClick={handleNewCategoryClick}
                    disabled={isLoading}
                >
                    New category
                </AddButton>
            </S.ShopConfigurationTitleContainer>

            {categories && categories.length > 0 && (
                <Table>
                    <table>
                        <S.SettingsTableHeadSROnly>
                            <tr>
                                <th>Category</th>
                                <th>Actions</th>
                                <th>Integration count</th>
                            </tr>
                        </S.SettingsTableHeadSROnly>

                        <tbody>
                            {categories.map((cat, i) => (
                                <S.ShopConfigurationTableRow key={i}>
                                    <td>
                                        <Link
                                            type="button"
                                            disabled={isLoading}
                                            onClick={() =>
                                                handleEditCategoryClick(cat)
                                            }
                                        >
                                            {cat.name}
                                        </Link>
                                    </td>
                                    <td>
                                        <S.ShopConfigurationTableActionButtonsContainer>
                                            <Button
                                                title="Edit"
                                                variant="bordered"
                                                onClick={() =>
                                                    handleEditCategoryClick(cat)
                                                }
                                                disabled={isLoading}
                                            >
                                                <SettingsIcon />
                                            </Button>

                                            <Button
                                                title="Delete"
                                                variant="bordered"
                                                onClick={() =>
                                                    handleDeleteCategoryClick(
                                                        cat.id,
                                                    )
                                                }
                                                disabled={isLoading}
                                            >
                                                <TrashIcon />
                                            </Button>
                                        </S.ShopConfigurationTableActionButtonsContainer>
                                    </td>
                                    <td>
                                        <Text tag="span">
                                            {`${cat.integrationCount} integrations`}
                                        </Text>
                                    </td>
                                </S.ShopConfigurationTableRow>
                            ))}
                        </tbody>
                    </table>
                </Table>
            )}

            {categories && categories.length === 0 && (
                <Text tag="p">No categories</Text>
            )}

            {categoryModalOpenFor !== undefined && (
                <CategoryModal
                    category={categoryModalOpenFor}
                    onSave={handleSave}
                    saving={create.isLoading || update.isLoading}
                    saveError={!!create.error || !!update.error}
                    onDelete={handleDeleteCategoryClick}
                    onClose={handleCategoryModalClose}
                />
            )}

            {openConfirmDeleteModalForId !== undefined && (
                <ConfirmDeleteModal
                    title="Are you sure you want to delete this category?"
                    disableConfirmationKey={askDeleteConfirmTimestampKey}
                    onDelete={() =>
                        handleDeleteCategory(openConfirmDeleteModalForId)
                    }
                    deleting={del.isLoading}
                    deleteError={!!del.error}
                    onClose={handleConfirmDeleteModalClose}
                />
            )}
        </ContainerWithLoadingSpinner>
    );
};

interface CategoryModalProps extends ModalProps {
    category: IntegrationCategory;
    onDelete: (id: number) => void;
    onSave: (category: IntegrationCategory) => void;
    saving: boolean;
    saveError: boolean;
}

const CategoryModal: React.FC<CategoryModalProps> = (props) => {
    const [name, setName] = React.useState(props.category.name);
    const [nameInvalid, setNameInvalid] = React.useState(false);
    const [selectedTags, setSelectedTags] = React.useState(props.category.tags);
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    const templateTagsFetch = useQuery<string[]>(
        TEMPLATES_TAGS_API_PATH,
        undefined,
        undefined,
        "Failed to fetch template tags.",
    );
    const templateTags = templateTagsFetch.data;

    const isNameInvalid = (name: string) => name === "";

    const handleCategoryNameChange = (name: string) => {
        setName(name);

        const nextNameInvalid = isNameInvalid(name);
        setNameInvalid(nextNameInvalid);
        setSaveDisabled(nextNameInvalid);
    };

    const handleSelectedTagNamesChange = (tagNames: string[]) => {
        setSelectedTags(tagNames);
        setSaveDisabled(nameInvalid ?? false);
    };

    const handleCancelClick = () => props.onClose?.();

    const handleDeleteClick = () => {
        if (!props.category.id) {
            return;
        }
        props.onDelete(props.category.id);
    };

    const handleSaveClick = () => {
        const nameValid = !isNameInvalid(name);

        if (nameValid) {
            const savedCategory = {
                ...props.category,
                name,
                tags: selectedTags,
            };
            props.onSave(savedCategory);
        } else {
            setNameInvalid(true);
            setSaveDisabled(true);
        }
    };

    const isNewCategory = props.category.id === undefined;

    return (
        <Modal
            title={isNewCategory ? "Create new category" : "Edit category"}
            onClose={props.onClose}
            open
        >
            <ContainerWithLoadingSpinner loading={templateTagsFetch.isFetching}>
                <S.ConfigureModalContainer>
                    <Input
                        label="Category name"
                        type="text"
                        value={name}
                        onChange={handleCategoryNameChange}
                        errortext="Category name is required."
                        invalid={nameInvalid}
                    />

                    <Text tag="h3">Select tags included in this category</Text>

                    {templateTags && (
                        <TagsSelector
                            tags={templateTags}
                            selectedTagNames={selectedTags}
                            onSelectedTagsChange={handleSelectedTagNamesChange}
                        />
                    )}

                    <S.ConfigureModalActionButtonContainer>
                        <Button
                            variant="bordered"
                            onClick={
                                isNewCategory
                                    ? handleCancelClick
                                    : handleDeleteClick
                            }
                        >
                            {isNewCategory ? "Cancel" : "Delete category"}
                        </Button>

                        <SaveButton
                            onClick={handleSaveClick}
                            disabled={
                                templateTagsFetch.isFetching || saveDisabled
                            }
                            loading={props.saving}
                            error={props.saveError}
                        >
                            {isNewCategory ? "Create" : "Save"}
                        </SaveButton>
                    </S.ConfigureModalActionButtonContainer>
                </S.ConfigureModalContainer>
            </ContainerWithLoadingSpinner>
        </Modal>
    );
};

export default Categories;
