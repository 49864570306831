import * as React from "react";

import type FrendsTabsProps from "frends-ui-components/dist/types/tabs/Tabs";
import { type ChangeEventDetail } from "frends-ui-components/dist/types/tabs/Tabs";
import "frends-ui-components/Tabs";

type TabsProps = React.PropsWithChildren<{
    onChange?: (selected: number | string) => void;
}> & Partial<Pick<FrendsTabsProps, 'tabs' | 'selected' | 'addValuesAsHref'>>;

const Tabs: React.FC<TabsProps> = (props) => {
    const ref = React.useRef<FrendsTabsProps>(null);

    React.useEffect(() => {
        if (props.tabs !== undefined && ref.current) {
            ref.current.tabs = props.tabs;
        }
    }, [props.tabs]);

    React.useEffect(() => {
        if (props.selected !== undefined && ref.current) {
            ref.current.selected = props.selected;
        }
    }, [props.selected]);

    React.useEffect(() => {
        if (props.addValuesAsHref !== undefined && ref.current) {
            ref.current.addValuesAsHref = props.addValuesAsHref;
        }
    }, [props.addValuesAsHref]);

    React.useEffect(() => {
        const onTabSelect = (event: CustomEvent) => {
            const details = event.detail as ChangeEventDetail;
            props.onChange?.(details.selected);
        };

        const { current } = ref;

        current?.addEventListener('change', onTabSelect);

        return () => current?.removeEventListener('change', onTabSelect);

    }, [props]);

    return (
        <frends-tabs ref={ref} />
    );
};

export default Tabs;