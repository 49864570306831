import * as React from "react";
import type FrendsCheckboxProps from "frends-ui-components/dist/types/checkbox/Checkbox";
import "frends-ui-components/Checkbox";

type CheckboxProps = React.PropsWithChildren<{
    onChange?: (nextValue: boolean) => void;
}> & Partial<Pick<FrendsCheckboxProps,
    'checked' |
    'invalid' |
    'disabled' |
    'label' |
    'hidelabel' |
    'type' |
    'errortext' |
    'value' |
    'name' |
    'required' |
    'tabIndex' |
    'indeterminate'
>>;

const Checkbox: React.FC<CheckboxProps> = (props) => {
    const ref = React.useRef<FrendsCheckboxProps>(null);

    React.useEffect(() => {
        if (ref.current && props.checked !== undefined) {
            ref.current.checked = props.checked;
        }
    }, [props.checked]);

    React.useEffect(() => {
        if (ref.current && props.disabled !== undefined) {
            ref.current.disabled = props.disabled;
        }
    }, [props.disabled]);

    React.useEffect(() => {
        if (ref.current && props.invalid !== undefined) {
            ref.current.invalid = props.invalid;
        }
    }, [props.invalid]);

    React.useEffect(() => {
        const onCheckboxChange = () => {
            ref.current && props.onChange?.(ref.current.checked);
        };

        const { current } = ref;

        current?.addEventListener('change', onCheckboxChange);

        return () => current?.removeEventListener('change', onCheckboxChange);
    }, [props]);

    return (
        <frends-checkbox
            ref={ref}
            label={props.label}
            hidelabel={props.hidelabel}
            type={props.type}
            errortext={props.errortext}
            value={props.value}
            name={props.name}
            required={props.required}
            tabIndex={props.tabIndex}
            indeterminate={props.indeterminate}
        />
    );
};
export default Checkbox;