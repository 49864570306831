import * as React from "react";

import type FrendsColorPickerProps from "frends-ui-components/dist/types/color-picker/ColorPicker";
import "frends-ui-components/ColorPicker";

type ColorPickerProps = React.PropsWithChildren<{
    onChange: (nextValue: string) => void;
    defaultValue?: string;
}> & Partial<Pick<FrendsColorPickerProps, "value" | "label" | "name" | "disabled" | "required">>;

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
    const ref = React.useRef<FrendsColorPickerProps>(null);

    React.useEffect(() => {
        if (ref.current && props.value !== undefined) {
            ref.current.value = props.value;
        }
    }, [props.value]);

    React.useEffect(() => {
        const onInputChange = (event: CustomEvent) => {
            const target = event.currentTarget as FrendsColorPickerProps;
            props.onChange(target.value);
        };
        const { current } = ref;

        current?.addEventListener('change', onInputChange);

        return () => current?.removeEventListener('change', onInputChange);
    }, [props]);

    return (
        <frends-color-picker
            ref={ref}
            value={props.value}
            label={props.label}
            name={props.name}
            disabled={props.disabled}
            required={props.required}
        />
    );
}

export default ColorPicker;