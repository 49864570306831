import styled, { css } from "styled-components";

const SimplePageInlinePadding = "7.375rem";

export const SimplePageLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100vh;
    background-color: var(--background-color, ${(props) => props.theme.grey4});
`;

export const SimplePageLogoContainer = styled.div`
    width: 100%;
    padding: 1rem ${SimplePageInlinePadding};
`;

export const SimplePageContainerCss = css`
    width: 100%;
    margin-top: 9.813rem;
    padding: 0 ${SimplePageInlinePadding};
`;

export const SimplePageFormCss = css`
    ${SimplePageContainerCss}

    padding: 0;
    display: grid;
    max-width: 23.25rem;
    margin-left: auto;
    margin-right: auto;

    --frends-text--margin: 0 0 2rem 0;
    --frends-text-input--container--margin: 0 0 1rem 0;
    frends-button[type="button"] {
        --frends-button--margin: 0 auto;
    }
`;
