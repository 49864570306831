import * as React from "react";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import UserTable from "components/settings/users/UsersTable";
import InviteUserModal from "components/settings/users/InviteUserModal";
import Text from "components/shared/Text";
import { AddButton } from "components/shared/Button";

import { User } from "model/User";

interface OrganizationUsersProps {
    titleTag: "h2" | "h3";
    organizationId: number;
    users?: User[];
    loadingUsers: boolean;
    canToggleUserStatus?: boolean;
}

const OrganizationUsers: React.FC<OrganizationUsersProps> = (props) => {
    const [inviteNewUserModalOpen, setInviteNewUserModalOpen] =
        React.useState(false);

    const handleNewUserClick = () => setInviteNewUserModalOpen(true);

    const handleInviteNewUserModalClose = () =>
        setInviteNewUserModalOpen(false);

    return (
        <ContainerWithLoadingSpinner loading={props.loadingUsers}>
            <S.GridContainer
                $margin="0 0 0.5rem 0"
                $gridTemplateColumns="auto auto"
                $justifyContent="space-between"
                $alignItems="center"
            >
                <Text tag={props.titleTag}>Users</Text>

                <AddButton
                    onClick={handleNewUserClick}
                    disabled={props.loadingUsers}
                >
                    New user
                </AddButton>
            </S.GridContainer>

            <UserTable {...props} />

            {inviteNewUserModalOpen && (
                <InviteUserModal
                    open
                    organizationId={props.organizationId}
                    onClose={handleInviteNewUserModalClose}
                />
            )}
        </ContainerWithLoadingSpinner>
    );
};

export default OrganizationUsers;
