import * as React from "react";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";
import useUserData from "hooks/useUserData";
import * as S from "@styled";
import Tabs from "components/shared/Tabs";

const Settings: React.FC = () => {
    const { isTenantAdmin } = useUserData();
    const navigate = useNavigate();
    const location = useLocation();

    const tabs = [
        {
            label: "Organizations",
            value: "organizations",
            alsoMatchTo: ["organizations/:id"],
        },
        { label: "Shop configuration", value: "shop-configuration" },
        { label: "Users", value: "users" },
        { label: "Look and Feel", value: "look-and-feel" },
        { label: "SSO configuration", value: "sso-configuration" },
    ];

    const match = (path: string) =>
        matchPath(`/settings/${path}`, location.pathname);

    const selectedTab = tabs.find((tab) =>
        [tab.value, ...(tab.alsoMatchTo ? tab.alsoMatchTo : [])].some((path) =>
            match(path),
        ),
    );

    const onTabsChange = (selected: string) => navigate(selected);

    return (
        <>
            {isTenantAdmin && (
                <S.SettingsTabsCssPropertiesWrapper>
                    <Tabs
                        tabs={tabs}
                        selected={selectedTab?.value}
                        onChange={onTabsChange}
                        addValuesAsHref
                    />
                </S.SettingsTabsCssPropertiesWrapper>
            )}

            <S.ContentContainer>
                <Outlet />
            </S.ContentContainer>
        </>
    );
};

export default Settings;
