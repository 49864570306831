import styled from "styled-components";

export const LookAndFeelPageForm = styled.form`
    --frends-text-input--container--margin: 0.5rem 0;
    --frends-text--margin: 1rem 0 2rem 0;
    --frends-divider--margin: 1.313rem 0;

    frends-text:first-of-type {
        --frends-text--margin: 0 0 2rem 0;
    }
`;

export const LookAndFeelColorsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 15rem);
`;

export const LookAndFeelInjectionsContainer = styled.div`
    --frends-text-input--label--font-size: var(--header-2-font-size);
    --frends-text-input--container--margin: 2rem 0;
`;
