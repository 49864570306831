import * as React from "react";
import * as S from "@styled";
import Breadcrumbs from "components/shared/Breadcrumbs";
import {
    INTEGRATION_CATALOG_BASE_PATH,
    TEMPLATES_API_BASE_PATH,
} from "AppRoutes";
import { Breadcrumb } from "frends-ui-components/dist/types/breadcrumbs/Breadcrumbs";
import { useParams, useNavigate } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { TemplateDetail } from "model/Template";
import Button from "components/shared/Button";
import Table from "components/shared/Table";
import TitleWithArrowLink from "components/shared/TitleWithArrowLink";
import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import { TemplateChangeLogVersion } from "model/TemplateChangeLog";
import Time from "shared/Time";

const TemplateChangeLog: React.FC = () => {
    const navigate = useNavigate();
    const { templateId } = useParams();

    const templateResponse = useQuery<TemplateDetail>(
        `${TEMPLATES_API_BASE_PATH}/${templateId}`,
        undefined,
        undefined,
        "Failed to fetch template details.",
    );
    const template = templateResponse.data;

    const templateDetailsRoute = `${INTEGRATION_CATALOG_BASE_PATH}/${templateId}`;
    const breadcrumbs: Breadcrumb[] = [
        {
            label: "Integration catalog",
            href: INTEGRATION_CATALOG_BASE_PATH,
        },
        {
            label: "Template details",
            href: templateDetailsRoute,
        },
        {
            label: "Change log",
            href: `${INTEGRATION_CATALOG_BASE_PATH}/${templateId}/changelog`,
        },
    ];

    return (
        <S.TemplateDetailsPageContainer>
            <ContainerWithLoadingSpinner
                loading={
                    templateResponse.isLoading || templateResponse.isFetching
                }
            >
                <Breadcrumbs breadcrumbs={breadcrumbs} onClick={navigate} />
                <S.ContentContainer>
                    <S.TemplateDetailsContentContainer>
                        {templateResponse.isLoading && (
                            <TitleWithArrowLink
                                href={templateDetailsRoute}
                                title="Loading template details..."
                            />
                        )}

                        {!templateResponse.isLoading && template && (
                            <>
                                <TitleWithArrowLink
                                    title={template.name}
                                    href={templateDetailsRoute}
                                />
                                <ChangeLogTable
                                    templateUniqueIdentifier={
                                        template.uniqueIdentifier
                                    }
                                />
                                <S.TemplateChangeLogActionsContainer>
                                    <Button
                                        type="link"
                                        href={templateDetailsRoute}
                                    >
                                        Back to template details
                                    </Button>
                                    <Button
                                        href={`mailto:${template.modifier}`}
                                        variant="bordered"
                                    >
                                        Contact integration developer
                                    </Button>
                                </S.TemplateChangeLogActionsContainer>
                            </>
                        )}

                        {!templateResponse.isLoading && !template && (
                            <TitleWithArrowLink
                                href={INTEGRATION_CATALOG_BASE_PATH}
                                title="Template could not be loaded"
                            />
                        )}
                    </S.TemplateDetailsContentContainer>
                </S.ContentContainer>
            </ContainerWithLoadingSpinner>
        </S.TemplateDetailsPageContainer>
    );
};

const ChangeLogTable: React.FC<ChangeLogTableProps> = (props) => {
    const templateChangeLogResponse = useQuery<TemplateChangeLogVersion[]>(
        `${TEMPLATES_API_BASE_PATH}/${props.templateUniqueIdentifier}/changelog`,
        undefined,
        undefined,
        "Failed to fetch template change log.",
    );

    const templateVersions = templateChangeLogResponse.data;

    if (
        (!templateVersions || templateVersions.length === 0) &&
        !templateChangeLogResponse.isLoading
    ) {
        return <div>Change log could not be loaded</div>;
    }

    return (
        <ContainerWithLoadingSpinner
            loading={templateChangeLogResponse.isLoading}
        >
            {templateVersions && templateVersions?.length > 0 && (
                <Table>
                    <S.TemplateChangeLogTable>
                        <thead>
                            <tr>
                                <th>Version</th>
                                <th>Time</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templateVersions.map((version, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{version.version}</td>
                                        <td>
                                            {Time.toDetailedLocalTime(
                                                version.modifiedUtc,
                                            )}
                                        </td>
                                        <td>{version.description}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </S.TemplateChangeLogTable>
                </Table>
            )}
        </ContainerWithLoadingSpinner>
    );
};

interface ChangeLogTableProps {
    templateUniqueIdentifier: string;
}

export default TemplateChangeLog;
