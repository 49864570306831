import styled from "styled-components";

import { screenReaderOnlyStyles } from "./ScreenReadersOnly";

export const SettingsTabsCssPropertiesWrapper = styled.div`
    --frends-tabs--margin: 0.5rem 0 0 3.125rem;
    --frends-tabs--border-bottom: 0;
`;

export const SettingsTableHeadSROnly = styled.thead`
    ${screenReaderOnlyStyles};
`;

export const SettingsTableRow = styled.tr`
    &:is(:first-child) td {
        &:first-child {
            border-top-left-radius: 0.938rem;
        }

        &:last-child {
            border-top-right-radius: 0.938rem;
        }
    }

    &:hover td {
        background-color: ${(props) => props.theme.grey5};
    }
`;

export const SettingsTableActionButtonsContainer = styled.div`
    --frends-button--bordered--outline: 0;
    --frends-button--padding: 0 0.5rem;
    --frends-button--bordered--background-color: transparent;
    --frends-button--bordered--hover--background-color: transparent;
    --frends-button--svg--fill: var(--accent-color);
    --frends-button--hover--svg--fill: oklch(
        from var(--accent-color) calc(l * 0.9) c h
    );
`;

export const SettingsTableShownItemControl = styled.div`
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
`;

export const OrganizationSettingsForm = styled.form`
    display: grid;
    gap: 1.313rem;

    fieldset {
        display: grid;
        gap: 1.313rem;

        frends-dropdown {
            max-width: 22.5rem;
        }
    }
`;

export const OrganizationAvailableIntegrationsTableRow = styled(
    SettingsTableRow,
)`
    td:nth-child(2) {
        frends-button {
            visibility: hidden;
        }
    }

    &:hover {
        td:nth-child(2) {
            frends-button {
                visibility: visible;
            }
        }
    }
`;

export const OrganizationAvailableIntegrationsTableActionButtonsContainer = styled(
    SettingsTableActionButtonsContainer,
)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.688rem;
`;

export const SsoConfigurationForm = styled.form`
    --frends-toggle--container--margin: 0.5rem 0;
    --frends-toggle--container--margin: 1rem 0;
    --frends-toggle--label--font-size: 1.25rem;
    --frends-button--margin: 1rem 0 0 0;
    --frends-text-input--container--margin: 0.5rem 0;
`;

export const SettingsSaveButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const UsersTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    fieldset {
        display: flex;
        gap: 1rem;

        --frends-text--margin: 0 0.5rem 0 0;
    }
`;

export const UsersTableRow = styled(SettingsTableRow)`
    td:nth-child(1) {
        width: 25%;
        word-break: break-all;
    }

    td:nth-child(2) {
        width: 25%;
        word-break: break-word;
    }

    td:nth-child(3) {
        width: 10%;
    }

    td:nth-child(4) {
        width: 15%;
    }

    td:nth-child(5) {
        width: 25%;
    }

    td:nth-child(2) > frends-text {
        margin-left: 0.5rem;
    }

    td:nth-child(2) > div {
        display: none;
    }

    td:nth-child(5) > div {
        visibility: hidden;
    }

    &:hover {
        td:nth-child(2) > frends-text[color="black"] {
            display: none;
        }

        td:nth-child(2) > div {
            display: block;
        }

        td:nth-child(5) > div {
            visibility: visible;
        }
    }
`;

export const UsersTableActionButtonsContainer = styled(
    SettingsTableActionButtonsContainer,
)`
    display: grid;
    align-items: center;
    justify-content: end;
    gap: 1rem;

    // has both disable and delete actions
    &:has(frends-button:nth-child(2)) {
        grid-template-columns: 3.75rem 2.5rem;
    }
`;

export const NewUserButtonContainer = styled.div`
    margin-top: 1.313rem;
`;

export const InviteNewUserModalForm = styled.form`
    display: grid;
    margin-top: 1.5rem;
    gap: 1.5rem;
    width: 100dvh;
    max-width: 53rem;

    --frends-dropdown--width: 43%;

    frends-text-input[label="Email"] {
        --frends-text-input--container--width: 43%;
    }

    > div:last-child {
        display: flex;
        justify-content: flex-end;
    }
`;

export const ShopConfigurationPageContainer = styled.div`
    display: grid;
    gap: 1.313rem;
`;

export const ShopConfigurationTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

export const ShopConfigurationTableRow = styled(SettingsTableRow)`
    td:nth-child(1) {
        width: auto;
        word-break: break-all;

        --frends-link--text-decoration: none;
    }

    td:nth-child(2) {
        width: 5%;

        > div {
            visibility: hidden;
        }
    }

    td:nth-child(3) {
        width: 8.75rem;
        text-align: right;
    }

    &:hover td:nth-child(2) > div {
        visibility: visible;
    }

    --frends-link--color: ${(props) => props.theme.black};
`;

export const ShopConfigurationTableActionButtonsContainer = styled(
    SettingsTableActionButtonsContainer,
)`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1rem;
`;

export const ShopConfigurationPageEnvironmentsContainer = styled(
    ShopConfigurationPageContainer,
)`
    --frends-dropdown--width: 22.5rem;
`;

export const ConfigureModalContainer = styled.div`
    margin-top: 1.5rem;
    width: 54rem;
    display: grid;
    gap: 1.5rem;
`;

export const ConfigureModalSearchFieldset = styled.fieldset`
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    gap: 1.25rem;
`;

export const ConfigureModalTablesContainer = styled.div`
    display: grid;
    height: 27rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content auto;
    column-gap: 1.5rem;
    row-gap: 0.5rem;
    align-items: center;

    > * {
        align-self: flex-start;
    }

    > :nth-child(2),
    > :nth-child(4) {
        &:is(frends-text) {
            justify-self: center;
        }
    }

    > :nth-child(3) {
        grid-column: 2;
        grid-row: 1;
    }

    --frends-table--border: 0;

    frends-table {
        max-height: 100%;
        overflow: auto;
        border: 1px solid ${(props) => props.theme.grey2};
        border-radius: 1.25rem;
    }
`;

export const ConfigureModalSelectedTagsTableRow = styled.tr`
    td:nth-child(1) {
        width: auto;
    }

    td:nth-child(2) {
        width: 5%;

        frends-button {
            visibility: hidden;
        }
    }

    &:hover {
        td {
            background-color: ${(props) => props.theme.grey5};
        }

        td:nth-child(2) frends-button {
            visibility: visible;
        }
    }
`;

export const ConfigureModalSelectedTagsTableActionButtonsContainer = styled(
    SettingsTableActionButtonsContainer,
)`
    --frends-button--height: 1.5rem;
    --frends-button--bordered--outline: 0;
    --frends-button--padding: 0 0.5rem;
`;

export const ConfigureModalAvailableTagsTableRow = styled.tr`
    td:nth-child(1) {
        width: 5%;
    }

    td:nth-child(2) {
        width: auto;
    }
`;

export const ConfigureModalActionButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
`;
