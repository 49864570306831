import * as React from "react";
import * as S from "@styled";
import SimplePageContainer from "components/simplePages/SimplePageContainer";
import Text from "components/shared/Text";
import { AppContext } from "App";
import Button from "components/shared/Button";
import Link from "components/shared/Link";
import { LOGIN_PATH, ORGANIZATION_REQUEST_PATH } from "AppRoutes";

const Landing: React.FC = () => {
    const { state } = React.useContext(AppContext);

    const landingPageTitle =
        state.lookAndFeel.landingPageTitle ||
        "Empower Your Team with Frends Business Application Portal";
    const landingPageText =
        state.lookAndFeel.landingPageText ||
        "Create, manage, and streamline your business processes with ease—no technical expertise required. The Frends Business Application Portal puts the power of process automation in your hands, enabling your team to achieve more with intuitive tools and seamless integration.";
    const landingPageBackground = state.lookAndFeel.landingPageBackground;

    return (
        <SimplePageContainer>
            <S.LandingPageTextImageContainer>
                <S.LandingPageTextContainer>
                    <Text tag="h1">{landingPageTitle}</Text>
                    <Text tag="p">{landingPageText}</Text>
                    <Button type="link" href={LOGIN_PATH}>
                        Login
                    </Button>
                    <Link href={ORGANIZATION_REQUEST_PATH}>
                        Create new Organization
                    </Link>
                </S.LandingPageTextContainer>
                <S.LandingPageImageContainer>
                    <img src={landingPageBackground} />
                </S.LandingPageImageContainer>
            </S.LandingPageTextImageContainer>
        </SimplePageContainer>
    );
};

export default Landing;
