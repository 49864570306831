import * as React from "react";
import * as S from "@styled";
import {
    ChangeStepProps,
    TemplateWizardStep,
} from "../../../pages/integrationCatalog/TemplateWizard";
import Button, { SaveButton } from "components/shared/Button";
import Input from "components/shared/Input";

const DeployIntegrationStep: React.FC<DeployIntegrationProps> = (props) => {
    return (
        <S.TemplateWizardPageContainer>
            <Input
                type="textarea"
                label="Deploy comment"
                value={props.deployComment}
                onChange={(e: string) => props.onChangeDeployComment(e)}
            />
            <S.TemplateWizardStepButtonsContainer>
                <Button
                    variant="bordered"
                    onClick={() =>
                        props.onChangeStep(TemplateWizardStep.TestIntegration)
                    }
                >
                    Back
                </Button>
                <SaveButton
                    variant="bordered"
                    disabled={!props.deployComment}
                    onClick={() => props.onDeployToProduction()}
                    error={props.isDeploymentError}
                    loading={props.isDeploymentLoading}
                    success={props.isDeploymentSuccessful}
                >
                    Deploy to use
                </SaveButton>
            </S.TemplateWizardStepButtonsContainer>
        </S.TemplateWizardPageContainer>
    );
};

interface DeployIntegrationProps extends ChangeStepProps {
    deployComment: string;
    isDeploymentSuccessful: boolean;
    isDeploymentLoading: boolean;
    isDeploymentError: boolean;
    onDeployToProduction: () => void;
    onChangeDeployComment: React.Dispatch<React.SetStateAction<string>>;
}

export default DeployIntegrationStep;
