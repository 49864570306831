import * as React from "react";
import { useNavigate } from "react-router-dom";

import type FrendsLinkProps from "frends-ui-components/dist/types/link/Link";
import "frends-ui-components/Link";

type LinkProps = React.PropsWithChildren<{
    onClick?: (
        event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
    ) => void;
}> &
    Partial<
        Pick<
            FrendsLinkProps,
            "href" | "target" | "rel" | "type" | "disabled" | "text"
        >
    >;

const Link: React.FC<LinkProps> = (props) => {
    const ref = React.useRef<FrendsLinkProps>(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (ref.current && props.children != undefined) {
            ref.current.text = props.children.toString();
        }
    }, [props.children]);

    React.useEffect(() => {
        if (ref.current && props.disabled !== undefined) {
            ref.current.disabled = props.disabled;
        }
    }, [props.disabled]);

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    ) => {
        if (props.onClick) {
            props.onClick(event);
        } else if (props.href) {
            event.preventDefault();
            navigate(props.href);
        }
    };

    return (
        <frends-link
            ref={ref}
            href={props.href}
            target={props.target}
            rel={props.rel}
            type={props.type}
            onClick={handleClick}
        />
    );
};

export default Link;
