import * as React from "react";

import * as S from "@styled";

import Text from "components/shared/Text";
import Link from "components/shared/Link";

interface SettingsTableShownItemsControlProps {
    initialCount: number;
    shownCount: number;
    totalCount: number,
    onShowButtonClick: (nextShownCount: number) => void;
}

const SettingsTableShownItemsControl: React.FC<SettingsTableShownItemsControlProps> = (props) => {
    const allShown = props.shownCount >= props.totalCount;

    const handleShowButtonClick = () => props.onShowButtonClick(allShown ? props.initialCount : props.totalCount);

    return (
        <S.SettingsTableShownItemControl>
            <Text tag="p">
                {`${allShown ? props.totalCount : props.shownCount} shown of ${props.totalCount}.`}
            </Text>

            &nbsp;

            {props.initialCount < props.totalCount && (
                <Link
                    type="button"
                    onClick={handleShowButtonClick}
                >
                    {allShown ? 'Show less' : 'Show all'}
                </Link>
            )}
        </S.SettingsTableShownItemControl>
    );
};

export default SettingsTableShownItemsControl;
