import * as React from "react";

import * as S from "@styled";
import Text from "components/shared/Text";
import Button, { SaveButton } from "components/shared/Button";
import Modal, { ModalProps } from "components/shared/Modal";
import Checkbox from "components/shared/Checkbox";

interface ConfirmDeleteModalProps extends ModalProps {
    onDelete: () => void;
    deleting: boolean;
    deleteError: boolean;
    disableConfirmationKey?: string;
    body?: string;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = (props) => {
    const [disableConfirmation, setDisableConfirmation] = React.useState(false);

    const toggleDisableConfirmation = () =>
        setDisableConfirmation((prev) => !prev);

    const handleCancelClick = () => props.onClose?.();

    const handleDeleteClick = () => {
        if (props.disableConfirmationKey && disableConfirmation) {
            const now = new Date();
            localStorage.setItem(
                props.disableConfirmationKey,
                String(now.getTime()),
            );
        }
        props.onDelete();
    };

    return (
        <Modal title={props.title} onClose={props.onClose} open>
            <S.ConfigureModalContainer>
                <Text tag="p">{props.body ?? "Are you sure?"}</Text>

                {props.disableConfirmationKey && (
                    <Checkbox
                        label="Don’t ask again for 10 minutes"
                        checked={disableConfirmation}
                        onChange={() => toggleDisableConfirmation()}
                    />
                )}

                <S.ConfigureModalActionButtonContainer
                    style={{ justifyContent: "flex-end" }}
                >
                    <Button variant="bordered" onClick={handleCancelClick}>
                        No, don’t delete
                    </Button>

                    <SaveButton
                        onClick={handleDeleteClick}
                        loading={props.deleting}
                        error={props.deleteError}
                    >
                        Yes, delete
                    </SaveButton>
                </S.ConfigureModalActionButtonContainer>
            </S.ConfigureModalContainer>
        </Modal>
    );
};

export default ConfirmDeleteModal;
