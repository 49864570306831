import * as React from "react";
import { useNavigate } from "react-router-dom";
import * as S from "@styled";

import useUserData from "hooks/useUserData";
import useChangeSelectedOrganization from "hooks/useChangeSelectedOrganization";

import Button, { AddButton } from "components/shared/Button";
import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Input from "components/shared/Input";
import { OrganizationBase } from "model/Organization";
import useQuery from "hooks/useQuery";
import Table from "components/shared/Table";
import Text from "components/shared/Text";

import { INTEGRATIONS_BASE_PATH } from "AppRoutes";

const OrganizationList: React.FC = () => {
    const navigate = useNavigate();
    const organizationsResponse = useQuery<OrganizationBase[]>(
        "organizations",
        undefined,
        undefined,
        "Failed to fetch organizations.",
    );
    const organizations = organizationsResponse.data;

    const [organizationSearchFilter, setOrganizationSearchFilter] =
        React.useState<string>("");

    const filteredOrganizations = React.useMemo(() => {
        if (!organizations) {
            return [];
        }

        return organizations.filter((organization) =>
            organization.name
                .toLowerCase()
                .includes(organizationSearchFilter.toLowerCase()),
        );
    }, [organizations, organizationSearchFilter]);

    const handleNewOrganizationClick = () =>
        navigate("/settings/organizations/new");

    return (
        <S.GridContainer $gap="1.313rem">
            <S.GridContainer
                $gridTemplateColumns={"auto auto"}
                $justifyContent="space-between"
            >
                <AddButton onClick={handleNewOrganizationClick}>
                    New organization
                </AddButton>

                {organizations && (
                    <Input
                        type="search"
                        placeholder="Search..."
                        value={organizationSearchFilter}
                        onChange={(e: string) => setOrganizationSearchFilter(e)}
                    />
                )}
            </S.GridContainer>

            <ContainerWithLoadingSpinner
                loading={
                    organizationsResponse.isLoading ||
                    organizationsResponse.isFetching
                }
            >
                {filteredOrganizations && filteredOrganizations.length > 0 ? (
                    <OrganizationsTable organizations={filteredOrganizations} />
                ) : (
                    <Text tag="h3">No organizations</Text>
                )}
            </ContainerWithLoadingSpinner>
        </S.GridContainer>
    );
};

const OrganizationsTable: React.FC<{ organizations: OrganizationBase[] }> = (
    props,
) => {
    const { selectedOrganization } = useUserData();
    const navigate = useNavigate();
    const changeSelectedOrganization = useChangeSelectedOrganization();

    const handleViewIntegrationsClick = (organizationId: number) => {
        if (selectedOrganization?.id === organizationId) {
            navigate(INTEGRATIONS_BASE_PATH);
        } else {
            changeSelectedOrganization(organizationId);
        }
    };

    return (
        <Table>
            <table>
                <S.SettingsTableHeadSROnly>
                    <tr>
                        <th>Organization name</th>
                        <th>Organization actions</th>
                    </tr>
                </S.SettingsTableHeadSROnly>

                <tbody>
                    {props.organizations.map((organization) => (
                        <S.SettingsTableRow key={organization.id}>
                            <td>
                                <Text tag="h3">{organization.name}</Text>
                            </td>
                            <td>
                                <S.GridContainer
                                    $gridTemplateColumns={"auto auto"}
                                    $gap={"1rem"}
                                    $justifyContent="end"
                                >
                                    <Button
                                        type="link"
                                        href={`/settings/organizations/${organization.id}`}
                                    >
                                        Settings
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            handleViewIntegrationsClick(
                                                organization.id,
                                            )
                                        }
                                    >
                                        View integrations
                                    </Button>
                                </S.GridContainer>
                            </td>
                        </S.SettingsTableRow>
                    ))}
                </tbody>
            </table>
        </Table>
    );
};

export default OrganizationList;
