import * as React from "react";

import type FrendsTextInputProps from "frends-ui-components/dist/types/text-input/TextInput";
import "frends-ui-components/TextInput";

type InputProps = React.PropsWithChildren<{
    onChange?: (nextValue: string | number) => void;
}> &
    Partial<
        Pick<
            FrendsTextInputProps,
            | "value"
            | "label"
            | "hidelabel"
            | "errortext"
            | "invalid"
            | "type"
            | "placeholder"
            | "name"
            | "disabled"
            | "readonly"
            | "required"
            | "autocomplete"
            | "min"
            | "max"
            | "step"
            | "pattern"
            | "maxlength"
            | "minlength"
            | "cols"
            | "rows"
            | "tooltip"
        >
    >;

export interface InputRef {
    checkValidity: () => boolean;
    focus: () => void;
}

const Input = React.forwardRef<InputRef, InputProps>(function Input(
    props: InputProps,
    ref: React.ForwardedRef<InputRef>,
) {
    const _ref = React.useRef<FrendsTextInputProps>(null);

    React.useImperativeHandle(ref, () => ({
        checkValidity: () => _ref.current?.checkValidity() ?? false,
        focus: () => _ref.current?.focus(),
    }));

    React.useEffect(() => {
        if (_ref.current && props.value !== undefined) {
            _ref.current.value = props.value;
        }
    }, [props.value]);

    React.useEffect(() => {
        if (_ref.current && props.readonly !== undefined) {
            _ref.current.readonly = props.readonly;
        }
    }, [props.readonly]);

    React.useEffect(() => {
        if (_ref.current && props.invalid !== undefined) {
            _ref.current.invalid = props.invalid;
        }
    }, [props.invalid]);

    React.useEffect(() => {
        if (_ref.current && props.disabled !== undefined) {
            _ref.current.disabled = props.disabled;
        }
    }, [props.disabled]);

    React.useEffect(() => {
        const onInputChange = (event: CustomEvent) => {
            const target = event.currentTarget as FrendsTextInputProps;
            props.onChange?.(target.value);
        };
        const { current } = _ref;

        current?.addEventListener("input", onInputChange);

        return () => current?.removeEventListener("input", onInputChange);
    }, [props]);

    return (
        <frends-text-input
            ref={_ref}
            value={props.value}
            label={props.label}
            hidelabel={props.hidelabel}
            errortext={props.errortext}
            type={props.type}
            placeholder={props.placeholder}
            name={props.name}
            required={props.required}
            autocomplete={props.autocomplete}
            min={props.min}
            max={props.max}
            step={props.step}
            pattern={props.pattern}
            maxlength={props.maxlength}
            minlength={props.minlength}
            cols={props.cols}
            rows={props.rows}
            tooltip={props.tooltip}
        />
    );
});

export default Input;
