import * as React from "react";
import * as S from "@styled";

import Text from "components/shared/Text";
import { Template } from "model/Template";
import { AppContext } from "App";
import IntegrationFilters from "components/integrations/IntegrationFilters";
import Tag from "components/shared/Tag";
import useQuery from "hooks/useQuery";
import { useQueryClient, UseQueryResult } from "react-query";
import {
    INTEGRATION_CATALOG_BASE_PATH,
    TEMPLATES_API_BASE_PATH,
    TEMPLATES_TAGS_API_PATH,
} from "AppRoutes";
import { PagedResponse } from "model/PagedResponse";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import { useLocation } from "react-router-dom";
import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";

const IntegrationCatalog: React.FC = () => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const { state } = React.useContext(AppContext);

    const catalogPageTitle =
        state.lookAndFeel.catalogPageTitle || "Integration catalog";
    const catalogPageText =
        state.lookAndFeel.catalogPageText ||
        "Discover a wide range of pre-built integrations to connect your applications and automate workflows effortlessly. The Frends Integration Catalog offers everything you need to streamline your business processes and enhance productivity—no coding required. Find, configure, and deploy integrations in just a few clicks.";

    const loadMoreTemplatesRef = React.useRef<HTMLDivElement | null>(null);

    const getListViewFromLocalStorage =
        localStorage.getItem(IntegrationCatalogLayoutKey) === null
            ? "true"
            : localStorage.getItem(IntegrationCatalogLayoutKey);
    const [isListView, setIsListView] = React.useState(
        getListViewFromLocalStorage === "true",
    );

    const [pageNumber, setPageNumber] = React.useState<number>(1);
    const [searchFilter, setSearchFilter] = React.useState<string>("");
    const [tagFilter, setTagFilter] = React.useState<string[]>();

    const isFiltersActive = React.useMemo(() => {
        const isSearchFilterActive = searchFilter.length > 0;
        const isTagFilterActive = tagFilter ? tagFilter?.length > 0 : false;
        return isSearchFilterActive || isTagFilterActive;
    }, [searchFilter, tagFilter]);

    const templates = useQuery<PagedResponse<Template>>(
        TEMPLATES_API_BASE_PATH,
        {
            pageNumber: isFiltersActive ? 1 : pageNumber,
            processTemplateName: searchFilter,
            pageSize: 15,
            ...(tagFilter && { tags: tagFilter }),
        },
        undefined,
        "Failed to fetch templates.",
    );

    const allTemplateTags = useQuery<string[]>(
        TEMPLATES_TAGS_API_PATH,
        undefined,
        undefined,
        "Failed to fetch template tags.",
    );

    const [loadedTemplates, setLoadedTemplates] = React.useState<Template[]>(
        templates.data?.data ?? [],
    );

    const hasMoreTemplates = !!templates.data?.paging?.nextPage;

    useIntersectionObserver({
        blockIntersectAction: templates.isLoading,
        intersectionObserverDependencies: [
            templates.isLoading,
            hasMoreTemplates,
            queryClient,
        ],
        intersectionRef: loadMoreTemplatesRef,
        onIntersect: () => {
            if (hasMoreTemplates) {
                setPageNumber((prevPage) => prevPage + 1);
                queryClient.invalidateQueries(TEMPLATES_API_BASE_PATH);
            }
        },
    });

    React.useEffect(() => {
        // Reset page number to 1 and loaded templates when the location changes, because component doesnt unmount
        // This is needed for when navigating to a child page, for example template details
        setPageNumber(1);
        setLoadedTemplates([]);
        queryClient.invalidateQueries(TEMPLATES_API_BASE_PATH);
    }, [location, queryClient]);

    React.useEffect(() => {
        if (templates.data?.data) {
            // If filters are active, we just replace all loaded templates with the new ones
            if (isFiltersActive) {
                setLoadedTemplates(templates.data.data);
                return;
            }
            setLoadedTemplates((prevTemplates) => [
                ...prevTemplates,
                ...templates.data.data,
            ]);
        }
    }, [templates.data, isFiltersActive]);

    const handleChangeLayout = (isListView: boolean) => {
        setIsListView(isListView);
        localStorage.setItem(IntegrationCatalogLayoutKey, String(isListView));
    };

    const fetchInitialTemplates = () => {
        setPageNumber(1);
        setLoadedTemplates([]);
        queryClient.invalidateQueries(TEMPLATES_API_BASE_PATH);
    };

    const handleChangeSearchFilter = (nextValue: string) => {
        setSearchFilter(nextValue);
        if (searchFilter && !nextValue) {
            fetchInitialTemplates();
        }
    };

    const handleChangeTagFilter = (nextValue: string[]) => {
        if (nextValue.length === 0) {
            setTagFilter(undefined);
        } else {
            setTagFilter(nextValue);
        }
        if (tagFilter && nextValue.length === 0) {
            fetchInitialTemplates();
        }
    };

    return (
        <div>
            <S.IntegrationCatalogTextContainer>
                <Text tag="h1">{catalogPageTitle}</Text>
                <Text tag="p">{catalogPageText}</Text>
            </S.IntegrationCatalogTextContainer>
            <IntegrationFilters
                tags={allTemplateTags.data ?? []}
                isListView={isListView}
                searchFilter={searchFilter}
                tagFilter={tagFilter}
                onChangeLayout={handleChangeLayout}
                onChangeSearchFilter={handleChangeSearchFilter}
                onChangeTagFilter={handleChangeTagFilter}
            />
            <ContainerWithLoadingSpinner
                loading={templates.isLoading || templates.isFetching}
                style={{ minHeight: "5rem" }}
            >
                <IntegrationCatalogList
                    templates={templates}
                    loadedTemplates={loadedTemplates}
                    isListView={isListView}
                />
                {hasMoreTemplates && (
                    <div ref={loadMoreTemplatesRef} style={{ height: "1px" }} />
                )}
            </ContainerWithLoadingSpinner>
        </div>
    );
};

const IntegrationCatalogList: React.FC<InterationCatalogListProps> = (
    props,
) => {
    const templateData = props.loadedTemplates;

    if (props.templates.isFetched && templateData.length === 0) {
        return <Text tag="h3">No Integrations found</Text>;
    }

    return (
        <div>
            <S.CardsContainer $gridMode={!props.isListView}>
                {templateData.map((template) => (
                    <S.CardLink
                        key={template.id}
                        to={`${INTEGRATION_CATALOG_BASE_PATH}/${template.id}`}
                    >
                        <Text tag="h3">{template.name}</Text>
                        {template.description && (
                            <Text tag="p">{template.description}</Text>
                        )}
                        {template.templateTags && (
                            <S.CardTagsContainer>
                                {template.templateTags.map((tag, i) => (
                                    <Tag key={i}>{tag}</Tag>
                                ))}
                            </S.CardTagsContainer>
                        )}
                    </S.CardLink>
                ))}
            </S.CardsContainer>
        </div>
    );
};

interface InterationCatalogListProps {
    templates: UseQueryResult<PagedResponse<Template>, unknown>;
    loadedTemplates: Template[];
    isListView: boolean;
}

export const IntegrationCatalogLayoutKey = "IntegrationCatalog_IsListView";

export default IntegrationCatalog;
