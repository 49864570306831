import * as React from "react";

import * as S from "@styled";

import { ReactComponent as LoadingSpinnerIcon } from "@icons/loading_spinner.svg";

interface LoadingSpinnerProps {
    size?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = '1.5rem' }) => (
    <S.LoadingSpinnerContainer style={size ? { width: size, height: size } : undefined}>
        <LoadingSpinnerIcon />
    </S.LoadingSpinnerContainer>
);

export default LoadingSpinner;