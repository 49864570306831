import * as React from "react";

import * as S from "@styled";

import Text from "./Text";
import Checkbox from "./Checkbox";

import "frends-ui-components/RadioGroup";

interface RadioGroupOption {
    label: string;
    value: string;
}

interface RadioGroupProps {
    title: string;
    name: string;
    options: RadioGroupOption[];
    onChange: (nextValue: string) => void;
    select?: string;
}

const RadioGroup: React.FC<RadioGroupProps> = (props) => {
    const handleCheckboxChange = (value: string, next: boolean) => {
        if (next) {
            props.onChange(value);
        }
    };

    return (
        <frends-radio-group>
            <Text tag="legend">
                {props.title}
            </Text>

            <S.RadioGroupCheckboxFieldset>
                {props.options.map((opt, id) => (
                    <Checkbox
                        key={id}
                        type="radio"
                        name={props.name}
                        label={opt.label}
                        value={opt.value}
                        onChange={(next) => handleCheckboxChange(opt.value, next)}
                        checked={opt.value === props.select}
                    />
                ))}
            </S.RadioGroupCheckboxFieldset>
        </frends-radio-group>
    );
};

export default RadioGroup;