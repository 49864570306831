import * as React from "react";
import * as S from "@styled";
import Text from "./Text";
import Button from "./Button";
import useDeleteMutation from "hooks/useDeleteMutation";
import { queryClient } from "index";
import { usePostFileMutation } from "hooks/usePostMutation";

const invalidatableQueries = ["lookandfeel"];

const ImageUploader: React.FC<ImageUploaderProps> = (props) => {
    const [selectedFile, setSelectedFile] = React.useState<File>();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const uploadImageMutation = usePostFileMutation(
        `upload/image/${props.imageType}`,
        selectedFile,
        () => {
            queryClient.invalidateQueries({ queryKey: invalidatableQueries });
        },
        "Failed to upload image.",
    );

    const deleteImageMutation = useDeleteMutation(
        `upload/image/${props.imageType}`,
        () => {
            queryClient.invalidateQueries({ queryKey: invalidatableQueries });
        },
        "Failed to delete image.",
    );

    React.useEffect(() => {
        if (selectedFile) {
            handleUpload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile]);

    const handleUpload = async () => {
        if (!selectedFile) {
            return;
        }
        (await uploadImageMutation).mutateAsync();
    };

    const handleRemoveImage = async () => {
        deleteImageMutation.mutate();
    };

    return (
        <S.ImageUploaderContainer>
            {props.existingImageSrc ? (
                <ExistingImageUploader
                    existingImage={props.existingImageSrc}
                    label={props.label}
                    handleFileChange={handleFileChange}
                    handleUpload={handleUpload}
                    handleRemoveImage={handleRemoveImage}
                />
            ) : (
                <NewImageUploader
                    label={props.label}
                    handleFileChange={handleFileChange}
                    handleUpload={handleUpload}
                />
            )}
        </S.ImageUploaderContainer>
    );
};

const NewImageUploader: React.FC<SharedImageUploaderProps> = (props) => {
    const ref = React.useRef<HTMLInputElement>(null);
    const handleShowFileUpload = () => {
        if (ref.current) {
            ref.current.click();
        }
    };

    const onFileSelected = (ev: React.ChangeEvent<HTMLInputElement>) => {
        props.handleFileChange(ev);
    };

    return (
        <S.ImageUploaderContentContainer>
            <Text tag="label">{props.label}</Text>
            <input ref={ref} hidden type="file" onChange={onFileSelected} />
            <S.ImageUploaderNewImageContainer>
                <Button
                    variant="bordered"
                    type="button"
                    onClick={handleShowFileUpload}
                >
                    Upload
                </Button>
                <p>
                    maximum file size: 5 MB Accepted file types: .jpeg, .png,
                    .svg{" "}
                </p>
            </S.ImageUploaderNewImageContainer>
        </S.ImageUploaderContentContainer>
    );
};

const ExistingImageUploader: React.FC<ExistingImageUploaderProps> = (props) => {
    const ref = React.useRef<HTMLInputElement>(null);
    const handleShowFileUpload = () => {
        if (ref.current) {
            ref.current.click();
        }
    };

    const onFileSelected = (ev: React.ChangeEvent<HTMLInputElement>) => {
        props.handleFileChange(ev);
    };

    return (
        <S.ImageUploaderContentContainer>
            <Text tag="label">{props.label}</Text>
            <input ref={ref} hidden type="file" onChange={onFileSelected} />
            <S.ImageUploaderExistingImageContainer>
                <img src={props.existingImage} alt={props.existingImage} />
                <Button
                    variant="bordered"
                    type="button"
                    onClick={handleShowFileUpload}
                >
                    Upload new
                </Button>
                <Button
                    variant="bordered"
                    type="button"
                    onClick={props.handleRemoveImage}
                >
                    Remove
                </Button>
            </S.ImageUploaderExistingImageContainer>
        </S.ImageUploaderContentContainer>
    );
};

interface ImageUploaderProps {
    label: string;
    existingImageSrc?: string;
    imageType:
        | "favicon"
        | "portal_logo"
        | "landing_page_image"
        | "landing_page_background";
}

interface SharedImageUploaderProps {
    label: string;
    handleUpload: () => void;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ExistingImageUploaderProps extends SharedImageUploaderProps {
    existingImage: string;
    handleRemoveImage: () => void;
}

export default ImageUploader;
