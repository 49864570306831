export const PASSWORD_REQUIREMENTS = [
    "The password must be at least 8 characters long",
    "The password must contain at least one lowercase letter",
    "The password must contain at least one uppercase letter",
    "The password must contain at least one digit (0-9)",
    "The password must contain at least one special character (non-alphanumeric character like !@#$% etc.)",
];

export const PASSWORD_REQUIREMENTS_PATTERN =
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^a-zA-Z0-9])(?=.{8,}).*$";
