import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import Toaster from "components/shared/Toaster";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";

// Create a client
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
            retry: false, // do not retry failed queries
        },
    },
});

const rootElement = document.getElementById("root");
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <ErrorBoundary>
                {/*
                 * The toaster creates a container just before the <body> tag and adds all toasts there, regardless of where the toaster component is initially placed in the DOM.
                 * Use the useToaster hook to add toasts to the toaster.
                 */}
                <Toaster />
                <BrowserRouter>
                    <QueryClientProvider client={queryClient}>
                        <App />
                    </QueryClientProvider>
                </BrowserRouter>
            </ErrorBoundary>
        </React.StrictMode>,
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
