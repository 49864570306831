import { UseMutationResult, useMutation } from "react-query";
import Utilities from "shared/utilities";
import useHandleMutationError from "./useHandleMutationError";
import { FetchError } from "model/FetchError";

const useDeleteMutation = <T = string>(
    initialUrl: string,
    onSuccess?: () => void,
    errorMsg?: string,
): UseMutationResult<T, FetchError, string | void, unknown> => {
    const mutation = useMutation<T, FetchError, string | void, unknown>({
        mutationFn: (url?: string) =>
            Utilities.fetchBase(url || initialUrl, "DELETE"),
        onSuccess,
    });

    useHandleMutationError(mutation, errorMsg);

    return mutation;
};

export default useDeleteMutation;
