import * as React from "react";
import { Link } from "react-router-dom";

import * as S from "@styled";

import Text from "components/shared/Text";

import { ReactComponent as Arrow } from "@icons/arrow.svg";

interface TitleWithArrowLinkProps {
    title: string;
    href?: string;
}

const TitleWithArrowLink: React.FC<TitleWithArrowLinkProps> = (props) => (
    <S.IntegrationNameWithArrowLink>
        {props.href && (
            <Link to={props.href}>
                <Arrow style={{ rotate: "180deg" }} />
            </Link>
        )}

        <Text tag="h2">{props.title}</Text>
    </S.IntegrationNameWithArrowLink>
);

export default TitleWithArrowLink;