import * as React from "react";

import "frends-ui-components/Tag";

type TagProps = React.PropsWithChildren;

const Tag: React.FC<TagProps> = (props) => (
    <frends-tag>{props.children}</frends-tag>
);

export default Tag;