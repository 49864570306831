export interface User {
    id: number;
    name: string;
    roles: OrganizationUserRole[];
    loginType: UserLoginType;
    disabled: boolean;
}

export enum UserRole {
    User = "User",
    Admin = "Admin",
    TenantAdmin = "TenantAdmin",
}

export interface OrganizationUserRole {
    organizationId: number;
    roleName: UserRole;
    organizationName?: string;
}

export enum UserLoginType {
    SSO = "sso",
    Local = "local",
}

export interface InviteUserRequest {
    loginType: UserLoginType;
    role: UserRole;
    email: string;
    message: string;
    [key: string]: unknown;
}

export interface UserUpdateModel {
    userRole?: OrganizationUserRole;
    disabled?: boolean;
}
