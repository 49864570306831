import * as React from "react";
import { useNavigate } from "react-router-dom";
import { UseMutationResult, UseQueryResult } from "react-query";

import useToaster from "./useToaster";
import useUserData from "./useUserData";

import Utilities from "shared/utilities";

import { FetchError } from "model/FetchError";
import { LOGIN_PATH } from "AppRoutes";
import { ProblemDetails } from "model/ProblemDetails";

const useHandleMutationError = <TData, TVariables = void, TContext = unknown>(
    mutation:
        | UseQueryResult<TData, FetchError>
        | UseMutationResult<TData, FetchError, TVariables, TContext>
        | UseMutationResult<Response, FetchError, void, unknown>,
    errorMsg?: string,
    options?: {
        excludedErrorStatuses?: number[];
    },
) => {
    const { clearUserData } = useUserData();
    const navigate = useNavigate();
    const { toastError } = useToaster();

    React.useEffect(() => {
        if (!mutation.error) {
            return;
        }

        if (mutation.error.status === 401) {
            clearUserData();

            navigate(LOGIN_PATH);
            return;
        }

        if (
            options?.excludedErrorStatuses &&
            options.excludedErrorStatuses.includes(mutation.error.status)
        ) {
            return;
        }

        toastError(
            `${Utilities.parseApiCallErrorMsg(errorMsg)}${getProblemDetails(mutation.error)}`,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutation.error]);
};

const getProblemDetails = (error: FetchError) => {
    const problemDetails = Utilities.deserialize<ProblemDetails>(error.content);

    if (problemDetails === null || !problemDetails.traceId) {
        return error.content;
    }

    return `${problemDetails.title}\nTraceId: ${problemDetails.traceId}`;
};

export default useHandleMutationError;
