import styled, { css } from "styled-components";
import { CSS } from "styled-components/dist/types";

const GridContainerBase = css`
    display: grid;
`;

export const GridContainer = styled.div<GridContainerProps>`
    ${GridContainerBase};
    ${props => props.$gridTemplateColumns && `grid-template-columns: ${props.$gridTemplateColumns}`};
    ${props => props.$gridTemplateRows && `grid-template-rows: ${props.$gridTemplateRows}`};
    ${props => props.$justifyItems && `justify-items: ${props.$justifyItems}`};
    ${props => props.$justifyContent && `justify-content: ${props.$justifyContent}`};
    ${props => props.$alignItems && `align-items: ${props.$alignItems}`};
    ${props => props.$alignContent && `align-content: ${props.$alignContent}`};
    ${props => props.$gap && `gap: ${props.$gap}`};
    ${props => props.$margin && `margin: ${props.$margin}`};
    ${props => props.$padding && `padding: ${props.$padding}`};
`;

interface GridContainerProps {
    $gridTemplateColumns?: CSS.Property.GridTemplateColumns;
    $gridTemplateRows?: CSS.Property.GridTemplateRows;
    $justifyItems?: CSS.Property.JustifyItems;
    $justifyContent?: CSS.Property.JustifyContent;
    $alignItems?: CSS.Property.AlignItems;
    $alignContent?: CSS.Property.AlignContent;
    $gap?: CSS.Property.Gap;
    $margin?: CSS.Property.Margin;
    $padding?: CSS.Property.Padding;
}