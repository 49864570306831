import * as React from "react";

import Text from "./Text";

import type FrendsModalProps from "frends-ui-components/dist/types/modal/Modal";
import "frends-ui-components/Modal";

export type ModalProps = React.PropsWithChildren<{
    title?: string;
    onClose?: () => void;
}> & Partial<Pick<FrendsModalProps, 'open' | 'closeOnBackdropClick'>>;

const Modal: React.FC<ModalProps> = (props) => {
    const ref = React.useRef<FrendsModalProps>(null);

    React.useEffect(() => {
        if (ref.current && props.open !== undefined) {
            ref.current.open = props.open;
        }
    }, [props.open]);

    React.useEffect(() => {
        if (ref.current && props.closeOnBackdropClick !== undefined) {
            ref.current.closeOnBackdropClick = props.closeOnBackdropClick;
        }
    }, [props.closeOnBackdropClick]);

    React.useEffect(() => {
        const onOpen = (event: CustomEvent) => {
            if (!event.detail.open) {
                props.onClose?.();
            }
        };

        const { current } = ref;

        current?.addEventListener('open', onOpen);

        return () => current?.removeEventListener('open', onOpen);
    }, [props]);

    return (
        <frends-modal ref={ref}>
            {props.title && (
                <div slot="title">
                    <Text tag="h2">{props.title}</Text>
                </div>
            )}

            <div slot="content">
                {props.children}
            </div>
        </frends-modal>
    );
};

export default Modal;