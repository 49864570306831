import styled, { css } from "styled-components";

import { SimplePageFormCss } from "./SimplePage";

const PasswordFormCss = css`
    ${SimplePageFormCss}

    --frends-text--text-align: center;
`;

const PasswordForm = styled.form`
    ${PasswordFormCss}

    frends-text[tag="h1"] {
        --frends-text--margin: 0 0 2.625rem 0;
    }

    frends-button[type="submit"],
    frends-button[type="link"] {
        --frends-button--margin: 0 auto;
    }
`;

export const LoginForm = styled(PasswordForm)`
    frends-text[tag="p"][color="red"] {
        --frends-text--margin: 0 0 1rem 0;
    }

    frends-link {
        --frends-link--width: fit-content;
        --frends-link--margin: 2.625rem auto 0 auto;
    }
`;

export const PasswordActionForm = styled(PasswordForm)`
    max-width: 30.25rem;

    frends-text-input {
        max-width: 27.875rem;
        width: 100%;
        margin: 0 auto;
    }

    frends-text[tag="p"] {
        --frends-text--text-align: left;
        --frends-text--margin: 0 0 0.5rem 0;
    }

    ul {
        list-style: disc;
        display: grid;
        padding-left: 1rem;
        gap: 0.35rem;
        margin-bottom: 1.5rem;
        font-size: 0.875rem;
    }
`;

export const PasswordActionSuccess = styled.div`
    ${PasswordFormCss}

    max-width: 38rem;

    frends-text:last-of-type {
        --frends-text--margin: 0 0 4.5rem 0;
    }

    frends-button[type="link"] {
        --frends-button--margin: 0 auto;
    }
`;
