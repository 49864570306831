import {
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery as useReactQuery,
} from "react-query";
import Utilities from "shared/utilities";
import useHandleMutationError from "./useHandleMutationError";
import { FetchError } from "model/FetchError";

const useQuery = <T = string>(
    url: string,
    queryParameters?: Record<string, string | string[] | number | number[]>,
    options?: UseQueryOptions<T, FetchError, T, QueryKey>,
    errorMsg?: string,
): UseQueryResult<T, FetchError> => {
    url = Utilities.mapQueryParameters(url, queryParameters);

    const query = useReactQuery<T, FetchError>({
        queryFn: () => Utilities.fetchBase(url, "GET"),
        queryKey: url,
        ...options,
    });

    useHandleMutationError(query, errorMsg, { excludedErrorStatuses: [404] });

    return query;
};

export default useQuery;
