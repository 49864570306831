/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import useUserData from "hooks/useUserData";

import useDeferredMutation from "hooks/useDeferredMutation";

import * as S from "@styled";

import SimplePageContainer from "components/simplePages/SimplePageContainer";
import Text from "components/shared/Text";
import Input, { InputRef } from "components/shared/Input";
import { SaveButton } from "components/shared/Button";
import Link from "components/shared/Link";

import Utilities from "shared/utilities";
import { FORGOT_PASSWORD_PATH, INTEGRATIONS_BASE_PATH } from "AppRoutes";

const Login: React.FC = () => {
    const { setUserDataFromCookie } = useUserData();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [gettingLoginTypeTried, setGettingLoginTypeTried] = React.useState(
        location.state?.localUserLoginExpected ?? false,
    );
    const [localUserLoginExpected, setLocalUserLoginExpected] = React.useState(
        location.state?.localUserLoginExpected ?? false,
    );
    const [localUserLoginTried, setLocalUserLoginTried] = React.useState(false);
    const [loginErrorMsg, setLoginErrorMsg] = React.useState<
        string | undefined
    >(undefined);

    const [email, setEmail] = React.useState(location.state?.email ?? "");
    const handleEmailChange = (value: string) => setEmail(value);
    const emailInputRef = React.useRef<InputRef>(null);
    const emailValid = () => emailInputRef.current?.checkValidity();
    const [emailErrorMsg, setEmailErrorMsg] = React.useState("");
    const [showEmailAsInvalid, setShowEmailAsInvalid] = React.useState(false);

    const [password, setPassword] = React.useState("");
    const handlePasswordChange = (value: string) => setPassword(value);
    const passwordInputRef = React.useRef<InputRef>(null);
    const passwordValid = passwordInputRef.current?.checkValidity();
    const [passwordErrorMsg, setPasswordErrorMsg] = React.useState("");
    const [showPasswordAsInvalid, setShowPasswordAsInvalid] =
        React.useState(false);

    /*
        When user comes to login page from reset password page after successful reset,
        email is already known and focus can be set straight to password 
    */
    React.useEffect(() => {
        if (location.state?.email && passwordInputRef.current) {
            passwordInputRef.current.focus();
        }
    }, [location.state]);

    React.useEffect(() => {
        const errorInQuery = searchParams.get("error");
        if (errorInQuery) {
            setLoginErrorMsg(errorInQuery);
        }
    }, []);

    React.useEffect(() => {
        setEmailErrorMsg("Email required");
        setShowEmailAsInvalid(gettingLoginTypeTried && !emailValid());

        setPasswordErrorMsg("Password required");
        setShowPasswordAsInvalid(localUserLoginTried && !passwordValid);
    }, [email, gettingLoginTypeTried, password, localUserLoginTried]);

    const loginTypeGET = useDeferredMutation<LoginTypeResponse>("GET");

    const localUserLoginPOST = useDeferredMutation("POST");

    const tryLocalUserLogin = () => {
        setLocalUserLoginTried(true);

        if (emailValid() && passwordValid) {
            localUserLoginPOST.mutate({
                url: "auth/signin/internal",
                body: {
                    email,
                    password,
                },
            });
        }
    };

    // handle GET login type success
    React.useEffect(() => {
        if (!loginTypeGET.isSuccess || !loginTypeGET.data) {
            return;
        }

        const { isLocalUser, redirectUrl } = loginTypeGET.data;
        if (!isLocalUser && redirectUrl) {
            // here redirectUrl is full url of SSO flow
            window.location.assign(redirectUrl);
            return;
        }

        // try local user login if the form is submitted more than once
        if (localUserLoginExpected) {
            tryLocalUserLogin();
        } else {
            setLocalUserLoginExpected(true);
            setTimeout(() => passwordInputRef.current?.focus());
        }
    }, [loginTypeGET.isSuccess, loginTypeGET.data]);

    // handle POST local user login success
    React.useEffect(() => {
        if (localUserLoginPOST.isSuccess) {
            setUserDataFromCookie();

            navigate(location.state?.redirectUrl ?? INTEGRATIONS_BASE_PATH);
        }
    }, [localUserLoginPOST.isSuccess]);

    // handle local user login error
    React.useEffect(() => {
        if (localUserLoginPOST.isError) {
            setLoginErrorMsg("Invalid email or password. Please try again.");

            setEmailErrorMsg("");
            setShowEmailAsInvalid(true);

            setPasswordErrorMsg("");
            setShowPasswordAsInvalid(true);
        }
    }, [localUserLoginPOST.isError]);

    const handleFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        setGettingLoginTypeTried(true);

        // always try getting login type first in case of email is changed after failed local user login attempt
        if (emailValid()) {
            loginTypeGET.mutate({
                url: Utilities.mapQueryParameters("auth/loginType", {
                    email,
                    ...(location.state?.redirectUrl && {
                        redirectUrl: location.state?.redirectUrl,
                    }),
                }),
            });
        }
    };

    const submitButtonDisabled =
        (gettingLoginTypeTried && !emailValid()) ||
        (localUserLoginTried && !passwordValid);

    const submitButtonLoading =
        loginTypeGET.isLoading ||
        (loginTypeGET.data && !loginTypeGET.data.isLocalUser) ||
        localUserLoginPOST.isLoading;

    const handleForgotPasswordClick = (
        evt: React.MouseEvent<HTMLAnchorElement>,
    ) => {
        evt.preventDefault();

        navigate(FORGOT_PASSWORD_PATH, { state: { email } });
    };

    return (
        <SimplePageContainer>
            <S.LoginForm onSubmit={handleFormSubmit}>
                <Text tag="h1">Login</Text>

                {loginErrorMsg && (
                    <Text tag="p" color="red">
                        {loginErrorMsg}
                    </Text>
                )}

                <Input
                    ref={emailInputRef}
                    label="Email"
                    type="email"
                    autocomplete="username current-username"
                    required
                    value={email}
                    onChange={handleEmailChange}
                    invalid={showEmailAsInvalid}
                    errortext={emailErrorMsg}
                />

                {localUserLoginExpected && (
                    <Input
                        ref={passwordInputRef}
                        label="Password"
                        type="password"
                        autocomplete="current-password"
                        required
                        value={password}
                        onChange={handlePasswordChange}
                        invalid={showPasswordAsInvalid}
                        errortext={passwordErrorMsg}
                    />
                )}

                <SaveButton
                    type="submit"
                    disabled={submitButtonDisabled}
                    loading={submitButtonLoading}
                >
                    Login
                </SaveButton>

                {localUserLoginExpected && (
                    <Link
                        href={FORGOT_PASSWORD_PATH}
                        onClick={handleForgotPasswordClick}
                    >
                        Forgot your password?
                    </Link>
                )}
            </S.LoginForm>
        </SimplePageContainer>
    );
};

interface LoginTypeResponse {
    isLocalUser: boolean;
    redirectUrl?: string;
}

export default Login;
