import * as React from "react";

import type FrendsBreadcrumbsProps from "frends-ui-components/dist/types/breadcrumbs/Breadcrumbs";
import "frends-ui-components/Breadcrumbs";

type BreadcrumbsProps = Partial<Pick<FrendsBreadcrumbsProps, "breadcrumbs">> & {
    onClick?: (href: string) => void;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
    const ref = React.useRef<FrendsBreadcrumbsProps>(null);

    React.useEffect(() => {
        if (ref.current && props.breadcrumbs !== undefined) {
            ref.current.breadcrumbs = props.breadcrumbs;
        }
    }, [props.breadcrumbs]);

    React.useEffect(() => {
        const shadowRoot = ref.current?.shadowRoot;
        if (!shadowRoot || !props.onClick) return;

        const handleClick = (e: MouseEvent, link: HTMLAnchorElement) => {
            e.preventDefault();
            props.onClick?.(link.pathname);
        };

        const links = shadowRoot.querySelectorAll('a');
        links.forEach(link => link.addEventListener('click', (e) => handleClick(e, link)));

        return () => {
            links.forEach(link => link.removeEventListener('click', (e) => handleClick(e, link)));
        };
    }, [props]);

    return (
        <frends-breadcrumbs
            ref={ref}
        />
    );
}

export default Breadcrumbs;