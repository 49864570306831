import * as React from "react";
import { useNavigate } from "react-router-dom";

import useUserData from "./useUserData";

import useDeferredMutation from "./useDeferredMutation";

import { INTEGRATIONS_BASE_PATH } from "AppRoutes";

const useChangeSelectedOrganization = () => {
    const { setUserDataFromCookie } = useUserData();
    const navigate = useNavigate();

    const changeSelectedOrganizationMutation = useDeferredMutation("POST");

    React.useEffect(() => {
        if (changeSelectedOrganizationMutation.isSuccess) {
            setUserDataFromCookie();
            navigate(INTEGRATIONS_BASE_PATH);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeSelectedOrganizationMutation.isSuccess]);

    const changeSelectedOrganization = (organizationId: number) => {
        changeSelectedOrganizationMutation.mutate({
            url: `auth/changeOrganization/${organizationId}`,
        });
    };

    return changeSelectedOrganization;
};

export default useChangeSelectedOrganization;
